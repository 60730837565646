import React from 'react';
import * as Icon from 'react-feather';
import { BaseForm, Validators } from '../FormBuilder';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';

export class CreateUserForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".      
        const formDefinition = {
            fields: [
                {
                    name: "firstName",
                    label: "First Name",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "surname",
                    label: "Surname",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "username",
                    label: "Username",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "email",
                    label: "Email",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "roles",
                    label: "Roles",
                    editor: {
                        name: "multirolecheckbox",
                        options: props.roleOptions,
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "isDefaultContact",
                    label: "Is a default form contact?",
                    editor: "checkbox",
                    value: false
                },
                {
                    name: "tenants",
                    label: "Has access to the following Tenants:",
                    editor: {
                        name: "multitentantcheckbox",
                        options: props.tenantOptions,
                        defaultValue: props.currentTenant
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "isCustomPassword",
                    label: "Specify a User Password?",
                    editor: "checkbox",
                    value: false
                },
                {
                    name: "password",
                    label: "Password",
                    editor: "password"
                },
                {
                    name: "passwordConfirm",
                    label: "Confirm Password",
                    editor: "password"
                }
            ]   
        };      
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);      
    }

    // Password Confirm Validation
    passwordConfirmValidation(isCustomPassword, password, passwordConfirm) {
        // Auto-generated password
        if (!isCustomPassword) {
            password.value = '';  
            password.errorMessage = '';
            password.isValid = true;

            passwordConfirm.value = '';
            passwordConfirm.isValid = true;
            passwordConfirm.errorMessage = '';
        } 
        // Custom Password
        else {
            password.isValid = false;
            password.errorMessage = password.label + ' is required';  
            passwordConfirm.isValid = false;
            passwordConfirm.errorMessage = passwordConfirm.label + ' does not match' + password.label;        

            if (password.value.length > 0) { 
                password.isValid = true;
            }

            if (passwordConfirm.value.length > 0) {           
                if (passwordConfirm.value === password.value) {
                    passwordConfirm.isValid = true;
                } 
            }
        }
    }  

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {       
        var isCustomPassword = fields.isCustomPassword.value;


        // Password Confirm Validation
        this.passwordConfirmValidation(isCustomPassword, fields.password, fields.passwordConfirm);  
   
        return (
            <Row form>
                <Col xs="12" md="12">
                    <FieldEditor field={fields.firstName} actions={this.fieldActions} />
                    <FieldEditor field={fields.surname} actions={this.fieldActions} />
                    <FieldEditor field={fields.username} actions={this.fieldActions} />
                    <FieldEditor field={fields.email} actions={this.fieldActions} />
                    
                    <FieldEditor field={fields.roles} actions={this.fieldActions} />
                    <FieldEditor field={fields.isDefaultContact} actions={this.fieldActions} />

                    <FieldEditor field={fields.tenants} actions={this.fieldActions} />
                 
                    <FieldEditor field={fields.isCustomPassword} actions={this.fieldActions} />    

                    {isCustomPassword === true ? <>
                        <p>Please enter the password below, using at least:</p>
                        <ul>
                            <li>one lowercase ('a'-'z')</li>
                            <li>one uppercase ('A'-'Z')</li>
                            <li>one digit ('0'-'9')</li>
                            <li>6+ characters</li>
                        </ul> 
                        <FieldEditor field={fields.password} actions={this.fieldActions}/>
                        <FieldEditor field={fields.passwordConfirm} actions={this.fieldActions}/></>
                        : <><p> <Icon.Info className="feather" /> The password will be automatically generated.</p></>
                    }
                </Col>
            </Row>
        );
    }
}