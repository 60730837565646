import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { BasicModal } from '../../Modals/Basic';
import { EditFollowUpForm } from './EditFollowUpForm';
import { CreateFollowUpResponseForm } from './CreateFollowUpResponseForm';
import { EditFollowUpResponseForm } from './EditFollowUpResponseForm';
import { ConfirmDeleteFollowUpModal } from './ConfirmDeleteModal';
import { Keys } from '../../../enums';
import { EncodedStorageService } from '../../../services/EncodedStorageService';
import { Can } from '../../RBAC/Can';

export const FollowUpActionsButton = props => {    
    const storage = new EncodedStorageService();
    const currentUser = storage.get(Keys.AuthUser);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isEditFormModalOpen, setIsEditFormModalOpen] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
    const [isCreateResponseFormModalOpen, setIsCreateResponseFormModalOpen] = useState(false);
    const [isEditResponseFormModalOpen, setIsEditResponseFormModalOpen] = useState(false);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);    
    const handleDeleteConfirmed = (entity, e) => props.handleDelete(entity, e);

    const toggleEditFormModal = () => setIsEditFormModalOpen(!isEditFormModalOpen);
    const toggleCreateResponseFormModal = () => setIsCreateResponseFormModalOpen(!isCreateResponseFormModalOpen);
    const toggleEditResponseFormModal = () => setIsEditResponseFormModalOpen(!isEditResponseFormModalOpen);

    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={isDropdownOpen} toggle={toggleDropdown} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>                    
                        <Can
                            perform="followups:edit"
                            yes={() => (
                                <>
                                    <DropdownItem onClick={() => toggleEditFormModal()}> <Icon.Edit2 className="feather" />&nbsp; Edit</DropdownItem>
                                </>
                            )}
                        />

                        <Can
                            perform="followup:responses:all"
                            yes={() => (
                                <>
                                    { props.followUp.response === null ?
                                        <>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => toggleCreateResponseFormModal()}> <Icon.Plus className="feather" />&nbsp; Create Response</DropdownItem>
                                        </>
                                        :
                                        <>
                                            <DropdownItem divider />
                                            <DropdownItem onClick={() => toggleEditResponseFormModal()}> <Icon.Send className="feather" />&nbsp; Edit Response</DropdownItem>
                                        </>
                                    }
                                </>
                            )}
                        />

                        <Can
                            perform="followup:responses:self"
                            data={{
                                currentUserId: currentUser.id,
                                followUpResponseUserId: props.followUp.response != null ? props.followUp.response.assignee != null ? props.followUp.response.assignee.id : 0: 0
                            }}
                            yes={() => (
                                <>
                                { props.followUp.response === null ?
                                    <>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => toggleCreateResponseFormModal()}> <Icon.Plus className="feather" />&nbsp; Create Response</DropdownItem>
                                    </>
                                    :
                                    <>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => toggleEditResponseFormModal()}> <Icon.Send className="feather" />&nbsp; Edit Response</DropdownItem>
                                    </>
                                }
                            </>
                            )}
                        />

                        <Can
                            perform="followups:delete"
                            yes={() => (
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => toggleConfirmDeleteModal()}> <Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                                </>
                            )}
                        />  
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            {/* Edit Follow Up */}
            <BasicModal
                isOpen={isEditFormModalOpen}
                toggle={toggleEditFormModal}
                title="Edit Follow Up"
            >
                <EditFollowUpForm
                    data={props.followUp}
                    assigneeOptions={props.assigneeOptions}
                    handleSave={(e) => props.handleEdit(e)}
                />
            </BasicModal>

            <ConfirmDeleteFollowUpModal 
                isOpen={isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal} 
                handleConfirm={handleDeleteConfirmed}
                followUp={props.followUp}
            />

            {/* Create Follow Up Response */}
            <BasicModal
                isOpen={isCreateResponseFormModalOpen}
                toggle={toggleCreateResponseFormModal}
                title="Create Follow Up Response"
            >
                <CreateFollowUpResponseForm
                    followUpId={props.followUp.id}
                    handleSave={props.handleResponseCreate}>
                </CreateFollowUpResponseForm>
            </BasicModal>

            {/* Edit Follow Up Response */}
            <BasicModal
                isOpen={isEditResponseFormModalOpen}
                toggle={toggleEditResponseFormModal}
                title="Edit Follow Up Response"
            >
                <EditFollowUpResponseForm
                    followUpId={props.followUp.id}
                    data={props.followUp.response}
                    handleSave={props.handleResponseEdit}>
                </EditFollowUpResponseForm>
            </BasicModal>
        </> 
    );
}

