import React, { useRef } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { ZoneForm } from './ZoneForm';
import { ZoneService } from '../../services/ZoneService';

export const AddZoneContainer = () => {
    const history = useHistory();
    const zoneForm = useRef();
    const zoneService = new ZoneService();

    const addZone = (zone) => {
        var model = {
            ...zone
        };

        zoneService.addZone(model).then(
            response => {
                const zone = response.data.content;
                history.push('/zones/edit/' + zone.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            },
        );
    }

    // Build the Screen menu.
    const screenMenu = (
        <>
            <NavLink to="/zones" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Zones List
            </NavLink>
        </>
    );

    // Define the setting for the screen loader
    const screenLoader = {
        isEnabled: true,
        isLoaded: true,
        text: "Loading..."
    };

    return (
        <Screen title="Add Zone" menu={screenMenu} loader={screenLoader}>
            <ZoneForm
                ref={zoneForm}
                handleSave={addZone}                   
            />
        </Screen>
    );
}