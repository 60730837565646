import React from 'react';
import * as Icon from 'react-feather';

export function FormTabStatus(props) {  
    if (!props.status) {
        return <><Icon.Circle className="feather" />&nbsp; {props.title}</>
    }
    else {
        return <><Icon.CheckCircle className="feather" style={{ color: "green" }} />&nbsp; {props.title}</>
    }
}
