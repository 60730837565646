import React from 'react';
import { Table } from 'reactstrap';

export const FormTable = (props) => {
    const COLN_WIDTH = (100) / props.headers.length;

    return (
        <Table style={{"table-layout": "fixed", "width": "100%"}}>
            <thead>
                <tr style={{ width: COLN_WIDTH }}>
                    {
                        props.headers.map((header, index) => {
                            return (
                                <th key={index} >{header}</th>
                            );
                        })
                    }
                </tr>
            </thead>
            <tbody>
                <tr style={{ width: COLN_WIDTH }}>
                    {
                        props.cells.map((cell, index) => {
                            return (
                                <td key={index}>{cell}</td>
                            );
                        })
                    }
                </tr>
            </tbody>
        </Table>
    );
};