import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../Modals/Confirm';

export function ConfirmDeleteSharpModal(props) {
    return (
        <ConfirmModal s
            isOpen={props.isOpen}
            toggle={props.toggle} 
            handleConfirm={(e) => props.handleConfirm(props.sharp, e)}
            title="Delete Sharp?"
            confirmText="Yes, Delete Sharp"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following Sharp?</strong>
            </Alert>

            <Table bordered>
                <thead>
                <tr>
                    <th className="col-xs-4">Type</th>
                    <th className="col-xs-4">Zone</th>
                    <th className="col-xs-4">Operator</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{props.sharp.type.name}</td>
                    <td>{props.sharp.zone.name}</td>
                    <td>{props.sharp.operator.firstName} {props.sharp.operator.surname}</td>
                </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );
}