import axios from 'axios';
import { EntityService } from './EntityService';

export class CompanyService extends EntityService {
    constructor() {
        super('Companies');
    }

    // Get filtered lists
    getCustomersList(page, size, sort, direction) {
        const endpoint = this.baseUrl + "GetCustomers";
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    getSuppliersList(page, size, sort, direction) {
        const endpoint = this.baseUrl + "GetSuppliers";
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    getOutstandingFormsByCompany(id, type, showAll) {
        const endpoint = this.baseUrl + "GetOutstandingFormsByCompany";
        return axios.get(endpoint, {
            params: {
                id: id,
                type: type,
                showAll: showAll
            }
        });
    }

    createCompanyContact(companyId, model) {
        const endpoint = this.baseUrl + companyId + "/Contacts/";
        return axios.post(endpoint, model);
    }

    editCompanyContact(companyId, id, model){
        const endpoint = this.baseUrl + companyId + "/Contacts/" + id;
        return axios.put(endpoint, model);
    }

    deleteCompanyContact(companyId, id){
        const endpoint = this.baseUrl + companyId + "/Contacts/" + id;
        return axios.delete(endpoint);
    }
}