import React from 'react';
import { NavLink } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Alert } from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';

const getColumns = () => [
    {
        name: 'Name',
        selector: 'name',
        sortable: true,
        ignoreRowClick: true,
        cell: (row) => <NavLink to={`/${row.path}`}>{row.name}</NavLink>
    },
    {
        name: 'Area',
        selector: 'area',
        sortable: true,
        ignoreRowClick: true,
        cell: (row) => row.area
    }
];

export function LookupsList({ items, type }) {
    const columns = getColumns();
    const hasAny = items && items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={(query) => console.log(query)} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={items.map(item => ({ ...item, type }))}
                        defaultSortField="name"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No items were found</Alert>
            }
        </>
    );
}