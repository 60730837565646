import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { SharpsList } from './SharpsList';
import { Can } from '../RBAC/Can';
import { SharpService } from '../../services/SharpService';

export const SharpsListContainer = props =>  {
    const [state, setState] = useState({
        sharps: [],
        isSharpsLoaded: false,
        isSearchingActive: false
    });
    
    // Sharp Service
    const [sharpService] = useState(new SharpService());
    
    // Load initial data
    useEffect(() => {
        const handleResponse = (response) => {
            const sharps = response.data.map(sharps => {
                return {
                    ...sharps
                };
            });
            setState(prevState => {
                return {
                    ...prevState,
                    sharps: sharps,
                    isSharpsLoaded: true,
                    isSearchingActive: false
                };
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Sharps', 'Error', 1000);
        };

        sharpService.getSharps(1, 1000, 'Type', 'asc').then(handleResponse, handleError); // Use getSharps method
    }, [sharpService]);
    

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });
        const handleResponse = (response) => {
            const sharps = response.data.map(sharps => {
                return {
                    ...sharps
                };
            });
            setState({ 
                ...state,
                sharps: sharps,
                isSharpsLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Sharps', 'Error', 1000);
        };

        if (searchText && searchText.length > 0) {
            sharpService.getSharps(1, 1000, 'Type', 'asc', searchText).then(handleResponse, handleError); 
        }
        else {
            sharpService.getSharps(1, 1000, 'Type', 'asc').then(handleResponse, handleError); 
        }
    };

    const handleEdit = (sharp, e) => {
        e.preventDefault();
        props.history.push('/sharps/edit/' + sharp.id);
    };

    const handleDelete = (sharp, e) => {
        e.preventDefault();

        sharpService.deleteSharp(sharp.id).then(
            response => {  
                const sharps = _.filter(state.sharps, x => x.id !== sharp.id);
                setState({ ...state, sharps: sharps });
                NotificationManager.success(response.data.successMessage, 'Deleted', 1000);
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            }
        );
    };

    const isLoaded = state.isSharpsLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="sharps:add"
            yes={() => (
                <>
                    <NavLink to="/sharps/add" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Assign Sharp
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading..."
    };

    return (
        <Screen title="Sharps" menu={screenMenu} loader={screenLoader}>
            <SharpsList
                items={state.sharps}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </Screen>
    );
}