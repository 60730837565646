import React, { useState, useEffect } from 'react';
import { AuditReportService } from '../../services/';
import { EditAuditReportForm } from './EditAuditReportForm';
import { NotificationManager } from '../../helpers/NotificationManager';
import  { useHistory } from "react-router-dom";
import { Screen } from '../App/Screen';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';

export const EditAuditReportFormContainer = (props) => {
    const [report, setReport] = useState({
        name: '',
        reference: '',
        description: '',
        questionsAndAnswers: [],
        status: '',
        completedOnUtc: null,
        auditor: ''
    });

    const [auditReportService] = useState(new AuditReportService());
    const reportId = props.match.params.reportId * 1;
    const history = useHistory();

    useEffect(() => {
        auditReportService.getAuditReport(reportId)
            .then(response => {
                const { name, reference, description, questionsAndAnswers, status, completedOnUtc, assessorName } = response.data;
                setReport({
                    name,
                    reference,
                    description,
                    questionsAndAnswers,
                    status,
                    completedOnUtc: completedOnUtc,
                    auditor: assessorName
                });
            })
            .catch(error => {
                console.error("Failed to load report:", error);
                // Handle error appropriately
            });
    }, [reportId, auditReportService]);

    const handleSubmit = (questionsAndAnswers) => {        
        const reportInput = {
            id: reportId,
            answers: questionsAndAnswers.map(qa => ({
                questionId: qa.id,
                comments: qa.answer,
                isCompliant: qa.isCompliant
            }))
        };

        auditReportService.saveAuditReport(reportInput).then(
            response => {
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            },
        );
    };
    
    const handleFinish = (questionsAndAnswers) => {
        const reportInput = {
            id: reportId,
            answers: questionsAndAnswers.map(qa => ({
                questionId: qa.id,
                comments: qa.answer,
                isCompliant: qa.isCompliant
            }))
        };

        // Save the report
        auditReportService.saveAuditReport(reportInput)
            .then(response => {
                // Save successful, attempt to finish the report
                auditReportService.finishAuditReport({ id: reportId })
                    .then(finishResponse => {
                        // Finish successful, redirect and show success message
                        history.push('/audit-reports/');
                        NotificationManager.success(finishResponse.data.successMessage, 'Success');
                    })
                    .catch(finishError => {
                        // Finish failed, show error message
                        const finishErrors = finishError.response.data.errors;
                        NotificationManager.error(finishErrors[0].message, 'Error', 1000);
                    });
            })
            .catch(saveError => {
                // Save failed, show error message
                const saveErrors = saveError.response.data.errors;
                NotificationManager.error(saveErrors[0].message, 'Error', 1000);
            });      
    };

    const screenMenu = (
        <>
            <NavLink to={"/audit-reports/"} className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Reports
            </NavLink>
        </>
    );

    const screenLoader = {
        isEnabled: true,
        isLoaded: true,
        text: "Loading Report..."
    };

    return (
        <Screen title={report.reference} menu={screenMenu} loader={screenLoader}>
            <>
                <EditAuditReportForm
                    report={report}
                    handleSubmit={handleSubmit}
                    handleFinish={handleFinish}
                />
            </>
        </Screen>
    );
};