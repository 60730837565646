import React from 'react';
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom";
import { Alert }  from 'reactstrap';
import { DiscrepancyActionsButton } from './DiscrepancyActionsButton';
import { DiscrepancyStatusBadge } from './DiscrepancyStatusBadge';
import { DiscrepancyCategoryBadge } from './DiscrepancyCategoryBadge';
import { FormGroup }  from 'reactstrap';
import { DiscrepancySearch } from './DiscrepancySearch';
import { DiscrepancyPdf } from './DiscrepancyPdf';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';

export function DiscrepanciesList(props) {
    const isSmallDevice = useMediaQuery({ maxWidth: 768 });

    const columns = isSmallDevice ? [
        {
            name: 'Activity Audited',
            selector: 'activityAudited',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/discrepancies/edit/' + row.id}>{row.activityAudited}</NavLink>
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <DiscrepancyActionsButton
                        discrepancy={row}
                        handleEdit={props.handleEdit}
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ] : [
        {
            name: 'Reference',
            selector: 'reference',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/discrepancies/edit/' + row.id}>{row.reference}</NavLink>
            }
        },
        {
            name: 'Activity Audited',
            selector: 'activityAudited',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <span>{row.activityAudited}</span>
            }
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <DiscrepancyStatusBadge status={row.status}/>
            }
        },
        {
            name: 'Category',
            selector: 'discrepancyCategory.name',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                if (row.discrepancyCategory != null) {
                    return <DiscrepancyCategoryBadge category={row.discrepancyCategory.name}/>
                }
                else {
                    return <></>
                }
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                if (row.status === 'Completed'){
                    return <DiscrepancyPdf discrepancyId={row.id}/>
                }
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <DiscrepancyActionsButton
                        discrepancy={row}
                        handleEdit={props.handleEdit}
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    const containerStyle = {
        display: 'flex',
        justifyContent: isSmallDevice ? 'flex-start' : 'space-between',
        marginBottom: isSmallDevice ? '0' : '10px',
        flexWrap: 'wrap',
        flexDirection: isSmallDevice ? 'column' : 'row'
    };

    const formGroupStyle = {
        flex: isSmallDevice ? '1 1 100%' : '1 1 48%',
        marginBottom: '10px'
    };

    const searchStyle = {
        flex: isSmallDevice ? '1 1 100%' : '1 1 48%'
    };
    
    return (
        <>
            <div style={containerStyle}>
                <FormGroup style={formGroupStyle}>
                    <Select
                        options={props.options}
                        isMulti
                        name="filters"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={props.handleOptionsChange}
                        defaultValue={{value: 'In Progress', label: 'In Progress', type: 'status'}}
                    />
                </FormGroup>

                <div style={searchStyle}>
                    <DiscrepancySearch handleSearch={props.handleSearch}/>
                </div>
            </div>

            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="id"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No discrepancies were found</Alert>
            }
        </>
    );
}
