import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { Screen } from '../App/Screen';
import { ScheduleAuditReportForm } from './ScheduleAuditReportForm';
import { AuditReportService, TemplateService, ZoneService} from '../../services';
import { AUDIT_REPORTS} from "../../constants/Route";
import { NotificationManager } from "../../helpers/NotificationManager";
import {SharpService} from "../../services/SharpService";

export const ScheduleAuditReportFormContainer = (props) => {
    const [report, setReport] = useState({
        startDate: '',
        endDate: '',
        frequency: '',
        frequencyInterval: 1,
        templateId: 0,
    });
    
    const [templates, setTemplates] = useState([]);
    const [zones, setZones] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [templateService] = useState(new TemplateService());
    const [zoneService] = useState(new ZoneService());
    const [sharpService] = useState(new SharpService());
    const [auditReportService] = useState(new AuditReportService());

    useEffect(() => {
        Promise.all([
            templateService.getTemplates(1, 1000, 'Name', 'asc'),
            zoneService.getZones()
        ]).then(([templateResponse, zoneResponse]) => {
            var templates = templateResponse.data;
            templates.push({ id: 999, name: 'Sharps [Generated]' });
            setTemplates(templates);
            setZones(zoneResponse.data);
            setIsLoading(false);
        }).catch(error => {
            NotificationManager.error('Could not load data', 'Error', 1000);
        });
    }, [templateService, zoneService]);

    const handleReportChange = (field) => (event) => {
        const value = (field === 'frequencyInterval' || field === 'zoneId' || field === 'templateId') ? parseInt(event.target.value, 10) : event.target.value;
        setReport({
            ...report,
            [field]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Validate form data before submitting
        if (!report.startDate || !report.endDate || !report.frequency || !report.templateId) {
            NotificationManager.error('Please fill out all required fields', 'Error', 1000);
            return;
        }
        
        // Schedule sharps
        if (report.templateId === 999) {
            const reportInput = {
                startDate: report.startDate,
                endDate: report.endDate,
                frequency: report.frequency,
            };
            
            sharpService.scheduleAuditReports(reportInput).then(
                response => {
                    NotificationManager.success(response.data.successMessage, 'Success');
                },
                response => {
                    const errors = response.errors;
                    console.log(response);
                    NotificationManager.error(errors[0].message, 'Error', 1000);
                },
            );
        }
        // Schedule audit reports
        else {
            const reportInput = {
                ...report
            };

            auditReportService.scheduleAuditReports(reportInput).then(
                response => {
                    NotificationManager.success(response.data.successMessage, 'Success');
                },
                response => {
                    const errors = response.errors;
                    console.log(response);
                    NotificationManager.error(errors[0].message, 'Error', 1000);
                },
            );
        }
        
        

  
    };
    const screenMenu = (
        <>
            <NavLink to={AUDIT_REPORTS} className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Reports
            </NavLink>
        </>
    );

    const screenLoader = {
        isEnabled: true,
        isLoaded: !isLoading,
        text: "Loading..."
    };

    return (
        <Screen title="Schedule Reports" menu={screenMenu} loader={screenLoader}>
            <ScheduleAuditReportForm
                report={report}              
                handleReportChange={handleReportChange}
                handleSubmit={handleSubmit}
                zones={zones}
                templates={templates}                
            />
        </Screen>
    );
};