import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { ZoneForm } from './ZoneForm';
import { ZoneService } from '../../services/ZoneService';

export const EditZoneContainer = (props) => {
    const [zone, setZone] = useState({});
    const [isZoneLoaded, setIsZoneLoaded] = useState(false);
    const zoneForm = useRef();
    const [zoneService] = useState(new ZoneService());
    const history = useHistory();
    const zoneId = props.match.params.zoneId * 1;

    useEffect(() => {
        zoneService.getZone(zoneId).then(
            response => {
                const zone = {
                    ...response.data
                }
                setZone(zone);
                setIsZoneLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain specified zone', 'Error', 1000);
            }
        );
    }, [zoneId, zoneService]);

    const editZone = (zone) => {
        var model = {
            id: zoneId,
            ...zone
        };

        zoneService.editZone(model).then(
            response => {
                history.push('/zones/edit/' + model.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            }
        );
    }

    const screenMenu = (
        <>
            <NavLink to="/zones" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Zones List
           </NavLink>
        </>
    );

    const screenLoader = {
        isEnabled: true,
        isLoaded: isZoneLoaded,
        text: "Loading Edit Zone Form..."
    };

    return (
        <Screen title="Edit Zone" menu={screenMenu} loader={screenLoader}>
            <ZoneForm
                ref={zoneForm}
                data={zone}
                handleSave={editZone}
            />
        </Screen>
    );
}