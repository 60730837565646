import React from 'react';
import { HashRouter } from "react-router-dom";
import { Header, Footer, Navigation } from '../Layout';
import { Router } from './Router';
import ReactNotification from 'react-notifications-component'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css'; 
import './App.css';

export function App(props) {
    return (
        <HashRouter>
            <ReactNotification />
            <div className="App">
                <Header title="QualityWorx" user={props.data.user} handleLogout={props.actions.logout} handleSwitchTenant={props.actions.switchTenant}  />

                <div className="container-fluid">
                    <div className="row">
                        <Navigation/>
                        
                        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-4 main-content">
                            <Router 
                                data={props.data}
                                actions={props.actions}
                            />
                        </main>
                    </div>
                </div>                           

                <Footer
                    version={props.data.config.appVersion}
                    copyright={props.data.config.copyright}
                />
            </div>
        </HashRouter>    
    );
}