import axios from 'axios';
import config from '../config';

export class AuditReportService {
    constructor() {
        this.basePath = 'AuditReports';
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }

    getAuditReports(page, size, sort, direction, searchTerm = '', status = '', zoneId = null, start = null, end = null) {
        const endpoint = this.baseUrl;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
                status: status,
                zoneId: zoneId,
                start: start,
                end: end
            }
        });
    }

    getAuditReport(id) {
        const endpoint = this.baseUrl  + id;
        return axios.get(endpoint);
    }

    scheduleAuditReports(reportInput) {
        const endpoint = this.baseUrl + 'Schedule';
        return axios.post(endpoint, reportInput);
    }

    startAuditReport(reportInput) {
        const endpoint = this.baseUrl + 'Start';
        return axios.post(endpoint, reportInput);
    }

    saveAuditReport(reportInput) {
        const endpoint = this.baseUrl + 'Save';
        return axios.post(endpoint, reportInput);
    }

    finishAuditReport(reportInput) {
        const endpoint = this.baseUrl + 'Finish/' + reportInput.id;
        return axios.post(endpoint, reportInput);
    }

    deleteAuditReport(id) {
        const endpoint = this.baseUrl + id;
        return axios.delete(endpoint);
    }
}