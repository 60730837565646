

import React from 'react';
import { Redirect } from "react-router-dom";

export const LogoutForm = (props) => {
    props.handleLogout();
    return (
        <Redirect to='/' />
    );
}