import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ConfirmDeleteUserModal } from './ConfirmDeleteModal';
import { ChangePasswordForm } from './ChangePasswordForm';
import { BasicModal } from '../Modals/Basic';
import { UserService } from '../../services/UserService';

export const UserActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false,
        isChangePasswordModalOpen: false
    });

    const [userService] = useState(new UserService());

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleConfirmDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen
        }));
    };

    const handleDeleteConfirmed = (entity, e) => {
        props.handleDelete(entity, e);
    };

    const toggleChangePasswordModal = () => {
        setState((prevState) => ({
            ...prevState,
            isChangePasswordModalOpen: !prevState.isChangePasswordModalOpen
        }));
    };

    const changePassword = (entity, e) => {
        props.changePassword(entity, e);
    };

    const resendVerificationEmail = (entity) => {
        const model = {
            email: entity.email
        }

        userService.sendConfirmEmail(model).then(
            response => {
                NotificationManager.success('The verification email was sent successfully', 'Verification Email Sent', 1000);
            },
            error => {
                NotificationManager.error('The verification email failed to send', 'Error', 1000);
            }
        );        
    }

    return(
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleEdit(props.user, e)}><Icon.Edit2 className="feather" />&nbsp; Edit</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => resendVerificationEmail(props.user)}><Icon.Users className="feather" />&nbsp; Resend Verification Email</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={toggleChangePasswordModal}><Icon.Lock className="feather" />&nbsp; Change Password</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={toggleConfirmDeleteModal}><Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <ConfirmDeleteUserModal
                isOpen={state.isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal}
                handleConfirm={handleDeleteConfirmed}
                user={props.user}
            />

            {/* Change Password */}
            <BasicModal
                isOpen={state.isChangePasswordModalOpen}
                toggle={toggleChangePasswordModal}
                title="Change Password"
            >
                <ChangePasswordForm
                    user={props.user}
                    handleSave={changePassword}
                    
                />
            </BasicModal>
        </>
    );
}