const commonPermissions = [
    "forms:view",
    "forms:create",
    "forms:edit",
    "rootcause:create",
    "rootcause:edit",
    "rootcause:delete",
    "actiontaken:create",
    "actiontaken:edit",
    "actiontaken:delete",
    "companies:view",
    "companies:create",
    "companies:edit",
    "companies:delete",
    "operators:view",
    "operators:create",
    "operators:edit",
    "operators:delete",
];

const adminPermissions = [
    "forms:delete",
    "forms:archive",
    "followups:create",
    "followups:edit",
    "followups:delete",
    "followup:responses:all",
    "notes:all",
    "globalAlerts:view",
    "globalAlerts:create",
    "globalAlerts:edit",
    "globalAlerts:delete",
    "contacts:all"
];

const superAdminPermissions = [
    ...adminPermissions,
    "contacts:all"
];


const auditAdminPermissions = [
    // zones
    "zones:add",
    "zones:edit",
    "zones:delete",
    "zones:all",
    "zones:view",
    // templates
    "templates:add",
    "templates:edit",
    "templates:delete",
    "templates:all",
    "templates:view",
    // reports
    "reports:schedule",
    "reports:edit",
    "reports:delete",
    "reports:all",
    "reports:view",
    // discrepancies
    "discrepancies:add",
    "discrepancies:edit",
    "discrepancies:delete",
    "discrepancies:all",
    "discrepancies:view",
    // sharps
    "sharps:add",
    "sharps:edit",
    "sharps:delete",
    "sharps:all",
    "sharps:view",
]

// This is just temporary, until we decide on actual roles etc.
const auditorPermissions = [
    // reports
    "reports:edit",
    "reports:all",
    "reports:view",
    // discrepancies
    "discrepancies:add",
    "discrepancies:edit",
    "discrepancies:all",
    "discrepancies:view"
]

const rules = {
    SuperAdmin: {
        static: [
            ...commonPermissions,
            ...superAdminPermissions,
        ]
    },
    Admin: {
        static: [
            ...commonPermissions,
            ...adminPermissions,
        ]
    },
    User: {
        static: commonPermissions,
        dynamic: {
            "followup:responses:self": ({ currentUserId, followUpResponseUserId }) => {
                return currentUserId === followUpResponseUserId;
            },
            "notes:self": ({ currentUserId, noteUserId }) => {
                return currentUserId === noteUserId;
            }
        }
    },
    AuditAdmin: {
        static: [
            ...commonPermissions,
            ...auditAdminPermissions,
            ...auditorPermissions
        ]
    },
    Auditor: {
        static: [
            ...commonPermissions,
            ...auditorPermissions,
        ]
    }
};

export default rules;