import React, { useState, useEffect } from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';
import _ from 'lodash';
import { Can } from './../RBAC/Can';

export const MultipleContactsCheckboxField = props => {   
    const [selectedItems, setSelectedItems] = useState([]);  
    const [options, setOptions] = useState([]); 
    const [isLoaded, setIsLoaded] = useState(false); 
    
    const company = "Customer/Supplier"; // make dynamic
    const hasOptions = props.options && props.options.length > 0;      

    // Every Change
    useEffect(() => {        
        setIsLoaded(true);     
        console.log(selectedItems);
        if (props.options !== options){
            setSelectedItems(_.filter(props.options, x => x.isDefaultContact === true));
           
            setOptions(props.options);
        }

        // Create new multiCheckbox object
        const multiCheckbox = {
            target: {
                name: props.field.name,
                type: 'multicheckbox',
                value: selectedItems,
            }
        };

        props.actions.handleChange(multiCheckbox);
    }, [selectedItems, props.field.name, props.actions, props.options, options]);


    const handleChange = (e) => {
        const isChecked = e.target.checked;

        var model = {
            id: e.target.id * 1,
            firstName: e.target.getAttribute('firstname'),
            surname: e.target.getAttribute('surname'),
            email: e.target.getAttribute('email'),
            telephoneNumber: e.target.getAttribute('tel'),
            isUser: e.target.getAttribute('isuser') === "true" ? true : false,
        };

        if (isChecked) {
            setSelectedItems([...selectedItems, model]);
        }
        else if (!isChecked) {
            setSelectedItems(selectedItems.filter(item => item.id !== model.id));
        }     
    };  

    return (
        isLoaded ?

        <Can
        perform="contacts:all"
        yes={() => (
            <FormGroup className={props.actions.getErrorClass(props.field)}>
            <Label for={props.field.name}>{props.field.label}&nbsp;&nbsp;&nbsp;<small>{props.actions.getErrorMessage(props.field)}</small></Label>
            <div>
                {
                    hasOptions ? props.options.map((opt) => {     
                        var label = opt.isUser === true ? opt.firstName + ' ' + opt.surname
                        : opt.firstName + ' ' + opt.surname + ' (' + company + ')';           
                        var isChecked = _.filter(selectedItems, x => x.id === opt.id).length > 0 ? true : false;
                        var isDisabled = opt.isDefaultContact === true ? true : false; 
                        opt.value = isDisabled ? true : isChecked;                  
                        return (
                            <CustomInput key={opt.id} type="checkbox" id={opt.id} firstname={opt.firstName} surname={opt.surname} email={opt.email} tel={opt.telephoneNumber} isuser={opt.isUser.toString()} label={label} checked={opt.value} onChange={handleChange} disabled={isDisabled}/>
                        );                    
                    }) : <p></p>
                }
            </div>           
        </FormGroup> 
        )}
    />  
        
        : <>Loading options...</>
    );
}


