import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { ClassificationTypeForm } from './Form';
import { EntityService } from '../../services/EntityService';
import { LookupService } from '../../services/LookupService';

export class CreateClassificationTypeContainer extends React.Component {
    constructor(props) {
        super(props);

        this.ClassificationTypeService = new EntityService("ClassificationTypes");
        this.LookupService = new LookupService();
        this.createClassificationType = this.createClassificationType.bind(this);
    }

    createClassificationType(classificationType) {
        var model = {
            ...classificationType
        };

        this.ClassificationTypeService.create(model).then(
            response => {
                const classificationType = response.data;
                this.props.history.push('/classification-types/edit/' + classificationType.id);
                NotificationManager.success('The classification type ' + classificationType.name + ' was created successfully', 'Classification Type Created');
            },
            response => {
                NotificationManager.error('Classification Type was not created', 'Error', 1000);                
            },
        );
    }

    render() {       

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/classification-types" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Classification Types List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: true,
            text: "Loading Create Classification Type Form..."
        };

        return (
            <Screen title="Create Classification Type" menu={screenMenu} loader={screenLoader}>
                <ClassificationTypeForm
                    ref={(el) => this.classificationTypeForm = el}
                    handleSave={this.createClassificationType}
                    
                />
            </Screen>
        );
    }
}