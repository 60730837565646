import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Row, Col, Card, CardTitle, CardText } from 'reactstrap';

export const ClassificationTypeCardList = props => {
    const [state, setState] = useState({
        selected: ''
    });

    const setFilter = filter => {
        setState({ ...state, selected: filter });
        props.handleFilter(filter);
    };

    const isActive = value => {
        switch(props.isFilterToggled) {
            case true:
            return (value === state.selected) ? 'classificationTypeCard active' : 'classificationTypeCard';
            default:
            return 'classificationTypeCard';
         } 
    };

    return (
        <Row>
            <Col xs="6" sm="3">
                <Card className={isActive('Critical')} body onClick={() => setFilter('Critical')}>
                    <Icon.AlertCircle className="feather critical" />
                    <CardTitle>{props.critical}</CardTitle>
                    <CardText>Critical</CardText>
                </Card>
            </Col>
            <Col xs="6" sm="3">
                <Card className={isActive('Major')} body onClick={() => setFilter('Major')}>
                    <Icon.AlertOctagon className="feather major" />
                    <CardTitle>{props.major}</CardTitle>
                    <CardText>Major</CardText>
                </Card>
            </Col>
            <Col xs="6" sm="3">
                <Card className={isActive('Minor')} body onClick={() => setFilter('Minor')}>
                    <Icon.Info className="feather minor" />
                    <CardTitle>{props.minor}</CardTitle>
                    <CardText>Minor</CardText>
                </Card>
            </Col>
            <Col xs="6" sm="3">
                <Card className={isActive('Overdue')} body onClick={() => setFilter('Overdue')}> 
                    <Icon.Clock className="feather" />
                    <CardTitle>{props.overdue}</CardTitle>
                    <CardText>Overdue</CardText>
                </Card>
            </Col>
        </Row>
    );
}

