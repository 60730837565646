import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { OperatorForm } from './Form';
import { EntityService } from '../../services/EntityService';

export class EditOperatorContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            operator: {},
            isOperatorLoaded: false
        };

        this.OperatorService = new EntityService("Operators");

        this.updateOperator = this.updateOperator.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.operatorId = this.props.match.params.operatorId * 1;

    }

    componentDidMount() {
        this.OperatorService.getById(this.operatorId).then(
            response => {
                const operator = {
                    ...response.data
                }
                this.setState({
                    operator: operator,
                    isOperatorLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified operator', 'Error', 1000);
            }
        );        
    }

    updateOperator(operator) {
        var model = {
            ...operator
        };

        this.OperatorService.update(this.operatorId, model).then(
            response => {
                // Alert the client that the operator has been updated
                NotificationManager.success('The operator ' + operator.firstName + ' ' + operator.surname + ' was updated.', 'Operator Updated', 1000);
            },
            error => {
                // Alert the client that the operator has NOT been updated
                NotificationManager.error('Operator was not updated', 'Error', 1000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the UserForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.operatorForm && this.operatorForm.handleSubmit) {
            const form = this.operatorForm.state.form
            if (form.isValid) {
                this.operatorForm.handleSubmit(e);
            }
            else {
                this.operatorForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 1000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isOperatorLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/operators" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Operators List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit Operator Form..."
        };

        return (
            <Screen title="Edit Operator" menu={screenMenu} loader={screenLoader}>
                <OperatorForm
                    ref={(el) => this.operatorForm = el}
                    data={this.state.operator}
                    handleSave={this.updateOperator}
                    
                />
            </Screen>
        );
    }
}