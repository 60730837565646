
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import font from '../../fonts/HelveticaNeue.ttf';
import fontSB from '../../fonts/HelveticaNeue-Medium.ttf';
import { Page, View, Text, Font, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { AuditReportService } from '../../services';
import {  Button }  from 'reactstrap';
import Logo from '../../graphics/logo-no-text-transparent.png';

export const AuditReportPdf = (props) => {
    const [report, setReport] = useState({
        name: '',
        auditor: '',
        reference: '',
        description: '',
        questionsAndAnswers: [],
        status: '',
        loaded: false
    });
    
    
    const [auditReportService] = useState(new AuditReportService());
    const reportId = props.reportId;

    useEffect(() => {
        auditReportService.getAuditReport(reportId)
            .then(response => {
                const { name, reference, description, questionsAndAnswers, status, assessorName } = response.data;
                setReport({
                    name,
                    assessorName,
                    reference,
                    description,
                    questionsAndAnswers,
                    status,
                    loaded: true
                });
            })
            .catch(error => {
                console.error("Failed to load report:", error);
                // Handle error appropriately
            });
    }, [reportId, auditReportService]);

    const isLoaded = report.loaded ?? false;

    // PDF 
    const fileName = report.reference + "_" + moment(new Date()).format('DD-MM-YYYY_HH-mmA') + ".pdf";
    const generatePdfDocument = async () => {
        const blob = await pdf((
            <PdfDocument report={report} />
        )).toBlob();
        saveAs(blob, fileName);
    }

    return (isLoaded ?
        <Button onClick={generatePdfDocument} size="sm" color="success"><Icon.Download className="feather" /></Button> :
        <Button size="sm" color="success"><Icon.Download className="feather" /></Button>
    )
}

// Create Document Component
export const PdfDocument = (props) => {
    const dateCreated = moment(new Date()).format('DD/MM/YYYY @ hh:mm A');
    const isDocument = props.isDocument ?? true;

    // Register fonts
    Font.register({
        family: 'Helvetica Neue LT Std',
        fonts: [
            { src: font },
            { src: fontSB, fontStyle: 'semibold' },
        ],
    });

    // Updated styles
    const styles = StyleSheet.create({
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            fontFamily: 'Helvetica Neue LT Std',
        },
        title: {
            fontSize: 22,
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'semibold',
            marginBottom: 15,
            color: '#2c3e50',
            textAlign: 'left',
        },
        header: {
            fontSize: 10,
            color: '#7f8c8d',
            marginBottom: 15,
            textAlign: 'center',
        },
        containerTitle: {
            color: '#ffffff',
            fontSize: 16,
            fontFamily: 'Helvetica Neue LT Std',
            textAlign: 'left',
            fontWeight: 'semibold',
        },
        contentBox: {
            padding: 15,
            backgroundColor: '#ffffff',
            marginBottom: 20,
            border: '1px solid black', // Black border around table
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: 'solid',
            borderColor: '#e0e0e0', // Match category background color
            borderWidth: 1,
        },
        tableRow: {
            flexDirection: "row"
        },
        tableCol: {
            width: "50%",
            borderStyle: 'solid',
            borderColor: '#e0e0e0', // Match category background color
            borderWidth: 1,
        },
        tableCell: {
            padding: 10, // Increase padding
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std'
        },
        subtitle: {
            fontSize: 12,
            fontFamily: 'Helvetica Neue LT Std',
            color: '#000000',
            backgroundColor: '#e0e0e0',
            marginBottom: 5,
            textAlign: 'left',
            padding: 10,
            paddingLeft: 10,
            textTransform: 'none',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            marginTop: 20,
        },
        question: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'bold',
            margin: 5,
        },
        answer: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            margin: 5,
            border: '1px solid #ccc',
            padding: 5,
            borderRadius: 4,
            backgroundColor: '#f9f9f9',
            minHeight: 50,
        },
        comments: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            margin: 5,
            border: '1px solid #ccc',
            padding: 5,
            borderRadius: 4,
            backgroundColor: '#f9f9f9',
            minHeight: 50,
            width: '80%', // Set width to 80%
            marginRight: 0
        },
        complianceContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 5,
        },
        complianceColumn: {
            width: '10%', // Set width to 10%
            textAlign: 'center',
            backgroundColor: '#f9f9f9', // Grey background
            border: '1px solid #ccc',
            padding: 5,
            borderRadius: 4,
            minHeight: 50,
            marginLeft: 5,   
        },
        complianceLabel: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'bold',
            marginRight: 5,
        },
        complianceValue: {
            fontSize: 10,
            fontFamily: 'Helvetica Neue LT Std',
            color: '#000000', // Remove red text
        },
        hr: {
            borderBottom: '1px solid #e0e0e0', // Match category background color
            marginVertical: 10,
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 50,
            right: 0,
            textAlign: 'left',
            color: '#7f8c8d',
        },
        dateCreated: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 0,
            right: 50,
            textAlign: 'right',
            color: '#7f8c8d',
        },
        questionContainer: {
            marginTop: 20, // Increase margin between bottom of table and questions
        },
        explanatoryText: {
            fontSize: 10,
            color: '#7f8c8d',
            marginTop: 5,
        },
        logo: {
            width: 50,
            height: 50,
            marginBottom: 20,
            alignSelf: 'center',
            resizeMode: 'contain'
        },
    });

    const openOnUtcFormatted = moment(props.report.openOnUtc).format('DD/MM/YYYY');
    const completedOnUtcFormatted = props.report.completedOnUtc !== null ? moment(props.report.completedOnUtc).format('DD/MM/YYYY') : 'N/A';

    // Group questions and answers by categoryName
    const groupedQuestions = props.report.questionsAndAnswers.reduce((acc, qa) => {
        acc[qa.categoryName] = acc[qa.categoryName] || [];
        acc[qa.categoryName].push(qa);
        return acc;
    }, {});

    const renderQuestions = (questions) => {
        return questions.map((qa, idx) => (
            <View key={idx}>
                <Text style={styles.question}>{`${idx + 1}. ${qa.question}`}</Text>
                <View style={styles.complianceContainer}>
                    <Text style={styles.comments}>{qa.answer}</Text>
                    <View style={styles.complianceColumn}>
                        <Text style={{ color: qa.isCompliant ? 'green' : 'black' }}>
                            {qa.isCompliant ? 'C' : '-'}
                        </Text>
                    </View>
                    <View style={styles.complianceColumn}>
                        <Text style={{ color: qa.isCompliant ? 'black' : 'red' }}>
                            {qa.isCompliant ? '-' : 'NC'}
                        </Text>
                    </View>
                </View>
            </View>
        ));
    };

    // Rendering the document or view based on isDocument prop
    if (isDocument) {
        return (
            <Document>

                <Page style={styles.body}>
                    <Image style={styles.logo} src={Logo} alt="QualityWorx" title="QualityWorx"/>

                    <Text style={styles.title}>{props.report.reference}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Audit No: {props.report.reference}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Auditor: {props.report.assessorName}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Activity Audited: {props.report.description}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}></Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Date of Audit: {openOnUtcFormatted}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Completed On: {completedOnUtcFormatted}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.contentBox, styles.questionContainer]}>
                        {Object.entries(groupedQuestions).map(([categoryName, questions], index) => (
                            <View key={index}>
                                <Text style={styles.subtitle}>{categoryName}</Text>
                                {renderQuestions(questions.sort((a, b) => a.sortOrder - b.sortOrder))}
                            </View>
                        ))}
                        <Text style={styles.explanatoryText}>* C: Compliant</Text>
                        <Text style={styles.explanatoryText}>* NC: Non-Compliant</Text>
                    </View>
                    
                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                    <Text style={styles.dateCreated} render={() => `${dateCreated}`} fixed />
                </Page>
            </Document>
        );
    } else {
        return (
            <View style={[styles.contentBox, styles.questionContainer]}>
                <Text style={styles.title}>{props.report.reference}</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Audit No: {props.report.reference}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Auditor: {props.report.assessorName}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Activity Audited: Internal Audit</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Date of Audit: {openOnUtcFormatted}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Completed On: {completedOnUtcFormatted}</Text>
                        </View>
                    </View>
                </View>
                {Object.entries(groupedQuestions).map(([categoryName, questions], index) => (
                    <View key={index}>
                        <Text style={styles.subtitle}>{categoryName}</Text>
                        {renderQuestions(questions.sort((a, b) => a.sortOrder - b.sortOrder))}

                       
                    </View>
                ))}
                <Text style={styles.explanatoryText}>* C: Compliant</Text>
                <Text style={styles.explanatoryText}>* NC: Non-Compliant</Text>

            </View>
        );
    }
};