import React from 'react';
import * as Icon from 'react-feather';
import DataTable from 'react-data-table-component';
import { Badge, Alert } from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { Button, Table, Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { CompanyContactActionsButton } from './CompanyContact/ActionsButton';
import { Can } from './../RBAC/Can';

export function CompanyView(props) {

    const hasAny = props.companyContacts && props.companyContacts.length > 0;
    var badgeColor = "";

    switch (props.company.type.name) {
        case "Customer and Supplier":
            badgeColor = "success";
            break;
        case "Customer":
            badgeColor = "warning";
            break;
        case "Supplier":
            badgeColor = "primary";
            break;
        default:
            badgeColor = "secondary";
    }

    const columns = [
        {
            name: 'Full Name',
            selector: 'firstName',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <>{row.firstName} {row.surname}</>
            }
        },
        {
            name: 'Email',
            selector: 'email',
            sortable: true
        },
        {
            name: 'Telephone No.',
            selector: 'telephoneNumber',
            sortable: true
        },
        {
            name: 'Job Role',
            selector: 'jobRole',
            sortable: true
        },
        {
            name: 'Office Location',
            selector: 'officeLocation',
            sortable: true
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <CompanyContactActionsButton
                        contact={row}
                        handleEdit={props.toggleEditCompanyContactModal}
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    return (
        <>
            <Row>
                <Col sm="12">
                    <ListGroup>
                        <ListGroupItem active>
                            <ListGroupItemHeading>
                                Company Details
                            </ListGroupItemHeading>
                        </ListGroupItem>
                        <ListGroupItem>
                            <ListGroupItemHeading> Summary
                            </ListGroupItemHeading>
                            <ListGroupItemText>
                                Provides a quick summary for the Company, particularly whether they are a Customer, Supplier or Both.
                            </ListGroupItemText>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Shortcode</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{props.company.name}</td>
                                        <td>{props.company.shortcode}</td>
                                        <td><Badge color={badgeColor} pill>{props.company.type.name}</Badge></td>
                                    </tr>
                                </tbody>
                            </Table>
                        </ListGroupItem>
                        <ListGroupItem>
                            <ListGroupItemHeading> Contacts
                            <Can
                                    perform="companies:create"
                                    yes={() => (
                                        <>
                                            <Button className="float-right" color="success" size="sm" onClick={props.toggleCreateCompanyContactModal} style={{ margin: "0px 5px 0px 5px" }}>
                                                <Icon.PlusCircle className="feather" /> Create Company Contact
                                            </Button>
                                        </>
                                    )}
                                />
                            </ListGroupItemHeading>
                            <ListGroupItemText>
                                Provides a list of Company Contacts.
                            </ListGroupItemText>
                            <>
                                <TableSearchForm handleSearch={props.handleCompanyContactSearch} />
                                {
                                    hasAny ? (
                                        <DataTable
                                            columns={columns}
                                            data={props.companyContacts}
                                            defaultSortField="surname"
                                            pagination
                                            highlightOnHover
                                            noHeader
                                            paginationPerPage={50}
                                            paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                        />
                                    ) : <Alert color="warning">No Contacts were found</Alert>
                                }
                            </>
                        </ListGroupItem>

                    </ListGroup>
                </Col>
            </Row>
        </>
    );
}