import { BaseForm, Validators } from '../FormBuilder';

export class AddSharpForm extends BaseForm {
 
    constructor(props) {
        
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "type",
                    label: "Type",
                    editor: {
                        name: "dropdown",
                        options: props.types
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "zoneId",
                    label: "Zone",
                    editor: {
                        name: "dropdown",
                        options: props.zones
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "operatorId",
                    label: "Operator",
                    editor: {
                        name: "dropdown",
                        options: props.operators
                    },
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "isAssigned",
                    label: "Is Assigned?",
                    validators: [
                        Validators.required()
                    ],
                    editor: "checkbox"
                },
            ]
        };
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }
}