import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { SimpleLoader } from '../Common/SimpleLoader';

 export function BasicModal(props) {
    const modalTitle = props.title &&  props.title.length > 0 ?  props.title : "Modal Title Goes Here!";
    const isLoaded = props.isLoaded !== undefined ? props.isLoaded : true;

    return (
        <div>
            <Modal isOpen={props.isOpen} toggle={props.toggle} className={props.className} size={props.size}>
                <ModalHeader toggle={props.toggle}>{modalTitle}</ModalHeader>
                <ModalBody>
                    { isLoaded ? props.children  : <SimpleLoader active={true} spinner text="Loading..." />}
                </ModalBody>
            </Modal>
        </div>
    );
}