import React from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Role } from '../../enums';
import { PrivateRoute } from '../AuthApp/PrivateRoute'
import { LogoutForm } from '../AuthApp/LogoutForm';
import { DashboardContainer } from '../Dashboard/Container';
import { ErrorPage, NotFound, NotAuthorised, ReleaseNotes } from '../Pages';
import { TenantsListContainer, CreateTenantContainer, EditTenantContainer } from '../Tenants';
import { UsersListContainer, CreateUserContainer, EditUserContainer } from '../Users';
import { RolesListContainer, CreateRoleContainer, EditRoleContainer } from '../Roles';
import { CompaniesListContainer, CompanyViewContainer } from '../Companies';
import { ClassificationTypesListContainer, CreateClassificationTypeContainer, EditClassificationTypeContainer } from '../ClassificationTypes';
import { OperatorsListContainer, CreateOperatorContainer, EditOperatorContainer } from '../Operators';
import { CustomerComplaintListContainer, CustomerComplaintViewContainer, CreateCustomerComplaintContainer } from '../CustomerComplaints';
import { ConvertingNcrListContainer, ConvertingNcrViewContainer, CreateConvertingNcrContainer } from '../ConvertingNcrs';
import { PrepressNcrListContainer, PrepressNcrViewContainer, CreatePrepressNcrContainer } from '../PrepressNcrs';
import { FoilingEmbossingNcrListContainer, FoilingEmbossingNcrViewContainer, CreateFoilingEmbossingNcrContainer } from '../FoilingEmbossingNcrs';
import { PrintingNcrListContainer, PrintingNcrViewContainer, CreatePrintingNcrContainer } from '../PrintingNcrs';
import { BusinessSupportNcrListContainer, BusinessSupportNcrViewContainer, CreateBusinessSupportNcrContainer } from '../BusinessSupportNcrs';
import { SupplierNcrListContainer, SupplierNcrViewContainer, CreateSupplierNcrContainer } from '../SupplierNcrs';
import { DespatchNcrListContainer, DespatchNcrViewContainer, CreateDespatchNcrContainer } from '../DespatchNcrs';
import { GlobalAlertsListContainer, CreateGlobalAlertContainer, EditGlobalAlertContainer } from '../GlobalAlerts';

// Audits
import { ZonesListContainer, AddZoneContainer, EditZoneContainer } from '../Zones';
import { TemplatesListContainer, AddTemplateFormContainer, EditTemplateFormContainer } from '../Templates';
import { AuditReportsListContainer, ScheduleAuditReportFormContainer, EditAuditReportFormContainer, EditSharpAuditReportFormContainer } from '../AuditReports';
import { DiscrepanciesListContainer, AddDiscrepancyContainer, EditDiscrepancyContainer } from '../Discrepancies'
import { SharpsListContainer, AddSharpContainer, EditSharpContainer } from "../Sharps";

// Lookups
import { LookupsListContainer } from '../Lookups';


// Routes
import { AUDIT_REPORTS, SHARPS } from '../../constants/route';


export function Router(props) {  
    const errorsPath = 'error';
    const releaseNotesPath = 'release-notes';
    const tenantsPath = 'organisations';
    const usersPath = 'users';
    const rolesPath = 'roles';
    const companiesPath = 'companies';
    const classificationTypesPath = 'classification-types';
    const operatorsPath = 'operators';
    const customerComplaintsPath = 'customer-complaints';
    const convertingNcrsPath = 'converting-ncrs';
    const prepressNcrsPath = 'prepress-ncrs';
    const foilingEmbossingNcrsPath = 'foiling-embossing-ncrs';
    const printingNcrsPath = 'printing-ncrs';
    const businessSupportNcrsPath = 'business-support-ncrs';
    const supplierNcrsPath = 'supplier-ncrs';
    const despatchNcrsPath = 'despatch-ncrs';
    const globalAlertsPath = 'global-alerts';
    const zonesPath = 'zones';
    const templatesPath = 'templates';
    const discrepanciesPath = 'discrepancies';
  
    return (
        <Switch>            
            <PrivateRoute exact path='/' component={DashboardContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />

            <PrivateRoute exact path={'/' + tenantsPath + ''} component={TenantsListContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + tenantsPath + '/create'} component={CreateTenantContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + tenantsPath + '/edit/:tenantId'} component={EditTenantContainer} roles={[Role.SuperAdmin, Role.Admin]} />

            <PrivateRoute exact path={'/' + usersPath + ''} component={UsersListContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + usersPath + '/create'} component={CreateUserContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + usersPath + '/edit/:userId'} component={EditUserContainer} roles={[Role.SuperAdmin, Role.Admin]} />

            <PrivateRoute exact path={'/' + rolesPath + ''} component={RolesListContainer} roles={[Role.SuperAdmin]} />
            <PrivateRoute path={'/' + rolesPath + '/create'} component={CreateRoleContainer} roles={[Role.SuperAdmin]} />
            <PrivateRoute path={'/' + rolesPath + '/edit/:roleId'} component={EditRoleContainer} roles={[Role.SuperAdmin]} />

            <PrivateRoute exact path={'/' + companiesPath + ''} component={CompaniesListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + companiesPath + '/view/:companyId'} component={CompanyViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />

            <PrivateRoute exact path={'/' + classificationTypesPath + ''} component={ClassificationTypesListContainer} roles={[Role.SuperAdmin]} />
            <PrivateRoute path={'/' + classificationTypesPath + '/create'} component={CreateClassificationTypeContainer} roles={[Role.SuperAdmin]} />
            <PrivateRoute path={'/' + classificationTypesPath + '/edit/:classificationTypeId'} component={EditClassificationTypeContainer} roles={[Role.SuperAdmin]} />

            <PrivateRoute exact path={'/' + operatorsPath + ''} component={OperatorsListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + operatorsPath + '/create'} component={CreateOperatorContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + operatorsPath + '/edit/:operatorId'} component={EditOperatorContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />

            <PrivateRoute exact path={'/' + customerComplaintsPath + ''} component={CustomerComplaintListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + customerComplaintsPath + '/view/:customerComplaintId'} component={CustomerComplaintViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + customerComplaintsPath + '/create'} component={CreateCustomerComplaintContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />   

            <PrivateRoute exact path={'/' + convertingNcrsPath + ''} component={ConvertingNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + convertingNcrsPath + '/view/:convertingNcrId'} component={ConvertingNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + convertingNcrsPath + '/create'} component={CreateConvertingNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />      

            <PrivateRoute exact path={'/' + prepressNcrsPath + ''} component={PrepressNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + prepressNcrsPath + '/view/:prepressNcrId'} component={PrepressNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + prepressNcrsPath + '/create'} component={CreatePrepressNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />    

            <PrivateRoute exact path={'/' + foilingEmbossingNcrsPath + ''} component={FoilingEmbossingNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + foilingEmbossingNcrsPath + '/view/:foilingEmbossingNcrId'} component={FoilingEmbossingNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + foilingEmbossingNcrsPath + '/create'} component={CreateFoilingEmbossingNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />   

            <PrivateRoute exact path={'/' + printingNcrsPath + ''} component={PrintingNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + printingNcrsPath + '/view/:printingNcrId'} component={PrintingNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + printingNcrsPath + '/create'} component={CreatePrintingNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />      

            <PrivateRoute exact path={'/' + businessSupportNcrsPath + ''} component={BusinessSupportNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + businessSupportNcrsPath + '/view/:businessSupportNcrId'} component={BusinessSupportNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + businessSupportNcrsPath + '/create'} component={CreateBusinessSupportNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />   

            <PrivateRoute exact path={'/' + supplierNcrsPath + ''} component={SupplierNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + supplierNcrsPath + '/view/:supplierNcrId'} component={SupplierNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + supplierNcrsPath + '/create'} component={CreateSupplierNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />   

            <PrivateRoute exact path={'/' + despatchNcrsPath + ''} component={DespatchNcrListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} /> 
            <PrivateRoute path={'/' + despatchNcrsPath + '/view/:despatchNcrId'} component={DespatchNcrViewContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            <PrivateRoute path={'/' + despatchNcrsPath + '/create'} component={CreateDespatchNcrContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />      

            <PrivateRoute exact path={'/' + globalAlertsPath + ''} component={GlobalAlertsListContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + globalAlertsPath + '/create'} component={CreateGlobalAlertContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            <PrivateRoute path={'/' + globalAlertsPath + '/edit/:globalAlertId'} component={EditGlobalAlertContainer} roles={[Role.SuperAdmin, Role.Admin]} />
            
            <PrivateRoute exact path={'/' + zonesPath + ''} component={ZonesListContainer} roles={[Role.AuditAdmin]} />
            <PrivateRoute path={'/' + zonesPath + '/add'} component={AddZoneContainer} roles={[Role.AuditAdmin]} />
            <PrivateRoute path={'/' + zonesPath + '/edit/:zoneId'} component={EditZoneContainer} roles={[Role.AuditAdmin]} />        
           
            <PrivateRoute exact path={'/' + templatesPath + ''} component={TemplatesListContainer} roles={[Role.AuditAdmin]} />   
            <PrivateRoute path={'/' + templatesPath + '/add'} component={AddTemplateFormContainer} roles={[Role.AuditAdmin]} />
            <PrivateRoute path={'/' + templatesPath + '/edit/:templateId'} component={EditTemplateFormContainer} roles={[Role.AuditAdmin]} />

            { /* Audit Reports */ }            
            <PrivateRoute exact path={AUDIT_REPORTS} component={AuditReportsListContainer} roles={[Role.AuditAdmin, Role.Auditor]} />            
            <PrivateRoute exact path={`${AUDIT_REPORTS}/schedule`} component={ScheduleAuditReportFormContainer} roles={[Role.AuditAdmin]} />
            <PrivateRoute exact path={`${AUDIT_REPORTS}/:reportId`} component={EditAuditReportFormContainer} roles={[Role.AuditAdmin, Role.Auditor]} />
            <PrivateRoute exact path={`${AUDIT_REPORTS}/sharps/:reportId`} component={EditSharpAuditReportFormContainer} roles={[Role.AuditAdmin, Role.Auditor]} />

            { /* Sharps  */ }
            <PrivateRoute exact path={SHARPS} component={SharpsListContainer} roles={[Role.AuditAdmin]} />
            <PrivateRoute exact path={`${SHARPS}/add`} component={AddSharpContainer} roles={[Role.AuditAdmin]} />
            <PrivateRoute exact path={`${SHARPS}/edit/:sharpId`} component={EditSharpContainer} roles={[Role.AuditAdmin]} />


            <PrivateRoute exact path={'/' + discrepanciesPath + ''} component={DiscrepanciesListContainer} roles={[Role.AuditAdmin, Role.Auditor]} />
            <PrivateRoute path={'/' + discrepanciesPath + '/add'} component={AddDiscrepancyContainer} roles={[Role.AuditAdmin, Role.Auditor]} />
            <PrivateRoute path={'/' + discrepanciesPath + '/edit/:discrepancyId'} component={EditDiscrepancyContainer} roles={[Role.AuditAdmin, Role.Auditor]} />
            
            { /* Lookups */ }
            <PrivateRoute exact path='/lookups' component={LookupsListContainer} roles={[Role.SuperAdmin, Role.Admin, Role.User]} />
            
            <Route path='/logout' render={() => <LogoutForm user={props.data.user} handleLogout={props.actions.logout} />} />

            <Route exact path={'/' + errorsPath} component={ErrorPage} />
            <Route path={'/' + errorsPath + '/not-authorised'} component={NotAuthorised} />
            <Route path={'/' + errorsPath + '/not-found'} component={NotFound} />
            <Route path={'/' + releaseNotesPath + ''} component={ReleaseNotes} />

            <Redirect to={'/' + errorsPath + '/not-found'} />
        </Switch>
    );
}