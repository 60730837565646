import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { UsersList } from './List';
import { UserService } from '../../services/UserService';

export class UsersListContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            users: [],
            isUsersLoaded: false,
            isSearchingActive: false
        };

        // Bind the finctions that will be passed to the other components.
        // Doing this means that the function will be executed within the context of this container.
        this.handleSearch = this.handleSearch.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.changePassword = this.changePassword.bind(this);

        // Define and initialise the the relevant properties and services etc.
        this.UserService = new UserService();
    }

    componentDidMount() {
        // Retrieve the Users.
        // Once these have been obtained, we will add these items into the state
        // When they are in the state they can then be used within other components.
        this.handleSearch();
    }

    handleSearch(searchText) {
        this.setState({ 
            isSearchingActive: true
        });

        const handleResponse = (response) => {
            const users = response.data.results.map(user => {
                return {
                    ...user
                };
            });

            this.setState({ 
                users: users,
                isUsersLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = (error) => {
            NotificationManager.error('Could not obtain results for Users', 'Error', 1000);
        };

        if(searchText && searchText.length > 0) {
            this.UserService.search(searchText, 1, 1000, 'UserName', 'asc').then(handleResponse, handleError);
        }
        else {
            this.UserService.getList(1, 1000, 'UserName', 'asc').then(handleResponse, handleError);
        }
    }

    handleEdit(user, e) {
        e.preventDefault();
        this.props.history.push('/users/edit/' + user.id);
    }

    handleDelete(user, e) {
        e.preventDefault();

        this.UserService.delete(user.id).then(
            response => { 
                const users = _.filter(this.state.users, x => x.id !== user.id);
                this.setState({ users: users }, () => {
                    NotificationManager.success('The User ' + user.firstName + ' ' + user.surname + ' was deleted.', 'User Deleted', 1000);
                });
            },
            error => {
                NotificationManager.error('User was not deleted', 'Error', 1000);
            }
        );
    }

    changePassword(user, e) {   
        const model = {
            password: user.password,
            passwordConfirm: user.passwordConfirm
        };

        this.UserService.setPassword(user.id, model).then(
            response => { 
                NotificationManager.success('The password was changed succesfully', 'User Password Changed', 1000);
            },
            error => {
                NotificationManager.error('User password was not changed', 'Error', 1000);
            }
        );      
    }

    render() {
        const isLoaded = this.state.isUsersLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink  to="/roles" className="btn btn-sm btn-secondary" style={{marginRight: '5px'}}>
                    <Icon.List className="feather" />&nbsp;&nbsp;Manage Roles
                </NavLink>
                <NavLink  to="/users/create" className="btn btn-sm btn-success">
                    <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create User
                </NavLink>
            </>
            
        );
        
        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Users..."
        };

        return (
            <Screen title="Users" menu={screenMenu} loader={screenLoader}>
                <UsersList
                    items={this.state.users}
                    handleSearch={this.handleSearch}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                    changePassword={this.changePassword}
                />
            </Screen>
        );
    }
}
