import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteClassificationTypeModal } from './ConfirmDeleteModal';

export const ClassificationTypeActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleConfirmDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen
        }));        
    };

    const handleDeleteConfirmed = (entity, e) => {
        props.handleDelete(entity, e);
    };

    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleEdit(props.classificationType, e)}><Icon.Edit2 className="feather" />&nbsp; Edit</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem onClick={() => toggleConfirmDeleteModal()}><Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <ConfirmDeleteClassificationTypeModal 
                isOpen={state.isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal} 
                handleConfirm={handleDeleteConfirmed}
                classificationType={props.classificationType}
            />
        </>
    );
}