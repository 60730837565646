import React from 'react';
import { useMediaQuery } from 'react-responsive';

export function StickyFooter({ children }) {
    const footerStyle = {
        backgroundColor: "lightgrey",
        color: "#2a2a2b",
        fontSize: "0.9em",
        padding: "16px",
        position: "fixed",
        zIndex: 1000,
        left: "0",
        bottom: "0",
        width: "100%",
    };

    const phantomStyle = {
        display: "block",
        padding: "20px",
        height: "60px",
        width: "100%"
    };
    
    const isSmallDevice = useMediaQuery({ maxWidth: 768 });

    if (isSmallDevice) {
        return <></>;
    } else {
        return (
            <footer>
                <div style={phantomStyle} />
                <div style={footerStyle}>{children}</div>
            </footer>
        );
    }
}