import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Button, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { BasicModal } from '../../Modals/Basic';
import { Can } from '../../RBAC/Can';

export const FormJobDetails = props => {
    const [isEditJobDetailsModalOpen, setIsEditJobDetailsModalOpen] = useState(false);

    const toggleEditJobDetailsModal = () => setIsEditJobDetailsModalOpen(!isEditJobDetailsModalOpen);

    return (
        <>
            {/* Job Details */}
            <ListGroupItem>
                <ListGroupItemHeading> Job Details
                    <Can
                        perform="forms:edit"
                        yes={() => (
                            <>
                                <Button className="float-right" color="success" size="sm" onClick={toggleEditJobDetailsModal} style={{ margin: "0px 5px 0px 5px" }}>
                                    <Icon.Edit2 className="feather" />
                                </Button>
                            </>
                        )}
                    />
                </ListGroupItemHeading>
                <ListGroupItemText>
                    Provides a summary of some of the most important details for the job.
                </ListGroupItemText>
                {props.render}
            </ListGroupItem>

            {/* Modal */}
            <BasicModal
                isOpen={isEditJobDetailsModalOpen}
                toggle={toggleEditJobDetailsModal}
                title="Edit Job Details"
            >
                {props.modal}
            </BasicModal>
        </>
    );
}