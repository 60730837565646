import React from 'react';
import { Alert, Table, Button } from 'reactstrap';
import { BasicModal } from '../Modals/Basic';

export function SwitchTenantModal(props) {

    const hasAny = props.tenants && props.tenants.length > 0;

    return (
        <BasicModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            title="Switch Organisation"
        >

            {
                hasAny ?
                    <>
                        <Alert color="warning">
                            <strong>Select the Organisation that you wish to switch to from the list below.</strong>
                        </Alert>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th className="col-xs-4">Tenant</th>
                                    <th className="col-xs-4"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.tenants.map(tenant => {
                                        const isCurrent = (props.currentTenant && tenant.id === props.currentTenant.id);

                                        return (
                                            <tr key={tenant.id}>
                                                <td>{tenant.name}</td>
                                                <td>
                                                    {
                                                        isCurrent ? (
                                                            <span>Already selected.</span>
                                                        ) : (
                                                                <Button color="success" size="sm" onClick={() => props.handleSwitchTenant(tenant)}>Select</Button>
                                                            )
                                                    }

                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </>
                    : <Alert color="warning"><strong>No Organisations were found</strong></Alert>
            }
        </BasicModal>
    );
}
