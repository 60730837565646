import React from 'react';
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom";
import { Alert }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { SharpActionsButton } from './SharpActionsButton';

export function SharpsList(props) {
    const columns = [
        {
            name: 'Type',
            selector: 'type',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/sharps/edit/' + row.id}>{row.type.name}</NavLink>
            }
        },
        {
            name: 'Zone',
            selector: 'zone',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <span>{row.zone.name}</span>
            }
        },
        {
            name: 'Operator',
            selector: 'operator',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
               return <span>{row.operator.firstName} {row.operator.surname}</span>
            }
        },
        {
            name: 'Is Assigned',
            selector: 'isAssigned',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                
                return row.isAssigned ? <span>Yes</span> : <span>No</span>
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (                    
                    <SharpActionsButton 
                        sharp={row} 
                        handleEdit={props.handleEdit} 
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="name"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No sharps were found</Alert>
            }
        </>
    );
}

