import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { TenantForm } from './Form';
import { EntityService } from '../../services/EntityService';
import { UserService } from '../../services/UserService';

export class CreateTenantContainer extends React.Component {
   constructor(props) {
       super(props);

       // Setup the default state
       this.state = {
           users: [],
           isUsersLoaded: false
       };

       this.TenantService = new EntityService("Tenants");
       this.UserService = new UserService();
       
       this.createTenant = this.createTenant.bind(this);
   }

   componentDidMount() {
       this.UserService.getOwners(1, 1000, "UserName", "asc").then(
           response => {
               const users = response.data.results.map(user => {
                   return {
                       id: user.id,
                       name: user.firstName + ' ' + user.surname
                   };
               });
               this.setState({
                   users: users,
                   isUsersLoaded: true
               });
           },
           error => {
               NotificationManager.error('Could not obtain users', 'Error', 1000);
           }
       );
   }

   createTenant(tenant) {
       var model = {
           name: tenant.name,
           identifier: tenant.identifier,
           ownerId: tenant.ownerId,
           shortcode: tenant.shortcode
       };

       this.TenantService.create(model).then(
           response => {
               const tenant = response.data;
               this.props.history.push('/organisations/edit/' + tenant.id);
               NotificationManager.success('The Organisation ' + tenant.name + ' was created successfully', 'Organisation Created');
           },
           response => {
               NotificationManager.error('Organisation was not created', 'Error', 1000);
           },
       );
   }

   render() {
       const isLoaded = this.state.isUsersLoaded;

       // Build the Screen menu.
       // This will be passed to the Screen component so that it can be rendered in the relevant place.
       const screenMenu = (
           <>
               <NavLink  to="/organisations" className="btn btn-sm btn-outline-secondary">
                   <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Organisations List
               </NavLink>
           </>
       );

       // Define the setting for the screen loader
       // This object will be passed to the Screen component,
       // Which will determine how and if the loader should be displayed
       const screenLoader = {
           isEnabled: true,
           isLoaded: isLoaded,
           text: "Loading Create Organisation Form..."
       };

       return (
           <Screen title="Create Organisation" menu={screenMenu} loader={screenLoader}>
               <TenantForm
                   ref={(el) => this.tenantForm = el}
                   handleSave={this.createTenant}
                   
                   ownerOptions={this.state.users}
               />
           </Screen>
       );
   }
}