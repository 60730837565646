import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { EditDiscrepancyForm } from './EditDiscrepancyForm';
import { DiscrepancyService } from '../../services/DiscrepancyService';
import moment from 'moment';
import {EntityService} from "../../services/EntityService";

export const EditDiscrepancyContainer = (props) => {
    const history = useHistory();    
    const [inputValues, setInputValues] = useState({});
    const [isDiscrepancyLoaded, setIsDiscrepancyLoaded] = useState(false);
    const [discrepancyService] = useState(new DiscrepancyService());
    const discrepancyId = props.match.params.discrepancyId * 1;
    const [categories, setCategories] = useState([]);
    const [classificationTypeService] = useState(new EntityService('ClassificationTypes'));
    
    useEffect(() => {
        // Fetch all classification types
        classificationTypeService.getList(1, 1000, "Name", "desc").then(
            response => {
                const categories = response.data.results.map(category => {
                    return {
                        id: category.id,
                        name: category.name
                    };
                });

               setCategories(categories);
            },
            error => {
                NotificationManager.error('Could not obtain classification types', 'Error', 1000);
            }
        );

        discrepancyService.getDiscrepancy(discrepancyId).then(
            response => {
                const discrepancy = {
                    ...response.data,
                    discrepancyCategoryId: response.data.discrepancyCategory ? response.data.discrepancyCategory.id * 1 : null,
                    previouslyAuditedOnUtc: response.data.previouslyAuditedOnUtc ? moment(response.data.previouslyAuditedOnUtc).format('YYYY-MM-DD') : '',
                    correctiveActionScheduledCompletionDateUtc: response.data.correctiveActionScheduledCompletionDateUtc ? moment(response.data.correctiveActionScheduledCompletionDateUtc).format('YYYY-MM-DD') : '',
                    correctiveActionDate: response.data.correctiveActionDate ? moment(response.data.correctiveActionDate).format('YYYY-MM-DD') : '',
                    implementedActionOnUtc: response.data.implementedActionOnUtc ? moment(response.data.implementedActionOnUtc).format('YYYY-MM-DD') : ''
                }
                
                setInputValues(discrepancy);
                setIsDiscrepancyLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain categories', 'Error', 1000);
            }
        );
    }, [discrepancyId, discrepancyService, classificationTypeService]);

    const handleInputChange = (field, value) => {
        setInputValues({
            ...inputValues,
            [field]: value,
        });
    };

    const handleFinish = async (event) => {
        event.preventDefault();

        const discrepancyId = props.match.params.discrepancyId * 1;
    
        const discrepancyInput = {
            id: discrepancyId,
            ...inputValues
        };
    
        try {
            // First, save the report
            const saveResponse = await discrepancyService.editDiscrepancy(discrepancyInput);
            // If the save was successful, finish the report
            const finishResponse = await discrepancyService.finishDiscrepancy({ id: discrepancyId });
    
            // If both operations were successful, redirect and show a success message
            history.push('/discrepancies');
            NotificationManager.success(finishResponse.data.successMessage, 'Success');
        } catch (error) {
            // Access the error message from error.response.data
            const errorMessage = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].message : 'Finish failed.';
            NotificationManager.error(errorMessage, 'Error', 1000);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        discrepancyService.editDiscrepancy(inputValues).then(
            response => {
                const discrepancy = response.data.content;
                history.push('/discrepancies/edit/' + discrepancy.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                // Access the error message from error.response.data
                const errorMessage = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].message : 'Could not add discrepancy';
                NotificationManager.error(errorMessage, 'Error', 1000);
            },
        );
    }

    // Build the Screen menu.
    const screenMenu = (
        <>
            <NavLink to="/discrepancies" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Discrepancies List
            </NavLink>
        </>
    );

    // Define the setting for the screen loader
    const screenLoader = {
        isEnabled: true,
        isLoaded: isDiscrepancyLoaded,
        text: "Loading..."
    };

    return (
        <Screen title="Edit Discrepancy" menu={screenMenu} loader={screenLoader}>
            <EditDiscrepancyForm
                inputValues={inputValues}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                handleFinish={handleFinish}
                categories={categories}
            />
        </Screen>
    );
}