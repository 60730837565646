import React from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import _ from 'lodash';
import { FormGroup, Label } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";

export class DatePickerField extends React.Component {
    constructor(props) {
        super(props);

        this.defaultOptions = {
            inputFormat: "YYYY-MM-DD",
            outputFormat: "YYYY-MM-DD HH:mm:ss.SSS"
        };

        this.defaultVendorOptions = {
            dateFormat: "dd/MM/yyyy",                            
            isClearable: true,
            popperPlacement: "top-end",
            showMonthDropdown: false,
            showYearDropdown: false
        };

        this.options = _.defaults(this.props.field.editor.options, this.defaultOptions);
        this.vendorOptions = _.defaults(this.props.field.editor.options, this.defaultVendorOptions);

        this.handleDateChange = this.handleDateChange.bind(this);
    }
    
    handleDateChange(selectedDate) {
        const changeEventObj = {
            target: {
                name: this.props.field.name,
                value: ''
            }
        }

        if(selectedDate) {
            const date = moment(selectedDate);
            changeEventObj.target.value = date.format(this.options.outputFormat);            
        }
        
        this.props.actions.handleChange(changeEventObj);
    }

    render() {        
        const field = this.props.field;
        const actions = this.props.actions;

        const date = moment(field.value, this.options.inputFormat);
        const selectedDate = date.isValid() ? date.toDate() : '';

        return (
            <FormGroup className={actions.getErrorClass(field)}>
                <Label for={field.name}>{field.label}&nbsp;&nbsp;&nbsp;<small>{actions.getErrorMessage(field)}</small></Label>
                <br />  
                <DatePicker
                    selected={selectedDate}
                    onChange={this.handleDateChange}
                    className="form-control"
                    {...this.vendorOptions}
                />
            </FormGroup>
        );
    }
}