import React from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';

export function CheckboxField({ field, actions }) {
    return (
        <FormGroup className={actions.getErrorClass(field)}>
            <Label for={field.name}>{field.label}&nbsp;&nbsp;&nbsp;<small>{actions.getErrorMessage(field)}</small></Label>                            
            <div>
                <CustomInput type="checkbox" name={field.name} id={field.name} label="Tick if yes" checked={field.value} onChange={actions.handleChange} />
            </div>
        </FormGroup>
    );
}