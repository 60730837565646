import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import font from '../../../fonts/segoe-ui.ttf';
import fontSB from '../../../fonts/segoe-ui-semibold.ttf';

export const PdfTable = (props) => {

    const COLN_WIDTH = (100) / props.headers.length;

    // Register fonts
    Font.register({
        family: 'Segoe UI', fonts: [
            { src: font },
            { src: fontSB, fontStyle: 'semibold' }
        ]
    });

    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            margin: 10,
            marginTop: -1,
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: COLN_WIDTH + "%",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderBottomColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol: {
            width: COLN_WIDTH + "%",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: 5,
            fontSize: 11,
            fontStyle: 'semibold',
            fontFamily: 'Segoe UI'
        },
        tableCell: {
            margin: 5,
            fontSize: 11,
            fontFamily: 'Segoe UI'
        }
    });

    return (
        <View style={styles.table}>
            <View style={styles.tableRow}>
                {
                    props.headers.map((header, index) => {
                        return (
                            <View style={styles.tableColHeader} key={index}>
                                <Text style={styles.tableCellHeader}>{header}</Text>
                            </View>
                        );
                    })
                }
            </View>
            <View style={styles.tableRow}>
                {
                    props.cells.map((cell, index) => {
                        return (
                            <View style={styles.tableCol} key={index}>
                                <Text style={styles.tableCell}>{cell}</Text>
                            </View>
                        );
                    })
                }
            </View>
        </View>
    );
};