import React from 'react';
import * as Icon from 'react-feather';
import { Card, CardTitle, CardText } from 'reactstrap';

export function FormTotalCard(props) {
    var isIncreased = Math.sign(props.difference) === -1 ? false : true

    if (isIncreased) {
        return <Card className="stat-box increase">
            <p>{props.title}</p>
            <CardTitle>{props.count}</CardTitle>
            <CardText><Icon.ArrowUp className="feather" /> {props.difference}%</CardText>
        </Card>
    }
    else {
        return <Card className="stat-box decrease">
            <p>{props.title}</p>
            <CardTitle>{props.count}</CardTitle>
            <CardText><Icon.ArrowDown className="feather" /> {props.difference}%</CardText>
        </Card>
    }
}



