import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Button, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { BasicModal } from '../../Modals/Basic';
import { Can } from '../../RBAC/Can';

export const FormSummary = props => {
    const [isEditSummaryModalOpen, setIsEditSummaryModalOpen] = useState(false);
    const toggleEditSummaryModal = () => setIsEditSummaryModalOpen(!isEditSummaryModalOpen);

    return (
        <>
            {/* Summary */}
            <ListGroupItem>
                <ListGroupItemHeading> Summary
                <Can
                        perform="forms:edit"
                        yes={() => (
                            <>
                                <Button className="float-right" color="success" size="sm" onClick={toggleEditSummaryModal} style={{ margin: "0px 5px 0px 5px" }}>
                                    <Icon.Edit2 className="feather" />
                                </Button>
                            </>
                        )}
                    />
                </ListGroupItemHeading>
                <ListGroupItemText>
                    Provides a quick summary for the {props.title}, particularly for when the report was received and replied to.
                </ListGroupItemText>
                {props.render}
            </ListGroupItem>

            {/* Modal */}
            <BasicModal
                isOpen={isEditSummaryModalOpen}
                toggle={toggleEditSummaryModal}
                title="Edit Summary"
            >
                {props.modal}
            </BasicModal>
        </>
    );
}