import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../../Modals/Confirm';
import moment from 'moment';

export function ConfirmDeleteFollowUpModal(props) {
    return (
        <ConfirmModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            handleConfirm={(e) => props.handleConfirm(props.followUp, e)}
            title="Delete Follow Up?"
            confirmText="Yes, Delete Follow Up"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following Follow Up?</strong>
            </Alert>

            <Table bordered>
                <thead>
                    <tr>
                        <th className="col-xs-4">Assignee</th>
                        <th className="col-xs-3">Exp. Response Date</th>
                        <th className="col-xs-3">Date Created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.followUp.assignee != null ? props.followUp.assignee.firstName + ' ' + props.followUp.assignee.surname : 'N/A'}</td>
                        <td>{moment(props.followUp.expectedResponseDate).format('DD/MM/YYYY hh:mm A')}</td>
                        <td>{moment(props.followUp.dateCreated).format('DD/MM/YYYY hh:mm A')}</td>
                    </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );
}