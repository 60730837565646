

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Form } from '../FormBuilder/Form';
import { Validators } from '../FormBuilder';
import { AuthService } from '../../services/AuthService';
import { NotificationManager } from '../../helpers/NotificationManager';

export class ForgotPasswordForm extends Component {
    constructor(props) {
        super(props);       
        
        this.state = {
            showSuccess: false
        }

        this.formDefinition = {
            fields: [
                {
                    name: "email",
                    label: "Email",
                    validators: [
                        Validators.required(),
                        Validators.emailFormat()
                    ]
                }
            ]
        };

        this.handleForgotPassword = this.handleForgotPassword.bind(this);

        this.AuthService = new AuthService();
    } 

    handleForgotPassword(email) {
        this.AuthService.forgotPassword(email).then(
            response => {
                this.setState({ showSuccess: true });
            },
            error => {
                NotificationManager.error('Error occurred attempting to handle forgot password', 'Error', 1000);
            }
        );
    }
    render() {
        const showSuccess = this.state.showSuccess === true ? true : false

        return !showSuccess ? (
            <>
                <h5>Forgot Password</h5>
                <p>Please enter your email address to request a password reset.</p>
                <Form
                    definition={this.formDefinition}
                    handleSave={this.handleForgotPassword}
                    
                />
            </>
        ) : (
                <>
                    <h5>Password Recovery Email Sent</h5>
                    <p>An e-mail has been sent to your inbox with instructions on how to reset your password.</p>
                    <NavLink to="/">Return to Login</NavLink>
                </>
            );
    }
}