import React from 'react';
import { BaseForm, Validators } from '../FormBuilder';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';

export class GlobalAlertCreateForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "title",
                    label: "Title",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "description",
                    label: "Description",
                    validators: [
                        Validators.required()
                    ],
                    editor: "textarea"
                },
                {
                    name: "companyId",
                    label: "Company (If Applicable)",
                    editor: {
                        name: "dropdown",
                        options: props.companyOptions
                    }
                },
                {
                    name: "startDate",
                    label: "Start Date",
                    editor: {
                        name: "datepicker",
                        options: {
                            outputFormat: "YYYY-MM-DD"
                        }
                    },
                    validators: [
                        Validators.required(),
                        Validators.isDate()
                    ]
                },
                {
                    name: "endDate",
                    label: "End Date",
                    editor: {
                        name: "datepicker",
                        options: {
                            outputFormat: "YYYY-MM-DD"
                        }
                    },
                    validators: [
                        Validators.required(),
                        Validators.isDate()
                    ]
                }
            ]
        };
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }

    getModel(fields) {
        // This function will override the logic to generate the model that is returned after submitting the form.
        // The generated model will be passed to the relevant handleSave() event, where it can be used to process the data accordingly.

        // Get the model that is generated by the BaseForm.
        // This gets the value for each field defined within the form and returns it as an object.
        // We can use this as the base model to work from and modify it accordingly before we return it.    
        const model = {
            ...this._getModel(fields),
            companyId: fields.companyId.value !== "" ? fields.companyId.value * 1 : null
        };

        return model;
    }  

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {  
        return (
            <Row form>
                <Col xs="12" md="12">
                    <FieldEditor field={fields.title} actions={this.fieldActions} />
                    <FieldEditor field={fields.description} actions={this.fieldActions}/>
                    <FieldEditor field={fields.companyId} actions={this.fieldActions} />      
                    <FieldEditor field={fields.startDate} actions={this.fieldActions} />   
                    <FieldEditor field={fields.endDate} actions={this.fieldActions} />             
                </Col>
            </Row>
        );
    }
}