import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteFoilingEmbossingNcrModal } from './ConfirmDeleteModal';
import { Can } from '../RBAC/Can';
import { FoilingEmbossingNcrService } from '../../services/FoilingEmbossingNcrService';
import moment from 'moment';
import { PdfTable } from '../FormComponents/Pdf/PdfTable';
import { PdfActionItem } from '../FormComponents/Pdf/PdfActionItem';

export const FoilingEmbossingNcrActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleConfirmDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen
        }));        
    };

    const handleDeleteConfirmed = (entity, e) => {
        props.handleDelete(entity, e);
    };

    const formTitle = "Foiling/Embossing NCR";
    const [formService] = useState(new FoilingEmbossingNcrService());
    const form = props.foilingEmbossingNcr;
    const formId = form.formId * 1;

    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleView(props.foilingEmbossingNcr, e)}> <Icon.FileText className="feather" />&nbsp; View</DropdownItem>
                        <DropdownItem divider />
                        
                        <PdfActionItem
                            formId={formId}
                            formService={formService}
                            title={formTitle}
                status={form.status}
                            logNo={form.ncrLogNo}
                            summary={
                                <>
                                    <PdfTable
                                        headers={["NCR Log No", "Resolved by Contractor Repair", "Date Contractor Arrived", "Operator Responsible"]}
                                        cells={[form.ncrLogNo,
                                        form.isResolvedByContractorRepair === true ? "Yes" : "No",
                                        form.dateContractorArrived != null ? moment(form.dateContractorArrived).format('DD/MM/YYYY') : "N/A",
                                        form.operator != null ? form.operator.firstName + ' ' + form.operator.surname : "N/A"
                                        ]}
                                    />
                                    <PdfTable
                                        headers={["Description of Issue"]}
                                        cells={[form.description]}
                                    />
                                </>
                            }
                            jobDetails={
                                <PdfTable
                                    headers={["Customer", "SKU", "Work Ticket / Batch No", "Quantity"]}
                                    cells={[form.customer !== undefined ? form.customer.name : "N/A", form.stockCode, form.workTicketNo, form.quantity]}
                                />
                            }
                        />

                        <Can
                            perform="forms:delete"
                            yes={() => (
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => toggleConfirmDeleteModal()}> <Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                                </>
                            )}
                        />  
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <ConfirmDeleteFoilingEmbossingNcrModal 
                isOpen={state.isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal} 
                handleConfirm={handleDeleteConfirmed}
                foilingEmbossingNcr={props.foilingEmbossingNcr}
            />
        </>
    );
}

