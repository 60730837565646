import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { EditUserForm } from './EditForm';
import { UserService } from '../../services/UserService';
import { EntityService } from '../../services/EntityService';
import { AuthService } from '../../services/AuthService';
 
export class EditUserContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            roles: [],
            isRolesLoaded: false,
            user: {},
            isUserLoaded: false,
            tenants: [],
            isTenantsLoaded: false
        };

        this.UserService = new UserService();
        this.EntityService = new EntityService("Roles");
        this.AuthService = new AuthService();

        this.updateUser = this.updateUser.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.userId = this.props.match.params.userId;
    }

    componentDidMount() {
        this.UserService.getById(this.userId).then(
            response => {
                console.log("user:", response.data.user);
                const user = {
                    ...response.data,                    
                }
                this.setState({
                    user: user,
                    isUserLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified user', 'Error', 1000);
            }
        );

        this.EntityService.getList(1, 1000, "Name", "asc").then(
            response => {
                const roles = response.data.results.map(role => {
                    return {
                        id: role.id,
                        name: role.name
                    };
                });
                this.setState({
                    roles: roles,
                    isRolesLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain roles', 'Error', 1000);
            }
        );

        // Get a list of Tenants (specific to currentUser)
        this.AuthService.getMyTenants().then(
            response => {
                const tenants = response.data.results.map(tenant => {
                    return {
                        id: tenant.id,
                        name: tenant.name
                    };
                });
                this.setState({
                    tenants: tenants,
                    isTenantsLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain tenants', 'Error', 1000);
            }
        );
    }

    updateUser(user) {
        var model = {
            ...user,
            roleIds: user.roles.map(role => role.id)
        };

        this.UserService.update(this.userId, model).then(
            response => {
                // Alert the client that the user has been updated
                NotificationManager.success('The user ' + user.firstName + ' ' + user.surname + ' was updated.', 'User Updated', 1000);
            },
            error => {
                // Alert the client that the user has NOT been updated
                NotificationManager.error('User was not updated', 'Error', 1000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the UserForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.userForm && this.userForm.handleSubmit) {
            const form = this.userForm.state.form
            if (form.isValid) {
                this.userForm.handleSubmit(e);
            }
            else {
                this.userForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 1000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isUserLoaded & this.state.isRolesLoaded && this.state.isTenantsLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/users" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Users List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit User Form..."
        };

        return (
            <Screen title="Edit User" menu={screenMenu} loader={screenLoader}>
                <EditUserForm
                    ref={(el) => this.userForm = el}
                    data={this.state.user}
                    handleSave={this.updateUser}                    
                    roleOptions={this.state.roles}
                    tenantOptions={this.state.tenants}
                    currentTenant={this.state.user.tenant}
                />
            </Screen>
        );
    }
}