import React from 'react';
import { Loader } from '../Common/Loader';

export function Screen(props) {
    const loadingText = props.loader && props.loader.text && props.loader.text.length > 0 ? props.loader.text : 'Loading...';
    const isLoaded = props.loader && props.loader.isLoaded;

    return props.loader && props.loader.isEnabled
        ? (
            <Loader active={!isLoaded} spinner text={loadingText}>
                {
                    isLoaded ? (
                        <ScreenContent {...props} />
                    ) : null
                }
            </Loader>
        ) 
        : (
            <ScreenContent {...props} />
        );
}

function ScreenContent(props)
{
    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">{props.title}</h1>

                    {
                        props.menu ? (
                            <div className="btn-toolbar mb-2 mb-md-0">
                                {props.menu}
                            </div>
                        ) : null
                    }
                </div>

                {props.children}
            </section>
        </div>
    );
}