import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { PdfTable } from '../FormComponents/Pdf/PdfTable';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteCustomerComplaintModal } from './ConfirmDeleteModal';
import { Can } from './../RBAC/Can';
import moment from 'moment';
import { CustomerComplaintService } from '../../services/CustomerComplaintService';
import { PdfActionItem } from '../FormComponents/Pdf/PdfActionItem';

export const CustomerComplaintActionsButton = props => {    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleConfirmDeleteModal = () => setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen);    
    const handleDeleteConfirmed = (entity, e) => props.handleDelete(entity, e);

    const formTitle = "Customer Complaint";
    const [formService] = useState(new CustomerComplaintService());
    const form = props.customerComplaint;
    const formId = form.formId * 1;

    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={isDropdownOpen} toggle={toggleDropdown} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleView(props.customerComplaint, e)}> <Icon.FileText className="feather" />&nbsp; View</DropdownItem>
                        <DropdownItem divider />
                        
                        <PdfActionItem
                            formId={formId}
                            formService={formService}
                            title={formTitle}
                status={form.status}
                            logNo={form.ccrLogNo}
                            summary={
                                <>
                                    <PdfTable
                                        headers={["CCR Log No", "Customers' CCR Reference", "Date Complaint Raised", "Date of Response", "Is Product Being Returned?"]}
                                        cells={[
                                            form.ccrLogNo,
                                            form.customerReference,
                                            moment(form.dateCreated).format('DD/MM/YYYY'),
                                            moment(form.dateResponse).format('DD/MM/YYYY'),
                                            form.isProductReturned === true ? "Yes" : "No"
                                        ]}
                                    />
                                    <PdfTable
                                        headers={["Complaint Details"]}
                                        cells={[form.description]}
                                    />
                                </>
                            }
                            jobDetails={
                                <PdfTable
                                    headers={["Customer", "SKU", "Work Ticket / Batch No", "Date Despatched"]}
                                    cells={[form.customer === undefined ? "N/A" : form.customer.name, form.stockCode, form.workTicketNo, moment(form.dateDespatched).format('DD/MM/YYYY')]}
                                />
                            }
                        />
                        <Can
                            perform="forms:delete"
                            yes={() => (
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => toggleConfirmDeleteModal()}> <Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                                </>
                            )}
                        />  
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <ConfirmDeleteCustomerComplaintModal 
                isOpen={isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal} 
                handleConfirm={handleDeleteConfirmed}
                customerComplaint={props.customerComplaint}
            />
        </>
    );
}

