import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { throttle } from 'lodash';
import { refreshNavigation } from './reducers/navigationReducer';

// localStorage.js
export const loadState = () => {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) {
        return undefined;
      }
     
      return JSON.parse(serializedState);    
    } catch (err) {
      return undefined;
    }
  }; 

  export const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem('state', serializedState);
    } catch {
      // ignore write errors
    }
  };

const initialState = loadState();
const middleware = [thunk];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
);

store.subscribe(throttle(() => {
    saveState({
        navigationState: store.getState().navigationState
    });
}, 1000));

store.dispatch(refreshNavigation)

export default store;