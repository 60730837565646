import React, { useState, useEffect } from 'react';
import { Screen } from '../App/Screen';
import { Dashboard } from './';
import { Row, Col, Card } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { FormTotalCard } from './FormTotalCard';
import { DashboardService } from '../../services/DashboardService';
import { NotificationManager } from '../../helpers/NotificationManager';
import { DashboardFormsList } from './List';
import { useDispatch } from 'react-redux';

import { refreshNavigation } from '../../reducers/navigationReducer';

export const DashboardContainer = (props) => {

    const [chartData, setChartData] = useState({});
    const [isChartDataLoaded, setIsChartDataLoaded] = useState(false); // set to false

    const [customerComplaints, setCustomerComplaints] = useState([]);
    const [isCustomerComplaintsLoaded, setIsCustomerComplaintLoaded] = useState(false);

    const [nonConformanceReports, setNonConformanceReports] = useState([]);
    const [isNonConformanceReportsLoaded, setIsNonConformanceReportsLoaded] = useState(false);

    const [forms, setForms] = useState([]);
    const [isFormsLoaded, setIsFormsLoaded] = useState(false);

    // Services
    const [dashboardService] = useState(new DashboardService());
    const dispatchStore = useDispatch();

    useEffect(() => {
        // ALL forms
        dashboardService.getTenantForms().then(
            response => {
                const forms = response.data.results.map(form => {
                    return {
                        ...form
                    };
                });

                setForms(forms);
                setIsFormsLoaded(true);

                dispatchStore(refreshNavigation());
            },
            error => {
                NotificationManager.error('Could not obtain all forms', 'Error', 1000);
            }
        );

        // ALL forms
        dashboardService.getChartData().then(
            response => {
                const chartForms = response.data.map(chartForm => {
                    return {
                        ...chartForm
                    };
                });

                setChartData({
                    labels: chartForms.map(x => {
                        return x.name
                    }),
                    datasets: [
                        {
                            label: "No. of reports over the past month",
                            data: chartForms.map(x => {
                                return x.count
                            }),
                            backgroundColor: [
                                'rgba(1, 1, 1, 1)',
                                'rgba(0, 123, 255, 1)',
                                'rgba(23, 162, 184, 1)',
                                'rgba(40, 167, 69, 1)',
                                'rgba(255, 193, 7, 1)',
                                'rgba(220, 53, 69, 1)',
                                'rgba(52, 58, 64, 1)',
                                'rgba(108, 117, 125, 1)'
                            ]
                        }]
                });

                setIsChartDataLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain all forms', 'Error', 1000);
            }
        );

        // Total Customer Complaints
        dashboardService.getTotalCCRs().then(
            response => {
                const customerComplaints = {
                    ...response.data
                }

                setCustomerComplaints(customerComplaints);
                setIsCustomerComplaintLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain customer complaints', 'Error', 1000);
            }
        );

        // Total Non-Conformance Reports
        dashboardService.getTotalNCRs().then(
            response => {
                const nonConformanceReports = {
                    ...response.data
                }

                setNonConformanceReports(nonConformanceReports);
                setIsNonConformanceReportsLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain non-conformance reports', 'Error', 1000);
            }
        );
    }, [dashboardService]);

    const handleView = (form, e) => {
        e.preventDefault();
        props.history.push(form.url);
    };

    const isLoaded = isChartDataLoaded && isFormsLoaded && isCustomerComplaintsLoaded && isNonConformanceReportsLoaded;

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Dashboard..."
    };

    return (
        <Screen title="Dashboard" loader={screenLoader}>
            <div className="box box-primary">
                <div className="box-body">
                    <Dashboard user={props.user} />
                </div>
            </div>

            <Row>
                <Col sm="12" md="9">
                    <Card>
                        <Bar
                            data={chartData}
                            height={150}
                            options={{
                                maintainAspectRatio: true,
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                            min: 0
                                        }
                                    }]
                                }
                            }} />
                    </Card>
                </Col>
                <Col xs="12" sm="12" md="3">
                    <Col xs="12">
                        <FormTotalCard
                            title="CCRs over the past month"
                            count={customerComplaints.count}
                            difference={customerComplaints.difference} />
                    </Col>
                    <Col xs="12">
                        <FormTotalCard
                            title="NCRs over the past month"
                            count={nonConformanceReports.count}
                            difference={nonConformanceReports.difference} />
                    </Col>
                </Col>

            </Row>
            <br /><br />
            <Row>

                <Col sm="12">
                    <h5>Recently Added</h5>
                    <DashboardFormsList
                        items={forms}
                        handleView={handleView}
                    />
                </Col>
            </Row>
        </Screen>
    );
}
