import axios from 'axios';
import config from '../config';

export class ZoneService {
    constructor() {
        this.basePath = 'Zones';
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }

    getZones(page, size, sort, direction, searchTerm = '') {
        const endpoint = this.baseUrl;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
            }
        });
    }

    getZone(id) {
        const endpoint = this.baseUrl + id;
        return axios.get(endpoint);
    }

    addZone(zoneInput) {
        const endpoint = this.baseUrl;
        return axios.post(endpoint, zoneInput);
    }

    editZone(zoneInput) {
        const endpoint = this.baseUrl + zoneInput.id;
        return axios.put(endpoint, zoneInput);
    }

    deleteZone(id) {
        const endpoint = this.baseUrl + id;
        return axios.delete(endpoint);
    }

    // Reports

    getReports(page, size, sort, direction, searchTerm = '', status = '', zoneId = null) {
        const endpoint = this.baseUrl + 'reports';
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
                status: status,
                zoneId: zoneId
            }
        });
    }

    getReport(id) {
        const endpoint = this.baseUrl + 'report/' + id;
        return axios.get(endpoint);
    }

    scheduleReports(reportInput) {
        const endpoint = this.baseUrl + 'report/schedule';
        return axios.post(endpoint, reportInput);
    }

    startReport(reportInput) {
        const endpoint = this.baseUrl + 'report/start';
        return axios.post(endpoint, reportInput);
    }

    editReport(reportInput) {
        const endpoint = this.baseUrl + 'report/edit/';
        return axios.post(endpoint, reportInput);
    }

    finishReport(reportInput) {
        const endpoint = this.baseUrl + 'report/finish/' + reportInput.id;
        return axios.post(endpoint, reportInput);
    }

    deleteReport(id) {
        const endpoint = this.baseUrl + 'report/' + id;
        return axios.delete(endpoint);
    }
}