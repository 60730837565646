import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../Modals/Confirm';

export function ConfirmDeleteCustomerComplaintModal(props) {
    return (
        <ConfirmModal
            isOpen={props.isOpen}
            toggle={props.toggle}
            handleConfirm={(e) => props.handleConfirm(props.customerComplaint, e)}
            title="Delete Customer Complaint?"
            confirmText="Yes, Delete Customer Complaint"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following Customer Complaint?</strong>
            </Alert>

            <Table bordered>
                <thead>
                    <tr>
                        <th className="col-xs-4">CCR Log No</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.customerComplaint.ccrLogNo}</td>
                    </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );
}