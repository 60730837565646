import React, { useState, useEffect } from 'react';
import { Form, Input, Card, CardBody, CardTitle, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';

export const EditSharpAuditReportForm = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [reportSharps, setReportSharps] = useState([]);
    const [zoneSharps, setZoneSharps] = useState([]);

    useEffect(() => {

        // Initialize with zoneSharps and set isAssigned to false
        const initialSharps = props.zoneSharps.map(sharp => ({
            sharpId: sharp.id,
            isAssigned: false
        }));

        // Update with props.report.sharps
        const updatedSharps = initialSharps.map(sharp => {
            const reportSharp = props.report.sharps.find(rs => rs.sharpId === sharp.sharpId);
            return reportSharp ? { ...sharp, isAssigned: reportSharp.isAssigned } : sharp;
        });

        // Add any additional sharps from props.report.sharps that are not in initialSharps
        props.report.sharps.forEach(reportSharp => {
            if (!updatedSharps.some(sharp => sharp.sharpId === reportSharp.sharpId)) {
                updatedSharps.push(reportSharp);
            }
        });

        setReportSharps(updatedSharps);
        setZoneSharps(props.zoneSharps);

    }, [props.report.sharps, props.zoneSharps]);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleInputChange = (sharpId, isAssigned) => {
         setReportSharps(prevSharps => {            
            const updatedSharps = prevSharps.map(sharp =>             
                sharp.sharpId === sharpId ? { ...sharp, isAssigned: isAssigned } : sharp
            );

            if (!updatedSharps.some(sharp => sharp.sharpId === sharpId)) {
                updatedSharps.push({ sharpId: sharpId, isAssigned: isAssigned });
            }

            return updatedSharps;
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmit(reportSharps);
    };

    const handleFinish = (e) => {
        e.preventDefault();
        props.handleFinish(reportSharps);
    };

    // Group questions and answers by categoryName
    const groupedSharps = zoneSharps.reduce((acc, sh) => {
        acc[sh.type.name] = acc[sh.type.name] || [];
        acc[sh.type.name].push(sh);
        return acc;
    }, {});

    return (      

        <>
            <Row>
                <Col xs="12">
                    <h5>Auditor: {props.report.auditor}</h5>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    {props.report.status === 'Completed' && (
                        <h5>Completed On: {moment(props.report.completedOnUtc).format('DD/MM/YYYY')}</h5>
                    )}
                </Col>
            </Row>

        <Row>
            <Col xs="12">
                <Form>
                    {Object.entries(groupedSharps).map(([type, sharps]) => (
                        <React.Fragment key={type}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">{type}</CardTitle>
                                    {sharps.sort((a, b) => a.sortOrder - b.sortOrder).map((sh) => {         
                                        const reportSharp = reportSharps.find(rs => rs.sharpId === sh.id);
                                        const sharpAssignment = sh.isAssigned; 
                                        const isAssignmentDifferent = reportSharp ? reportSharp.isAssigned !== sharpAssignment : false;

                                        return (
                                            <Row key={sh.id} className="mt-3">
                                                <Col xs="12" md="4">
                                                    <label>Operator</label>
                                                    <p>{sh.operator.firstName + ' ' + sh.operator.surname}</p>
                                                </Col>
                                                <Col xs="6" md="4">
                                                    <label>Assigned</label><br/>
                                                    <p>{sh.isAssigned ? "Yes" : "No"}</p>
                                                </Col>
                                                <Col xs="6" md="4">
                                                    <label>Audit: Assigned</label><br/>
                                                    {props.report.status === 'Completed' ? (
                                                        <p style={{color: isAssignmentDifferent ? 'red' : 'black'}}>
                                                            {reportSharp ? reportSharp.isAssigned ? "Yes" : "No" : "No"}
                                                        </p>
                                                    ) : (
                                                        <Input
                                                            type="checkbox"
                                                            id={`auditIsAssigned-${sh.id}`}
                                                            checked={reportSharp ? reportSharp.isAssigned : false}
                                                            onChange={(e) => handleInputChange(sh.id, e.target.checked)}
                                                        />
                                                    )}
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </CardBody>
                            </Card>
                        </React.Fragment>
                    ))}
                    {props.report.status === 'In Progress' && (
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="success" caret>
                                Save
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={handleSubmit}>Save</DropdownItem>
                                <DropdownItem onClick={handleFinish}>Save and Finish</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </Form>
            </Col>
        </Row>
            </>
    );
};
