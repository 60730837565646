import React, { useState, useEffect } from 'react';
import {SharpService} from "../../services/SharpService";
import { EditSharpAuditReportForm } from './EditSharpAuditReportForm';
import { NotificationManager } from '../../helpers/NotificationManager';
import  { useHistory } from "react-router-dom";
import { Screen } from '../App/Screen';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';

export const EditSharpAuditReportFormContainer = (props) => {
    const [report, setReport] = useState({
        name: '',
        reference: '',
        description: '',
        zone: {},
        sharps: [],
        status: '',
        completedOnUtc: null,
        auditor: '',
        isLoaded: false,
    });
    
    const [zoneSharps, setZoneSharps] = useState({
        sharps: [],
        isSharpsLoaded: false,
    });
    
    const [sharpService] = useState(new SharpService());
    const reportId = props.match.params.reportId * 1;
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetching the audit report
                const auditResponse = await sharpService.getAuditReport(reportId);
                const { name, zone, reference, description, sharps, status, completedOnUtc, assessorName } = auditResponse.data;

                // Setting report data
                setReport({
                    name,
                    zone,
                    reference,
                    description,
                    sharps,
                    status,
                    completedOnUtc: completedOnUtc,
                    auditor: assessorName,
                    isLoaded: true,
                });

                // Fetching sharps based on zoneId
                const sharpsResponse = await sharpService.getSharps(1, 1000, 'Type', 'asc', '', null, zone.id);
                const sharpsData = sharpsResponse.data.map(sharpItem => ({
                    ...sharpItem
                }));

                // Updating zoneSharps state and marking sharps as loaded
                setZoneSharps(prevState => ({
                    ...prevState,
                    sharps: sharpsData,
                    isSharpsLoaded: true
                }));

            } catch (error) {
                console.error("Failed to load data:", error);
                NotificationManager.error('Could not obtain results for Sharps', 'Error', 1000);
            }
        };

        fetchData();
    }, [reportId, sharpService]);

    const handleSubmit = (sharps) => {
        const reportInput = {
            id: reportId,
            sharps: sharps.map(sh => ({
                sharpId: sh.sharpId,
                isAssigned: sh.isAssigned,
            }))
        };

        sharpService.saveAuditReport(reportInput).then(
            response => {
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            },
        );
    };

    const handleFinish = (sharps) => {
        const reportInput = {
            id: reportId,
            sharps: sharps.map(sh => ({
                sharpId: sh.sharpId,
                isAssigned: sh.isAssigned,
            }))
        };

        // Save the report
        sharpService.saveAuditReport(reportInput)
            .then(response => {
                // Save successful, attempt to finish the report
                sharpService.finishAuditReport({ id: reportId })
                    .then(finishResponse => {
                        // Finish successful, redirect and show success message
                        history.push('/audit-reports/');
                        NotificationManager.success(finishResponse.data.successMessage, 'Success');
                    })
                    .catch(finishError => {
                        // Finish failed, show error message
                        const finishErrors = finishError.response.data.errors;
                        NotificationManager.error(finishErrors[0].message, 'Error', 1000);
                    });
            })
            .catch(saveError => {
                // Save failed, show error message
                const saveErrors = saveError.response.data.errors;
                NotificationManager.error(saveErrors[0].message, 'Error', 1000);
            });
    };

    const screenMenu = (
        <>
            <NavLink to={"/audit-reports/"} className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Reports
            </NavLink>
        </>
    );

    const isLoaded = zoneSharps.isSharpsLoaded && report.isLoaded;
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Report..."
    };

    return (
        <Screen title={report.reference} menu={screenMenu} loader={screenLoader}>
            <>
                <EditSharpAuditReportForm
                    report={report}
                    zoneSharps={zoneSharps.sharps}
                    handleSubmit={handleSubmit}
                    handleFinish={handleFinish}
                />
            </>
        </Screen>
    );
};