import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../../Modals/Confirm';
import moment from 'moment';

export function ConfirmDeleteNoteModal(props) {

    const user = props.note.user != null ? props.note.user.firstName + ' ' + props.note.user.surname : "";
    
    return (
        <ConfirmModal 
            isOpen={props.isOpen}
            toggle={props.toggle} 
            handleConfirm={(e) => props.handleConfirm(props.note, e)}
            title="Delete Note?"
            confirmText="Yes, Delete Note"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following Note?</strong>
            </Alert>
            <Table bordered>
                <thead>
                    <tr>
                        <th className="col-xs-3">User</th>
                        <th className="col-xs-3">Comments</th>
                        <th className="col-xs-3">Date Created</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{user}</td>
                        <td>{props.note.comments}</td>
                        <td>{moment(props.note.dateCreated).format('DD/MM/YYYY hh:mm A')}</td>
                    </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );

   
}
