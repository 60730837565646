import React from 'react';
import { HashRouter } from "react-router-dom";
import { Router } from './Router';
import ReactNotification from 'react-notifications-component';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css'; 
import '../AuthApp/AuthApp.css';
import Logo from '../../graphics/logo-no-text-transparent.png';

export function AuthApp(props) {
    return (
        <HashRouter>
            <ReactNotification />
            <div className="AuthApp">
                <div className="wrapper">
                    <div className="text-center mb-4">
                        <img src={Logo} alt="QualityWorx" title="QualityWorx" />
                    </div>

                    <div className="mx-auto" style={{ margin: '0 auto', width: '300px' }}>
                        <Router 
                            data={props.data}
                            actions={props.actions}
                        />       
                    </div>

                    <p className="mt-5 mb-3 text-muted text-center">&copy; {props.data.config.copyright}</p>
                    <p className="text-center"><small>Version: {props.data.config.appVersion}</small></p>
                </div>
            </div>
        </HashRouter> 
    );
}