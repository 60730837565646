import React, { useState, useEffect, useReducer, useCallback, useRef } from 'react';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { ClassificationTypeCardList } from '../FormComponents/ClassificationTypeCard/ClassificationTypeCardList';
import { Screen } from '../App/Screen';
import { PrepressNcrsList } from './List';
import { PrepressNcrService } from '../../services/PrepressNcrService';
import { Can } from '../RBAC/Can';
import { prepressNcrsReducer } from '../../reducers/formsReducer';
import { useDispatch } from 'react-redux';
import { refreshNavigation } from '../../reducers/navigationReducer';

export const PrepressNcrListContainer = props => {
    const [state, dispatch] = useReducer(prepressNcrsReducer, { forms: [], paging: null, loading: true, error: null });

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const isMountedVal = useRef(1);
    const [status, setStatus] = useState(0); 
    const [type, setType] = useState(null);
    const [isFilterToggled, setIsFilterToggled] = useState(false);

    const title = "Prepress NCRs";
    const createTitle = "Prepress NCR";
    const formPath = 'prepress-ncrs';

    // Servuces
    const [formService] = useState(new PrepressNcrService());
    const dispatchStore = useDispatch();

    // Mounts / Unmounts
    useEffect(() => {
        isMountedVal.current = 1;
        return () => { isMountedVal.current = 0; };     
    });
   
    const getForms = useCallback((currentPage, itemsPerPage, status) => {
        formService.getForms(currentPage, itemsPerPage, 'DateCreated', 'asc', status).then(
            response => {
                if (isMountedVal.current) {
                    const forms = response.data.results.map(form => {
                        return {
                            ...form
                        };
                    });
                    const paging = response.data.paging;
                    dispatch({ type: "LOADED", forms: forms, paging: paging });
                    dispatchStore(refreshNavigation());
                }
            },
            error => {
                dispatch({ type: "ERROR", error });
            }
        );     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formService]);

    const searchForms = useCallback((searchText, type, status, itemsPerPage) => {
        formService.formSearch(searchText, type, status, 1, itemsPerPage, 'DateCreated', 'asc').then(
            response => {
                if (isMountedVal.current) {
                    const forms = response.data.results.map(form => {
                        return {
                            ...form
                        };
                    });
                    const paging = response.data.paging;
                    dispatch({ type: "LOADED", forms: forms, paging: paging });
                    console.log(forms);
                }               
            },
            error => {
                dispatch({ type: "ERROR", error });
            }
        );
    }, [formService]);

    // Gets forms either on initial render or if the itemsPerPage values changes
    useEffect(() => {
        getForms(1, itemsPerPage, status);  
    }, [getForms, itemsPerPage, status]);
    
    // ========================= ACTIONS ========================= 
   
    const onChangePage = (page) => { getForms(page, state.paging.pageSize, status); }
    const onChangeRowsPerPage = (rowsPerPage) => { setItemsPerPage(rowsPerPage); } 

    const handleSearch = (searchText) => {
        if (searchText && searchText.length > 0) {
            searchForms(searchText, type, status, itemsPerPage);
        }
        else {
            getForms(1, itemsPerPage, status)
        }
    };

    const handleFilter = (newType) => {
        const currentType = newType === type ? null : newType;
        const isToggled = type === newType ? !isFilterToggled : true;        
        setType(currentType)
        setIsFilterToggled(isToggled);
    };

    const handleStatusChange = (status) => { setStatus(status); };

    const handleView = (ncr, e) => {
        e.preventDefault();
        props.history.push('/' + formPath + '/view/'+ ncr.formId);
    };

    const handleEdit = (ncr, e) => {
        e.preventDefault();
        props.history.push('/' + formPath + '/edit/'+ ncr.formId);
    };

    const handleDelete = (form, e) => {
        e.preventDefault();
        formService.delete(form.id).then(
            response => {
                const forms = _.filter(state.forms, x => x.id !== form.id);
                dispatch({ type: "LOADED", forms: forms, paging: state.paging });
                dispatchStore(refreshNavigation());
                NotificationManager.success('The NCR was deleted.', 'NCR Deleted', 1000);
            },
            error => {
                NotificationManager.error('The NCR was not deleted', 'Error', 1000);
            }
        );
    };

    const isLoaded =  !state.loading;
  
    // TO: Refactor this eventually
    var ncrs = state.forms;
    var currentDate = moment(new Date());
    var critical = _.filter(ncrs, x => x.classificationType.name === "Critical").length;
    var major = _.filter(ncrs, x => x.classificationType.name === "Major").length;
    var minor = _.filter(ncrs, x => x.classificationType.name === "Minor").length;
    var overdue = _.filter(ncrs, x => x.status.id !== 4 && x.status.id !== 5 && currentDate >= moment(x.dateDue)).length;

    if (type != null) {
        if (type === "Overdue") {
            ncrs = _.filter(ncrs, x => x.status.id !== 4 && x.status.id !== 5 && currentDate >= moment(x.dateDue));
        }
        else {
            ncrs = _.filter(ncrs, x => x.classificationType.name === type);
        }
    }

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="forms:create"
            yes={() => (
                <>
                    <NavLink to={'/' + formPath + '/create'} className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create {createTitle}
                    </NavLink>
                </>
            )}
        />
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading " + title + "..."
    };

    return (
        <Screen title={title} menu={screenMenu} loader={screenLoader}>

            <ClassificationTypeCardList
                handleFilter={handleFilter}
                isFilterToggled={isFilterToggled}
                critical={critical}
                major={major}
                minor={minor}
                overdue={overdue}
            />

            <br />

            <PrepressNcrsList
                items={ncrs}
                paging={state.paging}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                handleSearch={handleSearch}
                handleStatusChange={handleStatusChange}
                handleView={handleView}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                currentDate={currentDate}
            />
        </Screen>
    );
}
