import React from 'react';
import { NavLink } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Badge, Alert } from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';

export function CompaniesList(props) {
    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/companies/view/' + row.id}>{row.name}</NavLink>
            }
        },
        {
            name: 'Shortcode',
            selector: 'shortcode',
            sortable: true
        },
        {
            name: 'Type',
            selector: 'type.name',
            sortable: true,
            cell: (row) => {
                var badgeColor = "";
                switch (row.type.name) {
                    case "Customer and Supplier":
                        badgeColor = "success";
                        break;
                    case "Customer":
                        badgeColor = "warning";
                        break;
                    case "Supplier":
                        badgeColor = "primary";
                        break;
                    default:
                        badgeColor = "secondary";
                }
                return (
                    <Badge color={badgeColor} pill>{row.type.name}</Badge>
                );
            }
        },
        {
            name: 'Outstanding Forms',
            selector: 'outstandingForms',
            sortable: true
        }
    ];

    const hasAny = props.companies && props.companies.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.companies}
                        defaultSortField="outstandingForms"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No Companies were found</Alert>
            }
        </>
    );
}

