import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { BasicModal } from '../../Modals/Basic';
import { Button } from 'reactstrap';
import { NotificationManager } from '../../../helpers/NotificationManager';
import { SimpleLoader } from '../../Common/SimpleLoader';
import { CreateActionTakenForm } from './CreateActionTakenForm';
import { EditActionTakenForm } from './EditActionTakenForm';
import { Can } from '../../RBAC/Can';

export const ActionTaken = props => {
    const [actionTaken, setActionTaken] = useState(null);
    const [isActionTakenLoaded, setIsActionTakenLoaded] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    // Methods
    const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen);
    const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

    const formService = props.formService;
    const formId = props.formId * 1;

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            fetchActionTaken();
        }, [formService, formId]);
    }

    // Fetch root cause
    const fetchActionTaken = () => {
        formService.getActionTaken(formId).then(
            response => {
                const actionTaken = {
                    ...response.data,
                }

                if (actionTaken.id != null)  { 
                    setActionTaken(actionTaken);
                    setIsActionTakenLoaded(true);
                    props.hasActionTaken(true);
                }
                else { 
                    setActionTaken(null);
                    setIsActionTakenLoaded(true);
                }                
            },
            error => {
                NotificationManager.error('Could not retrieve action taken', 'Error', 1000);
                setActionTaken(null);
                setIsActionTakenLoaded(false);
            }
        )
    };

    // Creates a root cause
    const createActionTaken = (actionTaken) => {
        var model = { ...actionTaken };
        formService.createActionTaken(formId, model).then(
            response => {
                fetchActionTaken();
                props.auditTrailRefresh(true);
                NotificationManager.success('The root cause was created.', 'Created', 1000);
            },
            error => {
                NotificationManager.error('The root cause was not created.', 'Error', 1000);
            }
        );
    };

    // Updates a root cause
    const editActionTaken = (actionTaken) => {
        var model = { ...actionTaken };
        formService.editActionTaken(formId, model).then(
            response => {
                fetchActionTaken();
                props.auditTrailRefresh(true);
                NotificationManager.success('The root cause was updated.', 'Updated', 1000);
            },
            error => {
                NotificationManager.error('The root cause was not updated.', 'Error', 1000);
            }
        );
    };

    const isLoaded = isActionTakenLoaded;
    const hasAny = actionTaken === null ? false : true;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
            {!hasAny ?
                <>
                    <Can
                        perform="actiontaken:create"
                        yes={() => (
                            <>
                                <Button color="success" size="sm" style={{ margin: "0px 5px 0px 5px" }} onClick={() => toggleCreateModal()}>
                                    <Icon.PlusCircle className="feather" /> Create Action Taken
                                </Button>
                                <hr/>

                                <BasicModal
                                    isOpen={isCreateModalOpen}
                                    toggle={toggleCreateModal}
                                    title="Create Action Taken"
                                >
                                    <CreateActionTakenForm
                                        handleSave={createActionTaken}
                                    />
                                </BasicModal>
                            </>
                        )}
                    />
                </> :
                <>
                    <Can
                        perform="actiontaken:edit"
                        yes={() => (
                            <>
                                <Button color="success" size="sm" style={{ margin: "0px 5px 0px 5px" }} onClick={() => toggleEditModal()}>
                                    <Icon.Edit2 className="feather" /> Edit Action Taken
                                </Button>
                                <hr/>

                                <BasicModal
                                    isOpen={isEditModalOpen}
                                    toggle={toggleEditModal}
                                    title="Edit Action Taken"
                                >
                                    <EditActionTakenForm
                                        data={actionTaken}
                                        handleSave={editActionTaken}
                                    />
                                </BasicModal>
                            </>
                        )}
                    />
                    <p>{actionTaken.comments}</p>
                </>
            }
        </SimpleLoader>
    );
}