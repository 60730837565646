import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Keys } from '../../enums';
import { App as MainApp } from './';
import { AuthApp } from '../AuthApp';
import { AuthService } from '../../services/AuthService';
import config from "../../config";
import { NotificationManager } from '../../helpers/NotificationManager';
import { EncodedStorageService } from '../../services/EncodedStorageService';

export class AppContainer extends Component {
    constructor(props) {
        super(props);

        // Define the default state of the state.
        this.state = {
            user: {
                data: null,
                isLoaded: false,
                isLoggedIn: false
            },
            config: {
                data: { ...config },
                isLoaded: true
            }
        };

        this.storage = new EncodedStorageService();

        // Bind the functions
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleSwitchTenant = this.handleSwitchTenant.bind(this);
        this.initAxios = this.initAxios.bind(this);

        // Add the relevant functions to the actions object.
        // This is just a container to keep all the actions together and to make it easy to pass to the App component.
        this.actionHandlers = {
            login: this.handleLogin,
            logout: this.handleLogout,
            switchTenant: this.handleSwitchTenant
        };

        this.AuthService = new AuthService();
    }

    componentDidMount() {
        this.AuthService.getAuthUser().then(
            response => {
                const user = {
                    ...response.data
                };

                // Check local storage and determine if token has expired
                const expiryDate = moment(user.tokenExpires,'YYYY-MM-DD hh:mm:ss');
                const currentDate = moment().format('YYYY-MM-DD hh:mm:ss'); 
                const isTokenValid = expiryDate.isSameOrAfter(currentDate);
    
                if (!isTokenValid) {                
                    this.handleLogout(); 
                }
                else {
                    this.setState({
                        user: {
                            data: user,
                            isLoaded: true,
                            isLoggedIn: true
                        }
                    });
                }
            },
            error => {
                    // Do nothing
            }
        );

        this.initAxios();
    }

    initAxios() {
        // Intercept the HTTP Request and modify it accordingly
        axios.interceptors.request.use(
            config => {
                // Do something before request is sent
                const token = this.storage.get(Keys.AuthToken);
                config.headers = {
                    "Authorization": 'Bearer ' + token
                };

                return config;
            },
            error => {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        // Intercept the HTTP Response and modify it accordingly
        axios.interceptors.response.use(
            response => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                return response;
            },
            error => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger

                // Display alerts for any errors that were returned
                // Doing this here means that the errors will be handled automatically and there will be no need to handle the error response within our app.
                // We can still ofcourse handle the error response if we wish, it just means that these notifications will be triggered beforehand.
                //NotificationManager.handleApiErrorResponse(error.response);

                // Do something with response error
                return Promise.reject(error);
            }
        );
    }

    handleLogin(login) {
        this.AuthService.login(login.username, login.password).then(
            response => {
                const roles = response.data.user.roles;
                const user = {
                    data: {
                        ...response.data.user,
                        role: roles && roles.length > 0 ? roles[0] : '',
                        tokenExpires: response.data.expires
                    },
                    isLoaded: true,
                    isLoggedIn: true
                };

                this.AuthService.setAuthUser(user.data, response.data.token);

                this.setState({
                    user: user
                }, this.initAxios);

                // Refreshes the page when a Tenant has been changed
                window.location.reload(true);                
            },
            (error) => {
                NotificationManager.handleApiErrorResponse(error.response);
            }
        );
    }

    handleLogout() {
        this.AuthService.logout().then(
            response => {
                this.setState({
                    user: {
                        data: null,
                        isLoaded: false,
                        isLoggedIn: false
                    }
                });

                NotificationManager.success('You are now logged out!', 'Success', 1000);
            },
            error => {
                NotificationManager.error('Error occurred attempting to logout', 'Error', 1000);
            }
        );
    }   

    handleSwitchTenant(tenant) {
        this.AuthService.switchTenant(tenant.id).then(
            response => {                
                this.refreshUser() // refresh Current User
            },
            error => {
                NotificationManager.error('Error occurred attempting to switch Tenants', 'Error', 1000);
            }
        );
    }

    refreshUser() {
        this.AuthService.getUserDetails().then(
            response => {
                const prevUser = this.storage.get(Keys.AuthUser);

                const roles = response.data.user.roles;
                const user = {
                    ...response.data.user,
                    role: roles && roles.length > 0 ? roles[0] : '',
                    tokenExpires: prevUser.tokenExpires
                }

                this.AuthService.setAuthUser(user);

                this.setState({
                    user: {
                        data: user,
                        isLoaded: true,
                        isLoggedIn: true
                    }
                });

                // Refreshes the page when a Tenant has been changed
                window.location.reload(true); 
            },
            error => {
                NotificationManager.error('Error occurred attempting to get current User details', 'Error', 1000);
            }
        );
    }

    render() {
        const isLoaded = this.state.user.isLoaded && this.state.config.isLoaded;

        // Create the data object.
        // This will be passed to the App so that it has all of the relevant data.
        const data = {
            user: this.state.user.data,
            config: this.state.config.data
        };

        // Determine whether we should use the MainApp or the AuthApp.
        // We will only use the MainApp if the user is loaded and is logged-in.        
        const App = (isLoaded && this.state.user.isLoggedIn) ? MainApp : AuthApp;

        // NOTE:
        // The MainApp is the actual App.
        // The AuthApp is the app that will be used when there is no authentictaed user.
        // The purpose of the AuthApp is to allow the user to Signup, Login or Reset their password if they have to.

        return (
            <App
                data={data}
                actions={this.actionHandlers}
            />
        );
    }
}