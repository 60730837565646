import React from 'react';
import { Alert } from 'reactstrap';

export class ErrorList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAlertOpen: true
        };

        this.handleDismissAlert = this.handleDismissAlert.bind(this);
    }

    handleDismissAlert() {
        this.setState({ 
            isAlertOpen: false 
        });
    }

    render() {
        const errors = this.props.items;
        const introText = this.props.introText && this.props.introText.length > 0 ? this.props.introText : "Please correct the following errors:";

        if(errors && errors.length > 0) {
            return (
                <Alert color="danger" isOpen={this.state.isAlertOpen} toggle={this.handleDismissAlert}>
                    {
                        introText && introText.length > 0
                        ? (
                            <p><strong>{introText}</strong></p>
                        )
                        : null
                    }
                    
                    <ul>
                        {errors.map((error, index) => {
                            return (
                                <li key={index}>{error}</li>
                            );
                        })}
                    </ul>
                </Alert>
            );
        }
        else 
        {
            return null;
        }
    }
}