import React, { useState, useEffect} from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { BasicModal } from '../Modals/Basic';
import { Screen } from '../App/Screen';
import { CompanyView } from './View';
import { CreateCompanyContactForm } from './CompanyContact/CreateCompanyContactForm';
import { EditCompanyContactForm } from './CompanyContact/EditCompanyContactForm';
import { CompanyService } from '../../services/CompanyService';

export const CompanyViewContainer = props => {
    const [state, setState] = useState({
        isEditCompanyModalOpen: false,
        isCreateCompanyContactModalOpen: false,
        isEditCompanyContactModalOpen: false,
        selectedContact: 0
    });
   
    const [company, setCompany] = useState([]);
    const [companyContacts, setCompanyContacts] = useState([]);    
    const [isCompanyLoaded, setIsCompanyLoaded] = useState(false);


    // Services
    const [companyService] = useState(new CompanyService());

    const companyId = props.match.params.companyId * 1;    

    useEffect(() => {
        // Loads Company
        companyService.getById(companyId).then(
            response => {
                const company = {
                    ...response.data,
                    typeId: response.data.type.id
                }

                setCompany(company);
                setCompanyContacts(company.contacts); 
                setIsCompanyLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain specified companyId', 'Error', 1000);
            }
        );
    }, [companyId, companyService]);

    // Retrieves company
    const getCompany = () => {
        companyService.getById(companyId).then(
            response => {
                const company = {
                    ...response.data,
                    typeId: response.data.type.id
                }

                setCompany(company);
                setCompanyContacts(company.contacts);
                setIsCompanyLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain specified companyId', 'Error', 1000);
            }
        );
    };

    // Search
  
    const handleCompanyContactSearch = (searchText) => {         
        if (searchText && searchText.length > 0) {           
            var query = searchText.toLowerCase();
            var contacts = _.filter(company.contacts, x => x.firstName.toLowerCase().match(query) || x.surname.toLowerCase().match(query)
            || (x.firstName.toLowerCase() + ' ' + x.surname.toLowerCase()).match(query));
            setCompanyContacts(contacts);
        }
        else {
           getCompany(); // Refreshes the state
        }
    };

    const createCompanyContact = (contact) => { 
        var model = {
            ...contact
        };        

        companyService.createCompanyContact(companyId, model).then(
            response => {                
                getCompany(); // Refreshes the state
                NotificationManager.success('The Company Contact was created successfully', 'Company Contact Created', 1000);
            },
            error => {
                NotificationManager.error('The Company Contact was not created', 'Error', 1000);
            }
        );
    };

    const updateCompanyContact = (contact) => { 
        var model = {
            ...contact
        };        

        const companyContactId = contact.id * 1; 

        companyService.editCompanyContact(companyId, companyContactId, model).then(
            response => {                
                getCompany(); // Refreshes the state
                NotificationManager.success('The Company Contact was updated.', 'Company Contact Updated', 1000);
            },
            error => {
                NotificationManager.error('The Company Contact was not updated', 'Error', 1000);
            }
        );
    };

    const deleteCompanyContact = (contact, e) => {
        e.preventDefault();     
        const companyContactId = contact.id * 1; 

        companyService.deleteCompanyContact(companyId, companyContactId).then(
            response => {               
                getCompany(); // Refreshes the state
                NotificationManager.success('The Company Contact was as deleted.', 'Company Contact Deleted', 1000);
            },
            error => {
                NotificationManager.error('Company Contact was not deleted', 'Error', 1000);
            }
        );
    };

    //  ===============================================================================================================

    const toggleEditCompanyModal = () => {
        setState((prevState) => ({
            ...prevState,
            isEditCompanyModalOpen: !prevState.isEditCompanyModalOpen
        }));
    };
 
    const toggleCreateCompanyContactModal = () => {
        setState((prevState) => ({
            ...prevState,
            isCreateCompanyContactModalOpen: !prevState.isCreateCompanyContactModalOpen
        }));
    };

    const toggleEditCompanyContactModal = (entity, e) => {
        setState((prevState) => ({
            ...prevState,
            selectedContact: entity.id > 0 ? entity : 0,
            isEditCompanyContactModalOpen: !prevState.isEditCompanyContactModalOpen
        }));  
    };

    //  ===============================================================================================================    

    const isLoaded = isCompanyLoaded;
    const title = company.name === null ? "" : company.name;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <>
            <NavLink to="/companies" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Companies List
            </NavLink>
        </>
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading View Company Form..."
    };

    return (
        <Screen title={title} menu={screenMenu} loader={screenLoader}>
            <CompanyView
                company={company}
                companyContacts={companyContacts}
                handleCompanyContactSearch={handleCompanyContactSearch}
                toggleEditCompanyModal={toggleEditCompanyModal}
                toggleCreateCompanyContactModal={toggleCreateCompanyContactModal}
                toggleEditCompanyContactModal={toggleEditCompanyContactModal}
                handleDelete={deleteCompanyContact}
            />

            {/* Create Company Contact */}
            <BasicModal
                isOpen={state.isCreateCompanyContactModalOpen}
                toggle={toggleCreateCompanyContactModal}
                title="Create Company Contact"
            >
                <CreateCompanyContactForm
                    handleSave={createCompanyContact}
                    
                />
            </BasicModal>

            {/* Edit Company Contact */}
            <BasicModal
                isOpen={state.isEditCompanyContactModalOpen}
                toggle={toggleEditCompanyContactModal}
                title="Edit Company Contact"
            >
                <EditCompanyContactForm
                    data={state.selectedContact} 
                    handleSave={updateCompanyContact}
                    
                />
            </BasicModal>
        </Screen>
    );
}