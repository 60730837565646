import React from 'react';
import { NavLink } from 'react-router-dom';
import { StickyFooter } from './StickyFooter';

export function Footer(props) {
    const currentYear = new Date().getFullYear();
    return (
        <StickyFooter>
            <div className="container-fluid px-0">
                <div className="row no-gutters">
                    <div className="col">
                        <div>Version {props.version} |
                        <NavLink to="/release-notes">
                            &nbsp;Release Notes
                        </NavLink>
                        </div>                  
                    </div>

                    <div className="col">
                        <div className="text-right">&copy; {props.copyright} 2019 - {currentYear}</div>
                    </div>
                </div>
            </div>
        </StickyFooter>
    );
}
