import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../Modals/Confirm';

export function ConfirmDeleteZoneModal(props) {
    return (
        <ConfirmModal 
            isOpen={props.isOpen}
            toggle={props.toggle} 
            handleConfirm={(e) => props.handleConfirm(props.zone, e)}
            title="Delete Zone?"
            confirmText="Yes, Delete Zone"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following Zone?</strong>
            </Alert>

            <Table bordered>
                <thead>
                    <tr>
                        <th className="col-xs-12">Name</th>                      
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.zone.name}</td>
                    </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );
}