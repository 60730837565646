import React from 'react';
import { CheckboxField, DatePickerField, DropdownField, MultipleTenantCheckboxField, MultipleContactsCheckboxField, MultipleCheckboxField, NumberField, TextField, TextAreaField, PasswordField, MultipleFileUploader, MultipleRolesCheckboxField } from '.';

export function FieldEditor({ field, actions }) {
    let editorId = '';

    if (typeof field.editor === 'string') {
        editorId = field.editor;
    }
    else if (typeof field.editor === 'object') {
        editorId = field.editor.name;
    }

    switch (editorId.toLowerCase()) {
        case "datepicker":
            return <DatePickerField field={field} actions={actions} options={field.editor.options} />;

        case "dropdown":
            return <DropdownField field={field} actions={actions} options={field.editor.options} />;

        case "textarea":
            return <TextAreaField field={field} actions={actions} />;

        case "multitentantcheckbox":
            return <MultipleTenantCheckboxField field={field} actions={actions} options={field.editor.options} defaultValue={field.editor.defaultValue} />;

        case "multicheckbox":
            return <MultipleCheckboxField field={field} actions={actions} options={field.editor.options} />;

        case "multicontactcheckbox":
            return <MultipleContactsCheckboxField field={field} actions={actions} options={field.editor.options} />;

        case "checkbox":
            return <CheckboxField field={field} actions={actions} />;

        case "number":
            return <NumberField field={field} actions={actions} maximum={field.editor.maximum} minimum={field.editor.minimum} step={field.editor.step} />

        case "password":
            return <PasswordField field={field} actions={actions} />;

        case "fileuploader":
            return <MultipleFileUploader field={field} actions={actions} options={field.editor.options} />

        case "multirolecheckbox":
            return <MultipleRolesCheckboxField field={field} actions={actions} options={field.editor.options} />;
                
        default:
            return <TextField field={field} actions={actions} />;
    }
}