import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { DiscrepanciesList } from './DiscrepanciesList';
import { Can } from '../RBAC/Can';
import { DiscrepancyService } from '../../services/DiscrepancyService';
import {EntityService} from "../../services/EntityService";

export const DiscrepanciesListContainer = props =>  {
    const [state, setState] = useState({
        discrepancies: [],
        isDiscrepanciesLoaded: false,
        isSearchingActive: false
    });

    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]); 

    // Discrepancy Service
    const [discrepancyService] = useState(new DiscrepancyService());
    const [classificationTypeService] = useState(new EntityService('ClassificationTypes'));


    const handleOptionsChange = selectedOptions => { 
        if (Array.isArray(selectedOptions)) {
            setSelectedOptions(selectedOptions); 
        } else {
            console.error('not an array:', selectedOptions);
        }
    };


    // Load categories
    useEffect(() => {

        classificationTypeService.getList(1, 1000, 'Name', 'asc').then(response => {
            const categoryOptions = response.data.results.map(category => ({
                value: category.id,
                label: category.name,
                type: 'category'
            }));
    
            const statusOptions = [
                { value: 'In Progress', label: 'In Progress', type: 'status' },
                { value: 'Completed', label: 'Completed', type: 'status' },
            ];
    
            setOptions([...categoryOptions, ...statusOptions]);
            setSelectedOptions([statusOptions[0]]);          
        });
    }, [classificationTypeService]);


    // Load initial data
    useEffect(() => {      
        const handleResponse = (response) => {
            const discrepancies = response.data.map(discrepancies => {
                return {
                    ...discrepancies
                };
            });
            setState(prevState => {
                return {
                    ...prevState,
                    discrepancies: discrepancies,
                    isDiscrepanciesLoaded: true,
                    isSearchingActive: false
                };
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Discrepancies', 'Error', 1000);
        };
        
         // Separate the selected options into categories and statuses
         const selectedCategories = selectedOptions.filter(option => option.type === 'category');
         const selectedStatuses = selectedOptions.filter(option => option.type === 'status');
 
         // Map the selected categories and statuses to their values and join them with commas
         const categoryIds = selectedCategories.map(category => category.value).join(',');
         const statuses = selectedStatuses.map(status => status.value).join(',');

        discrepancyService.getDiscrepancies(1, 1000, 'Id', 'asc', null, categoryIds, statuses).then(handleResponse, handleError); 

    }, [discrepancyService, selectedOptions]);

    const handleSearch = (searchText) => {
       
        setState({ ...state, isSearchingActive: true });
        const handleResponse = (response) => {
            const discrepancies = response.data.map(discrepancies => {
                return {
                    ...discrepancies
                };
            });
            setState({ 
                ...state,
                discrepancies: discrepancies,
                isDiscrepanciesLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Discrepancies', 'Error', 1000);
        };

        // Separate the selected options into categories and statuses
        const selectedCategories = selectedOptions.filter(option => option.type === 'category');
        const selectedStatuses = selectedOptions.filter(option => option.type === 'status');

        // Map the selected categories and statuses to their values and join them with commas
        const categoryIds = selectedCategories.map(category => category.value).join(',');
        const statuses = selectedStatuses.map(status => status.value).join(',');

        if (searchText && searchText.length > 0) {
            discrepancyService.getDiscrepancies(1, 1000, 'Id', 'asc', searchText, categoryIds, statuses).then(handleResponse, handleError); 
        }
        else {
            discrepancyService.getDiscrepancies(1, 1000, 'Id', 'asc', null, categoryIds, statuses).then(handleResponse, handleError); 
        }
    };

    const handleEdit = (discrepancy, e) => {
        e.preventDefault();
        props.history.push('/discrepancies/edit/' + discrepancy.id);
    };

    const handleDelete = (discrepancy, e) => {
        e.preventDefault();

        discrepancyService.deleteDiscrepancy(discrepancy.id).then(
            response => {  
                const discrepancies = _.filter(state.discrepancies, x => x.id !== discrepancy.id);
                setState({ ...state, discrepancies: discrepancies });
                NotificationManager.success(response.data.successMessage, 'Deleted', 1000);
            },
            error => {
                const errors = error.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            }
        );
    };

    const isLoaded = state.isDiscrepanciesLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="discrepancies:add"
            yes={() => (
                <>
                    <NavLink to="/discrepancies/add" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Add Discrepancy
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading..."
    };

    return (
        <Screen title="Discrepancies" menu={screenMenu} loader={screenLoader}>
            <DiscrepanciesList
                items={state.discrepancies}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleOptionsChange={handleOptionsChange}
                options={options}
                selectedOptions={selectedOptions}
            />
        </Screen>
    );
}