import React, { useState } from "react";
import { Button, Label } from "reactstrap";
import * as Icon from 'react-feather';

export const MultipleFileUploader = ({ field, actions, options }) => {
  const [selectedFiles, setSelectedFiles] = useState(field.value ? field.value : []);


  const abbreviateDisplayName = (displayName, maxLength) => {
    if (displayName && displayName.length > maxLength) {
      const extension = displayName.split('.').pop(); // Get the file extension
      const abbreviatedName = displayName.slice(0, maxLength - extension.length - 3) + '...' + extension;
      return abbreviatedName;
    }
    return displayName;
  };

  const handleFileChange = (e) => {
    const newSelectedFiles = e.target.files;
    const limitedFiles = [];
    const maxSize = 2 * 1024 * 1024; // 2MB
    const maxItems = 10;
  
    // Check the number of selected files
    if (newSelectedFiles.length + selectedFiles.length > maxItems) {
      alert(`You can only select up to ${maxItems} files.`);
      e.target.value = null; // Clear the input
      return;
    }
  
    const filePromises = [];
  
    for (let i = 0; i < newSelectedFiles.length; i++) {
      const file = newSelectedFiles[i];
  
      // Check for duplicate filenames
      if (selectedFiles.some(existingFile => existingFile.fileName === file.name)) {
        alert(`File "${file.name}" is already selected.`);
      } else if (file.size > maxSize) {
        alert(`File ${file.name} exceeds the 2MB size limit.`);
      } else {
        limitedFiles.push(file);
  
        const reader = new FileReader();
  
        filePromises.push(
          new Promise((resolve, reject) => {
            reader.onload = (e) => {
              const encodedData = e.target.result;
              const base64 = encodedData.split(',')[1];
  
              resolve({ id: null, fileName: file.name, base64String: base64 });
            };
  
            reader.onerror = (error) => {
              reject(error);
            };
  
            reader.readAsDataURL(file);
          })
        );
      }
    }
  
    Promise.all(filePromises)
      .then((attachments) => {
        setSelectedFiles((prevSelectedFiles) => [...prevSelectedFiles, ...attachments]);
        field.value = [...selectedFiles, ...attachments];
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleRemoveFile = (id) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(updatedFiles);
    field.value = updatedFiles;
  };

  return (
    <>
     <Label for={field.name}>{field.label}&nbsp;&nbsp;&nbsp;<small>{actions.getErrorMessage(field)}</small></Label>          
      <div className="input-group">
        <div className="custom-file">
          <input type="file" className="custom-file-input" id="file" multiple onChange={handleFileChange} accept=".jpg, .jpeg, .png"/>
          <label className="custom-file-label" htmlFor="file">Add attachments...</label>
        </div>
      </div>
      <br />
      <div>
        
          {selectedFiles && selectedFiles.map((file, index) => (
            <div key={index}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: 'white', padding: '10px', marginBottom: '3px', border: '1px solid #ccc' }}>
              <small>{abbreviateDisplayName(file.fileName, 50)}
              </small>
              <Button className="delete-button" color="danger" size="xs" onClick={() => handleRemoveFile(file.id)} style={{margin: '0px 2px', padding: '0px 5px'}}>
                <Icon.XCircle className="feather" />
              </Button>
            </div>
          </div>          
          ))}
      </div>
    </>
  );
};

export default MultipleFileUploader;
