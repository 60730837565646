import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { ZonesList } from './ZonesList';
import { Can } from '../RBAC/Can';
import { ZoneService } from '../../services/ZoneService';

export const ZonesListContainer = props =>  {
    const [state, setState] = useState({
        zones: [],
        isZonesLoaded: false,
        isSearchingActive: false
    });

    // Zone Service
    const [zoneService] = useState(new ZoneService());

    // Load initial data
    useEffect(() => {
        const handleResponse = (response) => {
            const zones = response.data.map(zones => {
                return {
                    ...zones
                };
            });
            setState(prevState => {
                return {
                    ...prevState,
                    zones: zones,
                    isZonesLoaded: true,
                    isSearchingActive: false
                };
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Zones', 'Error', 1000);
        };

        zoneService.getZones(1, 1000, 'Name', 'asc').then(handleResponse, handleError); // Use getZones method

    }, [zoneService]);

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });
        const handleResponse = (response) => {
            const zones = response.data.map(zones => {
                return {
                    ...zones
                };
            });
            setState({ 
                ...state,
                zones: zones,
                isZonesLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Zones', 'Error', 1000);
        };

        if (searchText && searchText.length > 0) {
            zoneService.getZones(1, 1000, 'Name', 'asc', searchText).then(handleResponse, handleError); // Use getReports method
        }
        else {
            zoneService.getZones(1, 1000, 'Name', 'asc').then(handleResponse, handleError); // Use getZones method
        }
    };

    const handleEdit = (zone, e) => {
        e.preventDefault();
        props.history.push('/zones/edit/' + zone.id);
    };

    const handleDelete = (zone, e) => {
        e.preventDefault();

        zoneService.deleteZone(zone.id).then(
            response => {  
                const zones = _.filter(state.zones, x => x.id !== zone.id);
                setState({ ...state, zones: zones });
                NotificationManager.success(response.data.successMessage, 'Deleted', 1000);
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            }
        );
    };

    const isLoaded = state.isZonesLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="zones:add"
            yes={() => (
                <>
                    <NavLink to="/zones/add" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Add Zone
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading..."
    };

    return (
        <Screen title="Zones" menu={screenMenu} loader={screenLoader}>
            <ZonesList
                items={state.zones}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </Screen>
    );
}