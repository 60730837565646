import React from 'react';
import { BaseForm } from '../FormBuilder';
import { Validators } from '../FormBuilder';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';

export class ChangePasswordForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".      
        const formDefinition = {
            fields: [
                {
                    name: "password",
                    label: "New Password",
                    editor: "password",
                    validators: [
                        Validators.required(),
                        Validators.passwordFormat()
                    ]
                },
                {
                    name: "passwordConfirm",
                    label: "Confirm New Password",
                    editor: "password",
                    validators: [
                        Validators.required(),
                        Validators.passwordFormat()
                    ]
                }
            ]   
        };      
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);      
    }

    getModel(fields) {
        // This function will override the logic to generate the model that is returned after submitting the form.
        // The generated model will be passed to the relevant handleSave() event, where it can be used to process the data accordingly.

        // Get the model that is generated by the BaseForm.
        // This gets the value for each field defined within the form and returns it as an object.
        // We can use this as the base model to work from and modify it accordingly before we return it.    
        const model = {
            id: this.props.user.id, // Get the ID that's been passed through props
            ...this._getModel(fields)           
        };

        return model;
    }

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {       
        return (
            <Row form>
                <Col xs="12" md="12">
                <p>Please enter the new password below, using at least:</p>
                <ul>
                    <li>one lowercase ('a'-'z')</li>
                    <li>one uppercase ('A'-'Z')</li>
                    <li>one digit ('0'-'9')</li>
                    <li>6+ characters</li>
                </ul>   
                    <FieldEditor field={fields.password} actions={this.fieldActions} />
                    <FieldEditor field={fields.passwordConfirm} actions={this.fieldActions} />
                </Col>
            </Row>
        );
    }
}