import React, { useState } from 'react';
import { Input, FormGroup } from 'reactstrap';

export const FormStatusDropdownList = props => {

    const [status, setStatus] = useState(0);

    const handleChange = (event) => {    
        const statusId = event.target.value * 1;  
        setStatus(statusId);
        props.handleStatusChange(statusId);
    }

    return (
        <FormGroup row style={{"marginBottom":"10px"}}>         
                <Input type="select" name="select" id="formStatusSelect" onChange={e => handleChange(e)} value={status}>
                    <option value="0">Unresolved</option>
                    <option value="1">Resolved</option>
                </Input>
        </FormGroup>
    );
}


