import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { EditSharpForm } from './EditSharpForm';
import { SharpService } from '../../services/SharpService';
import { ZoneService } from "../../services";
import { EntityService } from '../../services/EntityService';

export const EditSharpContainer = (props) => {
    const [sharp, setSharp] = useState({});
    const [isSharpLoaded, setIsSharpLoaded] = useState(false);
    const sharpForm = useRef();
   
    const history = useHistory();
    const sharpId = props.match.params.sharpId * 1;

    // Services
    const [sharpService] = useState(new SharpService());
    const [zoneService] = useState(new ZoneService());
    const [operatorService] = useState(new EntityService('Operators'));

    const [zones, setZones] = useState([]);
    const [operators, setOperators] = useState([]);
    const [types, setTypes] = useState([]);
    
    useEffect(() => {
        sharpService.getSharp(sharpId).then(
            response => {
                const sharp = {
                    ...response.data,
                    zoneId: response.data.zone.id,
                    operatorId: response.data.operator.id,
                    type: response.data.type.id
                }
                setSharp(sharp);
                setIsSharpLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain specified sharp', 'Error', 1000);
            }
        );

        // Zones
        zoneService.getZones(1, 1000, 'Name', 'asc').then(
            response => {
                const zones = response.data.map(zone => ({ ...zone }));
                setZones(zones);
            },
            () => {
                NotificationManager.error('Could not obtain results for Zones', 'Error', 1000);
            }
        );

        // Operators
        operatorService.getList(1, 1000, 'FirstName', 'asc').then(
            response => {
                const operators = response.data.results.map(operator =>  ({
                    id: operator.id,
                    name: operator.firstName + ' ' + operator.surname
                }));
                setOperators(operators);
            },
            () => {
                NotificationManager.error('Could not obtain results for Operators', 'Error', 1000);
            }
        );

        // Types
        sharpService.getTypes().then(
            response => {
                const types = response.data.map(type => ({ ...type }));
                setTypes(types);
            },
            () => {
                NotificationManager.error('Could not obtain results for Types', 'Error', 1000);
            });
        
    }, [sharpId, sharpService]);

    const editSharp = (sharp) => {
        var model = {
            id: sharpId,
            isAssigned: sharp.isAssigned,
        };

        sharpService.updateSharpQuantity(model).then(
            response => {
                history.push('/sharps/edit/' + model.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            }
        );
    }

    const screenMenu = (
        <>
            <NavLink to="/sharps" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Sharps List
           </NavLink>
        </>
    );
    
    const isLoaded = isSharpLoaded && zones.length > 0 && operators.length > 0;

    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Edit Sharp Form..."
    };

    return (
        <Screen title="Edit Sharp" menu={screenMenu} loader={screenLoader}>
            <EditSharpForm
                ref={sharpForm}
                data={sharp}
                handleSave={editSharp}
                zones={zones}
                operators={operators}
                types={types}
                readonly={true}
            />
        </Screen>
    );
}