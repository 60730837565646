import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import _ from 'lodash';
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { Can } from '../RBAC/Can';
import { AuditReportsList } from './AuditReportsList';
import { ZoneService, AuditReportService } from '../../services';
import { SharpService } from "../../services/SharpService";

export const AuditReportsListContainer = (props) => {
    const [reportState, setReportState] = useState({
        reports: [],
        isReportsLoaded: false,
        isSearchingActive: false
    });

    const [zoneService] = useState(new ZoneService());
    const [auditReportService] = useState(new AuditReportService());
    const [sharpService] = useState(new SharpService());

    // Filters
    const [status, setStatus] = useState(null);
    const [type, setType] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [zoneId, setZoneId] = useState(props.match.params.zoneId === undefined ? null : props.match.params.zoneId * 1);

    // Dropdowns
    const [zonesState, setZonesState] = useState({
        zones: [],
        isZonesLoaded: false,
    });

    useEffect(() => {
        // Clear reports before fetching new data
        setReportState((prevState) => ({ ...prevState, reports: [], isSearchingActive: true }));

        // Fetch data from both services
        Promise.all([
            auditReportService.getAuditReports(1, 1000, 'OpenOnUtc', 'asc', null, status, zoneId, startDate, endDate),
            sharpService.getAuditReports(1, 1000, 'OpenOnUtc', 'asc', null, status, zoneId, startDate, endDate)
        ])
            .then(([auditResponse, sharpResponse]) => {
                const auditReports = auditResponse.data.map((report) => ({ ...report, type: 'Audit' }));
                const sharpReports = sharpResponse.data.map((report) => ({ ...report, type: 'Sharps' }));

                const filteredAuditReports =  _.uniqBy(auditReports, 'id');
                const filteredSharpReports = _.uniqBy(sharpReports, 'id');
                const combinedReports = _.uniqBy([...filteredAuditReports, ...filteredSharpReports], 'reference');
                
                switch (type) {
                    case 'Audit':
                        setReportState({
                            reports: filteredAuditReports,
                            isReportsLoaded: true,
                            isSearchingActive: false
                        });
                        break;
                    case 'Sharps':
                        setReportState({
                            reports: filteredSharpReports,
                            isReportsLoaded: true,
                            isSearchingActive: false
                        });
                        break;
                    default:
                        setReportState({
                            reports: combinedReports,
                            isReportsLoaded: true,
                            isSearchingActive: false
                        });
                        break;
                }
            })
            .catch(() => {
                NotificationManager.error('Could not obtain results for Reports', 'Error', 1000);
                setReportState((prevState) => ({
                    ...prevState,
                    isReportsLoaded: false,
                    isSearchingActive: false
                }));
            });

        // Get zones
        zoneService.getZones(1, 1000, 'Name', 'asc').then(
            (response) => {
                const zones = response.data.map(zones => {
                    return {
                        ...zones
                    };
                });
                setZonesState(prevState => {
                    return {
                        ...prevState,
                        zones: zones,
                        isZonesLoaded: true,
                    };
                });
            },
            () => {
                NotificationManager.error('Could not obtain results for Zones', 'Error', 1000);
            }
        );
    }, [zoneService, auditReportService, sharpService, status, zoneId, type, startDate, endDate]);

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleZoneChange = (event) => {
        setZoneId(event.target.value);
    };

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    }

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    }

    const handleSearch = (searchText) => {
        setReportState((prevState) => ({ ...prevState, reports: [], isSearchingActive: true }));

        const query = searchText && searchText.length > 0 ? searchText : null;

        // Fetch data from both services
        Promise.all([
            auditReportService.getAuditReports(1, 1000, 'OpenOnUtc', 'asc', query, status, zoneId, startDate, endDate),
            sharpService.getAuditReports(1, 1000, 'OpenOnUtc', 'asc', query, status, zoneId, startDate, endDate)
        ])
            .then(([auditResponse, sharpResponse]) => {
                const auditReports = auditResponse.data.map((report) => ({ ...report, type: 'Audit' }));
                const sharpReports = sharpResponse.data.map((report) => ({ ...report, type: 'Sharps' }));

                const filteredAuditReports =  _.uniqBy(auditReports, 'id');
                const filteredSharpReports = _.uniqBy(sharpReports, 'id');
                const combinedReports = _.uniqBy([...filteredAuditReports, ...filteredSharpReports], 'reference');

                switch (type) {
                    case 'Audit':
                        setReportState({
                            reports: filteredAuditReports,
                            isReportsLoaded: true,
                            isSearchingActive: false
                        });
                        break;
                    case 'Sharps':
                        setReportState({
                            reports: filteredSharpReports,
                            isReportsLoaded: true,
                            isSearchingActive: false
                        });
                        break;
                    default:
                        setReportState({
                            reports: combinedReports,
                            isReportsLoaded: true,
                            isSearchingActive: false
                        });
                        break;
                }
            })
            .catch(() => {
                NotificationManager.error('Could not obtain results for Reports', 'Error', 1000);
                setReportState((prevState) => ({
                    ...prevState,
                    isReportsLoaded: false,
                    isSearchingActive: false
                }));
            });
    };

    const handleEdit = (report, e) => {
        e.preventDefault();

        if (report.type === 'Audit') {
            props.history.push('/zones/' + zoneId + '/reports/edit/' + report.id);
        }
    };

    const handleStart = (report) => {
        const model = {
            id: report.id * 1
        };

        if (report.type === 'Audit') {
            auditReportService.startAuditReport(model).then(
                response => {
                    props.history.push('/audit-reports/' + model.id);
                    NotificationManager.success(response.data.successMessage, 'Success');
                },
                error => {
                    const errors = error.data.errors;
                    NotificationManager.error(errors[0].message, 'Error', 1000);
                }
            );
        }

        if (report.type === 'Sharps') {
            sharpService.startAuditReport(model).then(
                response => {
                    props.history.push('/audit-reports/sharps/' + model.id);
                    NotificationManager.success(response.data.successMessage, 'Success');
                },
                error => {
                    const errors = error.data.errors;
                    NotificationManager.error(errors[0].message, 'Error', 1000);
                }
            );
        }
    };

    const handleDelete = (report, e) => {
        e.preventDefault();

        if (report.type === 'Audit') {
            auditReportService.deleteAuditReport(report.id).then(
                (response) => {
                    const reports = _.filter(reportState.reports, (x) => x.id !== report.id);
                    setReportState({ ...reportState, reports: reports });
                    NotificationManager.success(response.data.successMessage, 'Deleted', 1000);
                },
                (error) => {
                    const errors = error.data.errors;
                    NotificationManager.error(errors[0].message, 'Error', 1000);
                }
            );
        }

        if (report.type === 'Sharps') {
            sharpService.deleteAuditReport(report.id).then(
                (response) => {
                    const reports = _.filter(reportState.reports, (x) => x.id !== report.id);
                    setReportState({ ...reportState, reports: reports });
                    NotificationManager.success(response.data.successMessage, 'Deleted', 1000);
                },
                (error) => {
                    const errors = error.data.errors;
                    NotificationManager.error(errors[0].message, 'Error', 1000);
                }
            );
        }
    };

    const isLoaded = reportState.isReportsLoaded && zonesState.isZonesLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="reports:schedule"
            yes={() => (
                <>
                    <NavLink to="audit-reports/schedule" className="btn btn-sm btn-info" style={{ marginRight: '5px' }}>
                        <Icon.Clock className="feather" />&nbsp;&nbsp;Schedule Reports
                    </NavLink>
                </>
            )}
        />
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading..."
    };

    return (
        <Screen title="Audit Reports" menu={screenMenu} loader={screenLoader}>
            <AuditReportsList
                items={_.uniqBy(reportState.reports, 'reference')}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleStart={handleStart}
                handleDelete={handleDelete}
                status={status}
                type={type}
                zones={zonesState.zones}
                startDate={startDate}
                endDate={endDate}
                handleStatusChange={handleStatusChange}
                handleTypeChange={handleTypeChange}
                handleZoneChange={handleZoneChange}
                handleStartDateChange={handleStartDateChange}
                handleEndDateChange={handleEndDateChange}
            />
        </Screen>
    );
}