import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { OperatorsList } from './List';
import { EntityService } from '../../services/EntityService';
import { Can } from '../RBAC/Can';

export const OperatorsListContainer = props =>  {
    const [state, setState] = useState({
        operators: [],
        isOperatorsLoaded: false,
        isSearchingActive: false
    });  

    // Operator Service
    const [operatorService] = useState(new EntityService('Operators'));

    // Load initial data
    useEffect(() => {            
        const handleResponse = (response) => {
            const operators = response.data.results.map(operator => {
                return {
                    ...operator
                };
            });
            setState(prevState => { 
                return {
                    ...prevState,
                    operators: operators,
                    isOperatorsLoaded: true,
                    isSearchingActive: false
                };
            });      
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Operators', 'Error', 1000);
        };
        
        operatorService.getList(1, 1000, 'FirstName', 'asc').then(handleResponse, handleError);

    }, [operatorService]);

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });

        const handleResponse = (response) => {
            const operators = response.data.results.map(operator => {
                return {
                    ...operator
                };
            });
            setState({ 
                ...state,
                operators: operators,
                isOperatorsLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Operators', 'Error', 1000);
        };

        if (searchText && searchText.length > 0) {
            operatorService.search(searchText, 1, 1000, 'FirstName', 'asc').then(handleResponse, handleError);
        }
        else {
            operatorService.getList(1, 1000, 'FirstName', 'asc').then(handleResponse, handleError);
        }
    };

    const handleEdit = (operator, e) => {
        e.preventDefault();
        props.history.push('/operators/edit/' + operator.id);
    };

    const handleDelete = (operator, e) => {
        e.preventDefault();

        operatorService.delete(operator.id).then(
            response => { 
                const operators = _.filter(state.operators, x => x.id !== operator.id);
                setState({ ...state, operators: operators });
                NotificationManager.success('The operator ' + operator.firstName + ' ' + operator.surname + ' was deleted.', 'Operator Deleted', 1000);
            },
            error => {
                NotificationManager.error('Operator was not deleted', 'Error', 1000);
            }
        );
    };

    const isLoaded = state.isOperatorsLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="operators:create"
            yes={() => (
                <>
                    <NavLink to="/operators/create" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create Operator
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Operators..."
    };

    return (
        <Screen title="Operators" menu={screenMenu} loader={screenLoader}>
            <OperatorsList
                items={state.operators}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </Screen>
    );
}
