import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { SupplierNcrCreateForm } from './CreateForm';
import { SupplierNcrService } from '../../services/SupplierNcrService';
import { EntityService } from '../../services/EntityService';
import { CompanyService } from '../../services/CompanyService';
import { LookupService } from '../../services/LookupService';

export const CreateSupplierNcrContainer = props => {    
    const [classificationTypes, setClassificationTypes] = useState([]);
    const [isClassificationTypesLoaded, setIsClassificationTypesLoaded] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [isSuppliersLoaded, setIsSuppliersLoaded] = useState(false);
    const [defectTypes, setDefectTypes] = useState([]);
    const [isDefectTypesLoaded, setIsDefectTypesLoaded] = useState(false);
    const [supplierId, setSupplierId] = useState(0);
    const [contacts, setContacts] = useState([]);
    const [isContactsLoaded, setIsContactsLoaded] = useState(false);

    // Services
    const [supplierNcrService] = useState(new SupplierNcrService());

    // Init.
    useEffect(() => {      
        const supplierNcrService = new SupplierNcrService();
        const classificationTypeService = new EntityService('ClassificationTypes');
        const companyService = new CompanyService();  
        const lookupService = new LookupService();  

        // Loads classification types  
        classificationTypeService.getList(1, 1000, "Name", "desc").then(
            response => {
                const classificationTypes = response.data.results.map(classificationType => {
                    return {
                        id: classificationType.id,
                        name: classificationType.name
                    };
                });

                setClassificationTypes(classificationTypes);
                setIsClassificationTypesLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain classification types', 'Error', 1000);
            }
        );

        // Loads suppliers
        companyService.getSuppliersList(1, 1000, "Name", "asc").then(
            response => {
                const suppliers = response.data.results.map(supplier => {
                    return {
                        id: supplier.id,
                        name: supplier.name
                    };
                });
                setSuppliers(suppliers);
                setIsSuppliersLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain suppliers', 'Error', 1000);
            }
        );

        // Loads defect types
        lookupService.getByType("DefectType").then(
            response => {
                const defectTypes = response.data.results.map(defectType => {
                    return {
                        id: defectType.id,
                        name: defectType.name
                    };
                });

                setDefectTypes(defectTypes);
                setIsDefectTypesLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain defect types', 'Error', 1000);
            }
        );

        supplierNcrService.getDefaultContacts(0).then(
            response => {
                const contacts = response.data.results.map((contact, index) => {
                    return {
                        id: index,
                        ...contact
                    };
                });

                setContacts(contacts);
                setIsContactsLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain contacts', 'Error', 1000);
            }
        );
    }, []);

    // Create a supplier ncr
    const createSupplierNcr = (supplierNcr) => {
        var model = {
            ...supplierNcr
        };
 
        supplierNcrService.create(model).then(
            response => {
                const supplierNcr = response.data;
                props.history.push('/supplier-ncrs/view/' + supplierNcr.logNo);
                NotificationManager.success( supplierNcr.ncrLogNo  + ' was created.', 'Created');
            },
            response => {
                NotificationManager.error('The NCR was not created.', 'Error', 1000);
            },
        );
    };

    const handleSupplierChange = (id) => {
        if (supplierId !== id) {
            setSupplierId(id);
            // Fetch all default contacts (based on Company)
            supplierNcrService.getDefaultContacts(id).then(
                response => {
                    const contacts = response.data.results.map((contact, index) => {
                        return {
                            id: index,
                            ...contact
                        };
                    });

                    setContacts(contacts);
                    setIsContactsLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain contacts', 'Error', 1000);
                }
            );
        };       
    }


    const isLoaded = isClassificationTypesLoaded & isSuppliersLoaded & isDefectTypesLoaded & isContactsLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <>
            <NavLink to="/supplier-ncrs" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Supplier NCRs List
               </NavLink>
        </>
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Create Supplier NCR Form..."
    };

    return (
        <Screen title="Create Supplier NCR" menu={screenMenu} loader={screenLoader}>
            <SupplierNcrCreateForm
                handleSave={createSupplierNcr}
                
                classificationTypeOptions={classificationTypes}
                supplierOptions={suppliers}
                defectTypeOptions={defectTypes}
                contactsOptions={contacts}
                handleSupplierChange={handleSupplierChange}
            />
        </Screen>
    );
}