// src/constants/route.js

const DASHBOARD = '/';
const TENANTS = '/organisations';
const USERS = '/users';
const ROLES = '/roles';
const COMPANIES = '/companies';
const CLASSIFICATION_TYPES = '/classification-types';
const OPERATORS = '/operators';
const CUSTOMER_COMPLAINTS = '/customer-complaints';
const CONVERTING_NCRS = '/converting-ncrs';
const PREPRESS_NCRS = '/prepress-ncrs';
const FOILING_EMBOSING_NCRS = '/foiling-embossing-ncrs';
const PRINTING_NCRS = '/printing-ncrs';
const BUSINESS_SUPPORT_NCRS = '/business-support-ncrs';
const SUPPLIER_NCRS = '/supplier-ncrs';
const DESPATCH_NCRS = '/despatch-ncrs';
const GLOBAL_ALERTS = '/global-alerts';
const ZONES = '/zones';
const TEMPLATES = '/templates';
const AUDIT_REPORTS = '/audit-reports';
const DISCREPANCIES = '/discrepancies';
const ERRORS = '/error';
const RELEASE_NOTES = '/release-notes';
const SHARPS = '/sharps';

export {
    DASHBOARD,
    TENANTS,
    USERS,
    ROLES,
    COMPANIES,
    CLASSIFICATION_TYPES,
    OPERATORS,
    CUSTOMER_COMPLAINTS,
    CONVERTING_NCRS,
    PREPRESS_NCRS,
    FOILING_EMBOSING_NCRS,
    PRINTING_NCRS,
    BUSINESS_SUPPORT_NCRS,
    SUPPLIER_NCRS,
    DESPATCH_NCRS,
    GLOBAL_ALERTS,
    ZONES,
    TEMPLATES,
    AUDIT_REPORTS,
    DISCREPANCIES,
    ERRORS,
    RELEASE_NOTES,
    SHARPS
};