import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export function PasswordField({ field, actions }) {    
    return (
        <FormGroup className={actions.getErrorClass(field)}>
            <Label for={field.name}>{field.label}&nbsp;&nbsp;&nbsp;<small>{actions.getErrorMessage(field)}</small></Label>                            
            <Input type="password" name={field.name} id={field.name} value={field.value} onChange={actions.handleChange} autoComplete={field.autoComplete} />
        </FormGroup>
    );
}