import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { RolesList } from './List';
import { EntityService } from '../../services/EntityService';

export class RolesListContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            roles: [],
            isRolesLoaded: false,
            isSearchingActive: false
        };

        // Bind the finctions that will be passed to the other components.
        // Doing this means that the function will be executed within the context of this container.
        this.handleSearch = this.handleSearch.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        // Define and initialise the the relevant properties and services etc.
        this.EntityService = new EntityService("Roles");
    }

    componentDidMount() {
        // Retrieve the Roles.
        // Once these have been obtained, we will add these items into the state
        // When they are in the state they can then be used within other components.
        this.handleSearch();
    }

    handleSearch(searchText) {
        this.setState({ 
            isSearchingActive: true
        });

        const handleResponse = (response) => {
            const roles = response.data.results.map(role => {
                return {
                    ...role
                };
            });

            this.setState({ 
                roles: roles,
                isRolesLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = (error) => {
            NotificationManager.error('Could not obtain results for Roles', 'Error', 1000);
        };

        if(searchText && searchText.length > 0) {
            this.EntityService.search(searchText, 1, 1000, 'Name', 'asc').then(handleResponse, handleError);
        }
        else {
            this.EntityService.getList(1, 1000, 'Name', 'asc').then(handleResponse, handleError);
        }
    }

    handleEdit(role, e) {
        e.preventDefault();
        this.props.history.push('/roles/edit/' + role.id);
    }

    handleDelete(role, e) {
        e.preventDefault();

        this.EntityService.delete(role.id).then(
            response => { 
                const roles = _.filter(this.state.roles, x => x.id !== role.id);
                this.setState({ roles: roles }, () => {
                    NotificationManager.success('The Role ' + role.name + ' was deleted.', 'Role Deleted', 1000);
                });
            },
            error => {
                NotificationManager.error('Role was not deleted', 'Error', 1000);
            }
        );
    }

    render() {
        const isLoaded = this.state.isRolesLoaded;

        // Buold the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <NavLink  to="/roles/create" className="btn btn-sm btn-success">
                <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create Role
            </NavLink>
        );
        
        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Roles..."
        };

        return (
            <Screen title="Roles" menu={screenMenu} loader={screenLoader}>
                <RolesList
                    items={this.state.roles}
                    handleSearch={this.handleSearch}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                />
            </Screen>
        );
    }
}
