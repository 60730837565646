import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import moment from 'moment';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { BusinessSupportNcrEditSummaryForm } from './EditSummaryForm';
import { BusinessSupportNcrEditJobDetailsForm } from './EditJobDetailsForm';
import { BusinessSupportNcrService } from '../../services/BusinessSupportNcrService';
import { LookupService } from '../../services/LookupService';
import { CompanyService } from '../../services/CompanyService';
import { EntityService } from '../../services/EntityService';
import { Form } from '../FormComponents/BaseForm/Form';
import { FormTable } from '../FormComponents/BaseForm/FormTable';
import { FormSummary } from '../FormComponents/BaseForm/FormSummary';
import { FormJobDetails } from '../FormComponents/BaseForm/FormJobDetails';
import { FormContacts } from '../FormComponents/BaseForm/FormContacts';
import { PdfDownload } from '../FormComponents/Pdf/PdfDownload';
import { PdfTable } from '../FormComponents/Pdf/PdfTable';
import { useDispatch } from 'react-redux';
import { refreshNavigation } from '../../reducers/navigationReducer';

export const BusinessSupportNcrViewContainer = props => {
    const [form, setForm] = useState([]);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [isStatusesLoaded, setIsStatusesLoaded] = useState(false);
    const [classificationTypes, setClassificationTypes] = useState([]);
    const [isClassificationTypesLoaded, setIsClassificationTypesLoaded] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isCustomersLoaded, setIsCustomersLoaded] = useState(false);

    // Services
    const dispatchStore = useDispatch();

    // Handles audit trail refresh
    const [isAuditTrailRefreshed, setIsAuditTrailRefreshed] = useState(false);
    const auditTrailRefresh = (tf) => setIsAuditTrailRefreshed(tf);

    // Global Component Services
    const [formService] = useState(new BusinessSupportNcrService());
    const formId = props.match.params.businessSupportNcrId * 1;
    const formTitle = "Business Support NCR";
    const formNavLink = "/business-support-ncrs";

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            const lookupService = new LookupService();
            const classificationTypeService = new EntityService('ClassificationTypes');
            const companyService = new CompanyService();

            fetchForm();

            // Loads statuses
            lookupService.getByType("FormStatus").then(
                response => {
                    const statuses = response.data.results.map(status => {
                        return {
                            id: status.id,
                            name: status.name
                        };
                    });

                    setStatuses(statuses);
                    setIsStatusesLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain statuses', 'Error', 1000);
                }
            );

            // Loads classification Types
            classificationTypeService.getList(1, 1000, "Name", "desc").then(
                response => {
                    const classificationTypes = response.data.results.map(classificationType => {
                        return {
                            id: classificationType.id,
                            name: classificationType.name
                        };
                    });

                    setClassificationTypes(classificationTypes);
                    setIsClassificationTypesLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain classification types', 'Error', 1000);
                }
            );

            // Custom list.
            companyService.getCustomersList(1, 1000, "Name", "asc").then(
                response => {
                    const customers = response.data.results.map(customer => {
                        return {
                            id: customer.id,
                            name: customer.name
                        };
                    });

                    setCustomers(customers);
                    setIsCustomersLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain customers', 'Error', 1000);
                }
            );
        }, [formService, formId]);
    }

    // Fetches the businessSupport ncr
    const fetchForm = () => {
        formService.getById(formId).then(
            response => {
                const form = {
                    ...response.data,
                    statusId: response.data.status.id * 1,
                    classificationTypeId: response.data.classificationType.id * 1,
                    // Custom props.
                    customerId: response.data.customer.id * 1
                }
                setForm(form);
                setIsFormLoaded(true);

dispatchStore(refreshNavigation());
            },
            error => {
                NotificationManager.error('Could not obtain specified non-conformance report', 'Error', 1000);
            }
        )
    };

    useEffect(() => {
        if (isAuditTrailRefreshed === true) {
            fetchForm();
            setIsAuditTrailRefreshed(false);            
        }     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuditTrailRefreshed])

    // Updates the form (PATCH request)
    const updateForm = (patchedFields) => {
        var model = [];
        const fields = Object.keys(patchedFields);
        const prevFields = Object.keys(form).filter(f => fields.includes(f)); // Filtered fields

        for (const entry of fields.entries()) {
            const key = entry[1]; // Skip index and take key value
            const prevKey = prevFields.filter(f => f.includes(key));
            const value = patchedFields[key];
            const prevValue = form[prevKey];

            if (value !== prevValue) {
                var json = {
                    "op": "replace",
                    "path": "/" + key,
                    "value": value
                };
                model.push(json);
            }
        }

        formService.patch(form.id, model).then(
            response => {
                fetchForm();
                auditTrailRefresh(true);
                NotificationManager.success(form.ncrLogNo + ' was updated.', 'Updated', 1000); // make CCR Log No
            },
            error => {
                NotificationManager.error('The NCR was not updated.', 'Error', 1000);
            }
        );
    };


    //  ===============================================================================================================

    const isLoaded = isFormLoaded && isStatusesLoaded && isClassificationTypesLoaded && isCustomersLoaded;
    const title = isLoaded ? form.ncrLogNo : "Non-Conformance Report";

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <>
            <NavLink to={formNavLink} className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to {formTitle}s List
            </NavLink>

            <FormContacts
                formId={formId}
                formService={formService}
                auditTrailRefresh={auditTrailRefresh}
            />

            <PdfDownload
                formId={formId}
                formService={formService}
                title={formTitle}
                status={form.status}
                logNo={form.ncrLogNo}
                summary={
                    <>
                        <PdfTable
                            headers={["NCR Log No", "Job Planned By", "Date Job Planned"]}
                            cells={[form.ncrLogNo, form.jobPlanner, form.dateJobPlanned != null ? moment(form.dateJobPlanned).format('DD/MM/YYYY') : "N/A"]}
                        />
                        <PdfTable
                            headers={["Description of Issue"]}
                            cells={[form.description]}
                        />
                    </>
                }
                jobDetails={
                    <PdfTable
                        headers={["Customer", "SKU", "Work Ticket / Batch No", "Quantity"]}
                        cells={[form.customer !== undefined ? form.customer.name : "N/A", form.stockCode, form.workTicketNo, form.quantity]}
                    />
                }
            />
        </>
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading " + formTitle + "..."
    };

    return (
        <Screen title={title} menu={screenMenu} loader={screenLoader}>
            <Form
                title={formTitle}
                status={form.status}
                formId={formId}
                formService={formService}
                data={form}
                isAuditTrailRefreshed={isAuditTrailRefreshed}
                auditTrailRefresh={auditTrailRefresh}
            >
                <FormSummary
                    title={formTitle}
                status={form.status}
                    render={
                        <>
                            <FormTable
                                headers={["NCR Log No", "Job Planned By", "Date Job Planned"]}
                                cells={[form.ncrLogNo, form.jobPlanner, form.dateJobPlanned != null ? moment(form.dateJobPlanned).format('DD/MM/YYYY') : "N/A"]}
                            />
                            <FormTable
                                headers={["Description of Issue"]}
                                cells={[form.description]}
                            />
                        </>
                    }
                    modal={
                        <BusinessSupportNcrEditSummaryForm
                            data={form}
                            handleSave={updateForm}
                            statusOptions={statuses}
                            classificationTypeOptions={classificationTypes}
                        />
                    }
                />
                <FormJobDetails
                    render={
                        <FormTable
                            headers={["Customer", "SKU", "Work Ticket / Batch No", "Quantity"]}
                            cells={[form.customer !== undefined ? form.customer.name : "N/A", form.stockCode, form.workTicketNo, form.quantity]}
                        />
                    }
                    modal={
                        <BusinessSupportNcrEditJobDetailsForm
                            data={form}
                            handleSave={updateForm}                            
                            customersOptions={customers}
                        />
                    }
                />
            </Form>
        </Screen>
    );
}