import rules from "./rbac-rules";
import { Keys } from '../../enums';
import { EncodedStorageService } from '../../services/EncodedStorageService';

// Updated check function to accept an array of roles and check each role against the permissions
const check = (rules, roles, action, data) => {
  return roles.some(role => {
    const permissions = rules[role];
    if (!permissions) {
      // Role is not present in the rules
      return false;
    }

    const staticPermissions = permissions.static;
    if (staticPermissions && staticPermissions.includes(action)) {
      // Static permission provided for action
      return true;
    }

    const dynamicPermissions = permissions.dynamic;
    if (dynamicPermissions) {
      const permissionCondition = dynamicPermissions[action];
      if (permissionCondition && permissionCondition(data)) {
        // Dynamic permission provided for action
        return true;
      }
    }
    return false;
  });
};

const storage = new EncodedStorageService();
const currentUser = storage.get(Keys.AuthUser);
// Retrieve all roles of the current user
const roles = currentUser != null ? currentUser.roles : [];

export const Can = props =>
    // Pass the array of roles to the check function
    check(rules, roles, props.perform, props.data)
        ? props.yes()
        : props.no();

Can.defaultProps = {
  yes: () => null,
  no: () => null
};