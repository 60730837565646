import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { ClassificationTypeForm } from './Form';
import { EntityService } from '../../services/EntityService';
import { LookupService } from '../../services/LookupService';

export class EditClassificationTypeContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            classificationType: {},
            isClassificationTypeLoaded: false
        };

        this.ClassificationTypeService = new EntityService("ClassificationTypes");
        this.LookupService = new LookupService();

        this.updateClassificationType = this.updateClassificationType.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.classificationTypeId = this.props.match.params.classificationTypeId * 1;

    }

    componentDidMount() {
        this.ClassificationTypeService.getById(this.classificationTypeId).then(
            response => {
                const classificationType = {
                    ...response.data,
                    daysEstimated: response.data.daysEstimated,
                };


                this.setState({
                    classificationType: classificationType,
                    isClassificationTypeLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified Classification Type', 'Error', 1000);
            }
        );
    }

    updateClassificationType(classificationType) {
        var model = {
            ...classificationType
        };

        this.ClassificationTypeService.update(this.classificationTypeId, model).then(
            response => {
                // Alert the client that the classificationType has been updated
                NotificationManager.success('The classification type ' + classificationType.name + ' was updated.', 'Classification Type Updated', 1000);
            },
            error => {
                // Alert the client that the classificationType has NOT been updated
                NotificationManager.error('Classification Type was not updated', 'Error', 1000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the ClassificationTypeForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.classificationTypeForm && this.classificationTypeForm.handleSubmit) {
            const form = this.classificationTypeForm.state.form
            if (form.isValid) {
                this.classificationTypeForm.handleSubmit(e);
            }
            else {
                this.classificationTypeForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 1000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isClassificationTypeLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/classification-types" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Classification Types List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit Classification Type Form..."
        };

        return (
            <Screen title="Edit Classification Type" menu={screenMenu} loader={screenLoader}>
                <ClassificationTypeForm
                    ref={(el) => this.classificationTypeForm = el}
                    data={this.state.classificationType}
                    handleSave={this.updateClassificationType}
                    
                />
            </Screen>
        );
    }
}