import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { TenantsList } from './List';
import { EntityService } from '../../services/EntityService';

export class TenantsListContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            tenants: [],
            isTenantsLoaded: false,
            isSearchingActive: false
        };

        // Bind the finctions that will be passed to the other components.
        // Doing this means that the function will be executed within the context of this container.
        this.handleSearch = this.handleSearch.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        // Define and initialise the the relevant properties and services etc.
        this.EntityService = new EntityService("Tenants");
    }

    componentDidMount() {
        // Retrieve the Tenants.
        // Once these have been obtained, we will add these items into the state
        // When they are in the state they can then be used within other components.
        this.handleSearch();
    }

    handleSearch(searchText) {
        this.setState({ 
            isSearchingActive: true
        });

        const handleResponse = (response) => {
            const tenants = response.data.results.map(tenant => {
                return {
                    ...tenant
                };
            });

            this.setState({ 
                tenants: tenants,
                isTenantsLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = (error) => {
            NotificationManager.error('Could not obtain results for Tenants', 'Error', 1000);
        };

        if(searchText && searchText.length > 0) {
            this.EntityService.search(searchText, 1, 1000, 'TenantName', 'asc').then(handleResponse, handleError);
        }
        else {
            this.EntityService.getList(1, 1000, 'TenantName', 'asc').then(handleResponse, handleError);
        }
    }

    handleEdit(tenant, e) {
        e.preventDefault();
        this.props.history.push('/organisations/edit/' + tenant.id);
    }

    handleDelete(tenant, e) {
        e.preventDefault();

        this.EntityService.delete(tenant.id).then(
            response => { 
                const tenants = _.filter(this.state.tenants, x => x.id !== tenant.id);
                this.setState({ tenants: tenants }, () => {
                    NotificationManager.success('The Organisation ' + tenant.name + ' was deleted.', 'Organisation Deleted', 1000);
                });
            },
            error => {
                NotificationManager.error('Organisation was not deleted', 'Error', 1000);
            }
        );
    }

    render() {
        const isLoaded = this.state.isTenantsLoaded;

        // Buold the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <NavLink  to="/organisations/create" className="btn btn-sm btn-success">
                <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create Organisation
            </NavLink>
        );
        
        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Organisations..."
        };

        return (
            <Screen title="Organisations" menu={screenMenu} loader={screenLoader}>
                <TenantsList
                    items={this.state.tenants}
                    handleSearch={this.handleSearch}
                    handleEdit={this.handleEdit}
                    handleDelete={this.handleDelete}
                />
            </Screen>
        );
    }
}
