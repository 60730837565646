import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../Modals/Confirm';

export function ConfirmDeleteRoleModal(props) {
    return (
        <ConfirmModal 
            isOpen={props.isOpen}
            toggle={props.toggle} 
            handleConfirm={(e) => props.handleConfirm(props.role, e)}
            title="Delete Role?"
            confirmText="Yes, Delete Role"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following Role?</strong>
            </Alert>

            <Table bordered>
                <thead>
                    <tr>
                        <th className="col-xs-4">Name</th>
                        <th className="col-xs-4">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.role.name}</td>
                        <td>{props.role.description}</td>
                    </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );
}