import React, { useState, useEffect } from 'react';
import { Form, Input, Card, CardBody, CardTitle, Col, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import moment from 'moment';

export const EditAuditReportForm = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);

    useEffect(() => {
        // Initialize local state with props
        setQuestionsAndAnswers(props.report.questionsAndAnswers);
    }, [props.report.questionsAndAnswers]);

    const toggle = () => setDropdownOpen(prevState => !prevState);

    const handleInputChange = (id, field, value) => {
        const updatedQuestions = questionsAndAnswers.map(qa => {
            if (qa.id === id) {
                return { ...qa, [field]: value };
            }
            return qa;
        });
        setQuestionsAndAnswers(updatedQuestions);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.handleSubmit(questionsAndAnswers);
    };

    const handleFinish = (e) => {
        e.preventDefault();
        props.handleFinish(questionsAndAnswers);
    };

    const handleCompliantChange = (id, isCompliant) => {
        const updatedQuestions = questionsAndAnswers.map(qa => {
            if (qa.id === id) {
                // If isCompliant is already the same value, set it to null, otherwise set to the new value
                const newIsCompliant = qa.isCompliant === isCompliant ? null : isCompliant;
                return { ...qa, isCompliant: newIsCompliant };
            }
            return qa;
        });
        setQuestionsAndAnswers(updatedQuestions);
    }

    // Group questions and answers by categoryName
    const groupedQuestions = questionsAndAnswers.reduce((acc, qa) => {
        acc[qa.categoryName] = acc[qa.categoryName] || [];
        acc[qa.categoryName].push(qa);
        return acc;
    }, {});

    return (        
        <>
            <Row>
                <Col xs="12">
                    <h5>Auditor: {props.report.auditor}</h5>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    {props.report.status === 'Completed' && (
                        <h5>Completed On: {moment(props.report.completedOnUtc).format('DD/MM/YYYY')}</h5>
                    )}
                </Col>
            </Row>
        
        <Row>
            <Col xs="12">
                <Form>
                    {Object.entries(groupedQuestions).map(([categoryName, questions]) => (
                        <React.Fragment key={categoryName}>
                            <Card>
                                <CardBody>
                                    <CardTitle tag="h5">{categoryName}</CardTitle>
                                    {questions.sort((a, b) => a.sortOrder - b.sortOrder).map((qa) => (
                                        <Row key={qa.id} style={{paddingBottom: '40px', paddingTop: '20px'}}>
                                            <Col xs="12" md="8">
                                                <label htmlFor={`answer-${qa.id}`}>{qa.question}</label>
                                                <Input
                                                    type="textarea"
                                                    id={`answer-${qa.id}`}
                                                    style={{height: '120px'}}
                                                    value={qa.answer || ''}
                                                    placeholder="Enter your findings or any relevant comments..."
                                                    onChange={(e) => handleInputChange(qa.id, 'answer', e.target.value)}
                                                    disabled={props.report.status === 'Completed'}
                                                />
                                            </Col>

                                            <Col xs="6" md="2">
                                                <label>Compliant</label><br/>
                                                <Input
                                                    type="checkbox"
                                                    id={`compliant-${qa.id}`}
                                                    checked={qa.isCompliant === true}
                                                    disabled={props.report.status === 'Completed'}
                                                    onChange={() => handleCompliantChange(qa.id, true)}
                                                    style={{ width: '20px', height: '20px', marginLeft: '1px' }}
                                                />
                                            </Col>
                                            <Col xs="6" md="2">
                                                <label>Non-Compliant</label><br/>
                                                <Input
                                                    type="checkbox"
                                                    id={`nonCompliant-${qa.id}`}
                                                    checked={qa.isCompliant === false}
                                                    onChange={() => handleCompliantChange(qa.id, false)}
                                                    disabled={props.report.status === 'Completed'}
                                                    style={{ width: '20px', height: '20px', marginLeft: '1px' }} 
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </CardBody>
                            </Card>
                        </React.Fragment>
                    ))}
                    {props.report.status === 'In Progress' && (
                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle color="success" caret>
                                Save
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem onClick={handleSubmit}>Save</DropdownItem>
                                <DropdownItem onClick={handleFinish}>Save and Finish</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                </Form>
            </Col>
        </Row>
    </>
    );
};