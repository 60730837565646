import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import { Col, Alert, Button, ListGroup, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { Keys } from '../../../enums';
import { NotificationManager } from '../../../helpers/NotificationManager';
import { EncodedStorageService } from '../../../services/EncodedStorageService';
import { BasicModal } from '../../Modals/Basic';
import { CreateNoteForm } from './CreateNoteForm';
import { EditNoteForm } from './EditNoteForm';
import { ConfirmDeleteNoteModal } from './ConfirmDeleteNoteModal';
import { Can } from '../../RBAC/Can';
import { SimpleLoader } from '../../Common/SimpleLoader';
import AttachmentViewer from './AttachmentViewer';

export const NoteList = props => {
    const [currentNote, setCurrentNote] = useState([]);
    const [notes, setNotes] = useState([]);
    const [isNotesLoaded, setIsNotesLoaded] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

    const storage = new EncodedStorageService();
    const currentUser = storage.get(Keys.AuthUser);
    const formService = props.formService;
    const formId = props.formId * 1;

    // Methods
    const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen);
    const toggleEditModal = (note) => { setCurrentNote(note); setIsEditModalOpen(!isEditModalOpen); }
    const toggleConfirmDeleteModal = (note) => { setCurrentNote(note); setIsConfirmDeleteModalOpen(!isConfirmDeleteModalOpen); }
    const handleDeleteConfirmed = (entity, e) => deleteNote(entity, e);

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            fetchNotes();
        }, [formService, formId]);
    }

    // Fetches notes
    const fetchNotes = () => {
        formService.getNotes(formId).then(
            response => {
                const notes = response.data.results.map(note => {
                    return { ...note };
                   
                });
                setNotes(notes);
                setIsNotesLoaded(true);
            },
            error => {
                setNotes(null);
                setIsNotesLoaded(true);
            }
        );
    }

    // Actions
    const createNote = (note) => {
        var model = { ...note };
        formService.createNote(formId, model).then(
            response => {
                fetchNotes();
                props.auditTrailRefresh(true);
                NotificationManager.success('The note was created successfully', 'Created', 1000);
            },
            error => {
                NotificationManager.error('The note was not created', 'Error', 1000);
            }
        );
    }

    const editNote = (note) => {
        var model = { ...note };
        const noteId = note.id * 1;
        formService.editNote(formId, noteId, model).then(
            response => {
                fetchNotes();
                props.auditTrailRefresh(true);
                NotificationManager.success('The note was updated.', 'Updated', 1000);
            },
            error => {
                NotificationManager.error('The note was not updated', 'Error', 1000);
            }
        );
    };

    const deleteNote = (note, e) => {
        e.preventDefault();
        const noteId = note.id * 1;
        formService.deleteNote(formId, noteId).then(
            response => {
                fetchNotes();
                props.auditTrailRefresh(true);
                NotificationManager.success('The note was deleted.', 'Note Deleted', 1000);
            },
            error => {
                NotificationManager.error('The note was not deleted', 'Error', 1000);
            }
        );
    };


    var groups = {};

    if (notes !== null) {
   // This gives an object with dates as keys
   groups = notes.reduce((groups, note) => {
    const date = note.dateCreated.split('T')[0];
    if (!groups[date]) {
        groups[date] = [];
    }
    groups[date].push(note);
    return groups;
}, {});
    }

 

    // Add it in the array format 
    const groupArrays = Object.keys(groups).map((date) => {
        return { date, notes: groups[date] };
    });

    const hasAny = notes !== null ? true : false;
    const isLoaded = isNotesLoaded;

    return (
        <>
            <ListGroup>
                <ListGroupItem active>
                    <ListGroupItemHeading> Notes
                    <Button className="float-right" color="success" size="sm" onClick={() => toggleCreateModal()} style={{ margin: "0px 5px 0px 5px" }}>
                            <Icon.PlusCircle className="feather" /> Create Note
                    </Button>
                    </ListGroupItemHeading>
                </ListGroupItem>
                <ListGroupItem style={{ maxHeight: "450px", overflowY: "auto" }}>
                    {isLoaded ?
                        <div>
                            {hasAny ?
                                <Col xs="12" style={{ marginTop: "10px" }}>
                                    {
                                        groupArrays.map((group, groupIndex) => {
                                            const date = moment(group.date).format('dddd, MMMM Do YYYY');
                                            const notes = group.notes;
                                            return (
                                                [
                                                    <p key={groupIndex} style={{ textAlign: "center", color: "grey" }}>{date}</p>,

                                                    notes.map((note, noteIndex) => {
                                                        var user = note.user != null ? note.user.firstName + ' ' + note.user.surname : "Deleted User";
                                                        return (
                                                            <Alert key={noteIndex} color="warning">
                                                                {<strong>{user} <small>{moment(note.dateCreated).format('hh:mm A')}</small></strong>}

                                                                <Can
                                                                    perform="notes:all"
                                                                    yes={() => (
                                                                        <>
                                                                            <Button className="float-right" color="danger" size="xs" onClick={() => toggleConfirmDeleteModal(note)} style={{ margin: "0px 2px 0px 2px", padding: "0px 5px 0px 5px" }}>
                                                                                <Icon.XCircle className="feather" />
                                                                            </Button>
                                                                            <Button className="float-right" color="success" size="xs" onClick={() => toggleEditModal(note)} style={{ margin: "0px 2px 0px 2px", padding: "0px 5px 0px 5px" }}>
                                                                                <Icon.Edit2 className="feather" />
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                />
                                                                <Can
                                                                    perform="notes:self"
                                                                    data={{
                                                                        currentUserId: currentUser.id,
                                                                        noteUserId: note.user != null ? note.user.id : 0
                                                                    }}
                                                                    yes={() => (
                                                                        <>
                                                                            <Button className="float-right" color="danger" size="xs" onClick={() => toggleConfirmDeleteModal(note)} style={{ margin: "0px 2px 0px 2px", padding: "0px 5px 0px 5px" }}>
                                                                                <Icon.XCircle className="feather" />
                                                                            </Button>
                                                                            <Button className="float-right" color="success" size="xs" onClick={() => toggleEditModal(note)} style={{ margin: "0px 2px 0px 2px", padding: "0px 5px 0px 5px" }}>
                                                                                <Icon.Edit2 className="feather" />
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                />
                                                                <hr />
                                                                {note.comments}

                                                                <AttachmentViewer attachments={note.attachments}/>
                                                            </Alert>
                                                            
                                                        );
                                                    })
                                                ]
                                            )
                                        })
                                    }
                                </Col> : <Col xs="12" style={{ marginTop: "10px" }}><Alert color="warning">No entries were found.</Alert></Col>}

                            {/* Create */}
                            <BasicModal
                                isOpen={isCreateModalOpen}
                                toggle={toggleCreateModal}
                                title="Create Note"
                            >
                                <CreateNoteForm
                                    handleSave={createNote}>
                                </CreateNoteForm>
                            </BasicModal>

                            {/* Edit */}
                            <BasicModal
                                isOpen={isEditModalOpen}
                                toggle={toggleEditModal}
                                title="Edit Note"
                            >
                                <EditNoteForm
                                    data={currentNote}
                                    handleSave={editNote}>
                                </EditNoteForm>
                            </BasicModal>

                            {/* Confirm Delete */}
                            <ConfirmDeleteNoteModal
                                isOpen={isConfirmDeleteModalOpen}
                                toggle={toggleConfirmDeleteModal}
                                handleConfirm={handleDeleteConfirmed}
                                note={currentNote}
                            />
                        </div>
                        : <SimpleLoader active={true} spinner text="Loading..." />}
                </ListGroupItem>
            </ListGroup>
        </>
    );
}