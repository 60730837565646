import React from 'react';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import DataTable from 'react-data-table-component';
import { Alert } from 'reactstrap';
import { StatusSearchForm } from '../Common/StatusSearchForm';
import { ConvertingNcrActionsButton } from './ActionsButton';
import { ClassificationTypeBadge } from '../FormComponents/Badges/ClassificationTypeBadge';
import { StatusBadge } from '../FormComponents/Badges/StatusBadge';

export function ConvertingNcrsList(props) {
    const { items, paging, onChangePage, onChangeRowsPerPage } = props;

    const columns = [
        {
            name: 'NCR Log No',
            selector: 'ncrLogNo',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/converting-ncrs/view/' + row.formId}>{row.ncrLogNo}</NavLink>
            }
        },
        {
            name: 'Customer',
            selector: 'customer.name',
            sortable: true
        },
        {
            name: 'Classification Type',
            selector: 'classificationType.name',
            sortable: true,
            cell: (row) => {
                return (
                    <ClassificationTypeBadge 
                    type={row.classificationType} />
                )
            }
        },
        {
            name: 'Status',
            selector: 'status.name',
            sortable: true,
            cell: (row) => {
                return (
                    <StatusBadge 
                    type={row.status} />
                );
            }
        },
        {
            name: 'Date Raised',
            selector: 'dateCreated',
            sortable: true,
            cell: (row) => {
                return moment(row.dateCreated).format('DD/MM/YYYY')
            }
        },
        {
            name: 'Date Due',
            selector: 'dateDue',
            sortable: true,
            cell: (row) => {
                if (row.status.id === 4 || row.status.id === 5) {
                    return <>- </>
                } 
                else {
                    if (props.currentDate >= moment(row.dateDue)) {
                        return <>
                            {moment(row.dateDue).format('DD/MM/YYYY')}&nbsp; <Icon.AlertTriangle className="feather" color="red"/> 
                        </>
                    }
                    else {
                        return moment(row.dateDue).format('DD/MM/YYYY');
                    }
                }   
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <ConvertingNcrActionsButton
                        convertingNcr={row}
                        handleView={props.handleView}
                        handleEdit={props.handleEdit}
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <StatusSearchForm 
                handleSearch={props.handleSearch} 
                handleStatusChange={props.handleStatusChange}
            />  
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={items}
                        defaultSortField="name"
                        pagination
                        paginationServer
                        highlightOnHover
                        noHeader
                        paginationPerPage={paging.pageSize ?? 50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        onChangePage={onChangePage}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        paginationTotalRows={paging.totalItemCount}
                    />
                ) : <Alert color="warning">No Converting NCRs were found</Alert>
            }
        </>
    );
}

