import axios from 'axios';
import config from '../config';

export class DiscrepancyService {
    constructor() {
        this.basePath = 'Discrepancies';
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }

    getDiscrepancies(page, size, sort, direction, searchTerm = '', categoryIds = '', statuses = '') {
        const endpoint = this.baseUrl;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
                categoryIds: categoryIds,
                statuses: statuses
            }
        });
    }

    getDiscrepancy(id) {
        const endpoint = this.baseUrl + id;
        return axios.get(endpoint);
    }

    addDiscrepancy(discrepancyInput) {
        const endpoint = this.baseUrl;
        return axios.post(endpoint, discrepancyInput);
    }

    editDiscrepancy(discrepancyInput) {
        const endpoint = this.baseUrl + discrepancyInput.id;
        return axios.put(endpoint, discrepancyInput);
    }

    finishDiscrepancy(discrepancyInput) {
        const endpoint = this.baseUrl + 'finish/' + discrepancyInput.id;
        return axios.post(endpoint, discrepancyInput);
    }

    deleteDiscrepancy(id) {
        const endpoint = this.baseUrl + id;
        return axios.delete(endpoint);
    }

    // Categories 
    getDiscrepancyCategories(page, size, sort, direction, searchTerm = '') {
        const endpoint = this.baseUrl + 'categories';
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
            }
        });
    }

    getDiscrepancyCategory(id) {
        const endpoint = this.baseUrl + 'categories/' + id;
        return axios.get(endpoint);
    }
    
    addDiscrepancyCategory(categoryInput) {
        const endpoint = this.baseUrl + 'categories';
        return axios.post(endpoint, categoryInput);
    }
    
    editDiscrepancyCategory(categoryInput) {
        const endpoint = this.baseUrl + 'categories/' + categoryInput.id;
        return axios.put(endpoint, categoryInput);
    }
    
    deleteDiscrepancyCategory(id) {
        const endpoint = this.baseUrl + 'categories/' + id;
        return axios.delete(endpoint);
    }
}