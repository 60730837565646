import axios from 'axios';
import { EntityService } from './EntityService';

export class FormService extends EntityService {
    // Gets all forms
    getForms(page, size, sort, direction, query) {
        const endpoint = this.baseUrl + 'GetForms/';
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                status: query
            }
        });
    }
    
    // Searches form
    formSearch(query, type, status, page, size, sort, direction) {
        const endpoint = this.baseUrl + 'Search/';
        return axios.get(endpoint, {
            params: {
                text: query,
                type: type, 
                status: status,
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    // Default Contacts
    getDefaultContacts(id) {
        const endpoint = this.baseUrl + "GetDefaultContacts/" + id;
        return axios.get(endpoint);
    }

    // Contacts
    getContacts(id) {
        const endpoint = this.baseUrl + id + "/Contacts/";
        return axios.get(endpoint);
    }

    getPotentialContacts(id) {
        const endpoint = this.baseUrl + id + "/GetPotentialContacts/";
        return axios.get(endpoint);
    }

    updateContacts(id, model) {
        const endpoint = this.baseUrl + id + "/UpdateContacts/";
        return axios.put(endpoint, model);
    }

    // Root Cause
    getRootCause(id) {
        const endpoint = this.baseUrl + id + "/RootCauses/";
        return axios.get(endpoint);
    }

    createRootCause(id, model) {
        const endpoint = this.baseUrl + id + "/RootCauses/";
        return axios.post(endpoint, model);
    }

    editRootCause(id, model) {
        const endpoint = this.baseUrl + id + "/RootCauses/";
        return axios.put(endpoint, model);
    }

    // Actions Taken 
    getActionTaken(id) {
        const endpoint = this.baseUrl + id + "/ActionsTaken/";
        return axios.get(endpoint);
    }

    createActionTaken(id, model) {
        const endpoint = this.baseUrl + id + "/ActionsTaken/";
        return axios.post(endpoint, model);
    }

    editActionTaken(id, model) {
        const endpoint = this.baseUrl + id + "/ActionsTaken/";
        return axios.put(endpoint, model);
    }

    // Follow Ups 
    getFollowUps(id, page, size, sort, direction) {
        const endpoint = this.baseUrl + id + "/FollowUps/";
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }

    getFollowUpById(id) {
        const endpoint = this.baseUrl + id + "/FollowUps/";
        return axios.get(endpoint);
    }

    createFollowUp(id, model) {
        const endpoint = this.baseUrl + id + "/FollowUps/";
        return axios.post(endpoint, model);
    }

    editFollowUp(id, followUpId, model) {
        const endpoint = this.baseUrl + id + "/FollowUps/" + followUpId;
        return axios.put(endpoint, model);
    }

    deleteFollowUp(id, followUpId) {
        const endpoint = this.baseUrl + id + "/FollowUps/" + followUpId;
        return axios.delete(endpoint);
    }

    // Follow Up Responses
    createFollowUpResponse(id, followUpId, model) {
        const endpoint = this.baseUrl + id + "/FollowUps/" + followUpId + "/FollowUpResponses/";
        return axios.post(endpoint, model);
    }

    editFollowUpResponse(id, followUpId, followUpResponseId, model) {
        const endpoint = this.baseUrl + id + "/FollowUps/" + followUpId + "/FollowUpResponses/" + followUpResponseId;
        return axios.put(endpoint, model);
    }

    // Notes
    getNotes(id, model) {
        const endpoint = this.baseUrl + id + "/Notes/";
        return axios.get(endpoint, model);
    }

    createNote(id, model) {
        const endpoint = this.baseUrl + id + "/Notes/";
        return axios.post(endpoint, model);
    }

    editNote(id, noteId, model) {
        const endpoint = this.baseUrl + id + "/Notes/" + noteId;
        return axios.put(endpoint, model);
    }

    deleteNote(id, noteId) {
        const endpoint = this.baseUrl + id + "/Notes/" + noteId;
        return axios.delete(endpoint);
    }

    // Audit Trail
    getAuditTrail(id, page, size, sort, direction) {
        const endpoint = this.baseUrl + id + "/AuditTrails/";
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction
            }
        });
    }
}