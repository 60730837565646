import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import * as Icon from 'react-feather';
import { Role } from '../../enums'
import { RoleCondition } from '../AuthApp/RoleCondition';
import { Badge } from 'reactstrap';
import { connect, useDispatch } from 'react-redux';
import { getNavigation } from '../../actions/navigationActions';
import { refreshNavigation } from '../../reducers/navigationReducer';
import { AUDIT_REPORTS, SHARPS } from "../../constants/Route";

const Navigation = props => {
    const isMobileNavigation = props.isMobileNavigation === true ? true : false;
    const isMountedVal = useRef(1);
    const dispatch = useDispatch();

    // Mounts / Unmounts
    useEffect(() => {
        isMountedVal.current = 1;
        return () => { isMountedVal.current = 0; };
    });

    // Refresh
    useEffect(() => {
        if (isMountedVal.current) {
            dispatch(refreshNavigation)
        }
    }, [dispatch]);

    return (
        isMobileNavigation ?
            /* Mobile Navigation */
            <div className="mobile-nav">
                <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                    <span>Forms</span>
                </h6>
                <li className="nav-item">
                    <NavLink to="/customer-complaints" className="nav-link">
                        <Icon.FileText className="feather" />&nbsp;Customer Complaints
                        <Badge color="danger" pill>{props.navigationProps.customerComplaints.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/supplier-ncrs" className="nav-link">
                        <Icon.Truck className="feather" />&nbsp;Supplier NCRs
                        <Badge color="danger" pill>{props.navigationProps.supplierNcrs.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/business-support-ncrs" className="nav-link">
                        <Icon.Clipboard className="feather" />&nbsp;Business Support NCRs
                        <Badge color="danger" pill>{props.navigationProps.businessSupportNcrs.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/prepress-ncrs" className="nav-link">
                        <Icon.Layers className="feather" />&nbsp;Prepress NCRs
                        <Badge color="danger" pill>{props.navigationProps.prepressNcrs.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/converting-ncrs" className="nav-link">
                        <Icon.Package className="feather" />&nbsp;Converting NCRs
                        <Badge color="danger" pill>{props.navigationProps.convertingNcrs.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/foiling-embossing-ncrs" className="nav-link">
                        <Icon.Type className="feather" />&nbsp;Foiling/Embossing NCRs
                        <Badge color="danger" pill>{props.navigationProps.foilingEmbossingNcrs.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/printing-ncrs" className="nav-link">
                        <Icon.Printer className="feather" />&nbsp;Printing NCRs
                        <Badge color="danger" pill>{props.navigationProps.printingNcrs.unresolved}</Badge>
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to="/despatch-ncrs" className="nav-link">
                        <Icon.Truck className="feather" />&nbsp; Despatch NCRs
                        <Badge color="danger" pill>{props.navigationProps.despatchNcrs.unresolved}</Badge>
                    </NavLink>
                </li>

                <RoleCondition roles={[Role.AuditAdmin, Role.Auditor]}>
                    <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Audits</span>
                    </h6>

                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to={AUDIT_REPORTS} className="nav-link">
                                <Icon.FileText className="feather"/>&nbsp;Reports
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink to="/discrepancies" className="nav-link">
                                <Icon.Disc className="feather"/>&nbsp;Discrepancies
                            </NavLink>
                        </li>

                        <RoleCondition roles={[Role.AuditAdmin]}>
                            <li className="nav-item">
                                <NavLink to={SHARPS} className="nav-link">
                                    <Icon.Scissors className="feather"/>&nbsp;Sharps
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/zones" className="nav-link">
                                    <Icon.Box className="feather"/>&nbsp;Zones
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/templates" className="nav-link">
                                    <Icon.Folder className="feather"/>&nbsp;Templates
                                </NavLink>
                            </li>
                        </RoleCondition>
                    </ul>
                </RoleCondition>
            </div>

            :
            /*  Desktop Navigation */
            <nav className="col-md-2 d-none d-md-block bg-light sidebar">
                <div className="sidebar-sticky">
                    <ul className="nav flex-column">
                        <li className="nav-item">
                            <NavLink to="/" className="nav-link">
                                <Icon.Home className="feather"/>&nbsp;Dashboard
                            </NavLink>
                        </li>
                    </ul>

                    <RoleCondition roles={[Role.SuperAdmin, Role.Admin, Role.User]}>
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Forms</span>
                        </h6>

                        <ul className="nav flex-column">
                            <li className="nav-item" style={{background: 'lavender'}}>
                                <NavLink to="/customer-complaints" className="nav-link">
                                    <Icon.FileText className="feather"/>&nbsp;Customer Complaints
                                    <Badge color="danger"
                                           pill>{props.navigationProps.customerComplaints.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{background: 'lightyellow'}}>
                                <NavLink to="/supplier-ncrs" className="nav-link">
                                <Icon.Truck className="feather" />&nbsp;Supplier NCRs
                                    <Badge color="danger" pill>{props.navigationProps.supplierNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{ background: 'lightsalmon' }}>
                                <NavLink to="/business-support-ncrs" className="nav-link">
                                    <Icon.Clipboard className="feather" />&nbsp;Business Support NCRs
                                    <Badge color="danger" pill>{props.navigationProps.businessSupportNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{ background: 'white' }}>
                                <NavLink to="/prepress-ncrs" className="nav-link">
                                    <Icon.Layers className="feather" />&nbsp;Prepress NCRs
                                    <Badge color="danger" pill>{props.navigationProps.prepressNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{ background: 'lightsteelblue' }}>
                                <NavLink to="/converting-ncrs" className="nav-link">
                                    <Icon.Package className="feather" />&nbsp;Converting NCRs
                                    <Badge color="danger" pill>{props.navigationProps.convertingNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{ background: 'lightcyan' }}>
                                <NavLink to="/foiling-embossing-ncrs" className="nav-link">
                                    <Icon.Type className="feather" />&nbsp;Foiling/Embossing NCRs
                                    <Badge color="danger" pill>{props.navigationProps.foilingEmbossingNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{ background: 'lightblue' }}>
                                <NavLink to="/printing-ncrs" className="nav-link">
                                    <Icon.Printer className="feather" />&nbsp;Printing NCRs
                                    <Badge color="danger" pill>{props.navigationProps.printingNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                            <li className="nav-item" style={{ background: 'lightpink' }}>
                                <NavLink to="/despatch-ncrs" className="nav-link">
                                    <Icon.Truck className="feather-flip" />&nbsp; Despatch NCRs
                                    <Badge color="danger" pill>{props.navigationProps.despatchNcrs.unresolved}</Badge>
                                </NavLink>
                            </li>
                        </ul>
                    </RoleCondition>

                    <RoleCondition roles={[Role.AuditAdmin, Role.Auditor]}>
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                            <span>Audits</span>
                        </h6>

                        <ul className="nav flex-column">

                            <li className="nav-item">
                                <NavLink to={AUDIT_REPORTS} className="nav-link">
                                    <Icon.FileText className="feather"/>&nbsp;Reports
                                </NavLink>
                            </li>

                            <li className="nav-item">
                                <NavLink to="/discrepancies" className="nav-link">
                                    <Icon.Disc className="feather"/>&nbsp;Discrepancies
                                </NavLink>
                            </li>

                            <RoleCondition roles={[Role.AuditAdmin]}>
                                <li className="nav-item">
                                    <NavLink to={SHARPS} className="nav-link">
                                        <Icon.Scissors className="feather"/>&nbsp;Sharps
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/zones" className="nav-link">
                                        <Icon.Box className="feather"/>&nbsp;Zones
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/templates" className="nav-link">
                                        <Icon.Folder className="feather"/>&nbsp;Templates
                                    </NavLink>
                                </li>
                            </RoleCondition>
                        </ul>
                    </RoleCondition>

                    <RoleCondition roles={[Role.SuperAdmin]}>
                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>Management</span>
                        </h6>

                        <ul className="nav flex-column">
                            <RoleCondition roles={[Role.SuperAdmin]}>
                                <li className="nav-item">
                                    <NavLink to="/users" className="nav-link">
                                        <Icon.Users className="feather"/>&nbsp;Users
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/organisations" className="nav-link">
                                        <Icon.Filter className="feather"/>&nbsp;Organisations
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/lookups" className="nav-link">
                                        <Icon.List className="feather"/>&nbsp;Lookups
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to="/global-alerts" className="nav-link">
                                        <Icon.Globe className="feather"/>&nbsp;Global Alerts
                                    </NavLink>
                                </li>
                            </RoleCondition>                            
                        </ul>
                    </RoleCondition>
                </div>
            </nav>
    );
}

const mapStateToProps = state => ({
    navigationProps: state.navigationState
})

export default connect(mapStateToProps, {getNavigation})(Navigation);