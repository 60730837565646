import axios from 'axios';
import config from '../config';

export class SharpService {
    constructor() {
        this.basePath = 'Sharps';
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }
    getSharps(page, size, sort, direction, searchTerm = '', operatorId = null, zoneId = null) {
        const endpoint = this.baseUrl;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
                operatorId: operatorId,
                zoneId: zoneId
            }
        });
    }

    getSharp(id) {
        const endpoint = this.baseUrl + id;
        return axios.get(endpoint);
    }
    
    getTypes() {
        const endpoint = this.baseUrl + 'types';
        return axios.get(endpoint);
    }

    createSharp(sharpInput) {
        const endpoint = this.baseUrl;
        return axios.post(endpoint, sharpInput);
    }

    deleteSharp(id) {
        const endpoint = this.baseUrl + id;
        return axios.delete(endpoint);
    }

    getAuditReports(page, size, sort, direction, searchTerm = '', status = '', zoneId = null, start = null, end = null) {
        const endpoint = this.baseUrl + 'auditReports';
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm,
                status: status,
                zoneId: zoneId,
                start: start,
                end: end
            }
        });
    }

    getAuditReport(id) {
        const endpoint = this.baseUrl + 'auditReport/' + id;
        return axios.get(endpoint);
    }

    scheduleAuditReports(reportInput) {
        const endpoint = this.baseUrl + 'auditReport/schedule';
        return axios.post(endpoint, reportInput);
    }

    startAuditReport(reportInput) {
        const endpoint = this.baseUrl + 'auditReport/start';
        return axios.post(endpoint, reportInput);
    }

    saveAuditReport(reportInput) {
        const endpoint = this.baseUrl + 'auditReport/save';
        return axios.post(endpoint, reportInput);
    }

    finishAuditReport(reportInput) {
        const endpoint = this.baseUrl + 'auditReport/finish/' + reportInput.id;
        return axios.post(endpoint, reportInput);
    }

    deleteAuditReport(id) {
        const endpoint = this.baseUrl + 'auditReport/' + id;
        return axios.delete(endpoint);
    }
}