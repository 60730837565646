import React from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { Alert, Badge }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { GlobalAlertActionsButton } from './ActionsButton';

export function GlobalAlertsList(props) {
    const columns = [
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
            ignoreRowClick: true
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true,
            ignoreRowClick: true
        },
        {
            name: 'Company',
            selector: 'company.name',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return row.company != null ? <Badge color="secondary">{row.company.name}</Badge> : ''
            }
        },
        {
            name: 'Is Active?',
            selector: 'isActive',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return row.isActive === true ? <Badge color="success">Yes</Badge> : <Badge color="secondary">No</Badge> 
            }
        },
        {
            name: 'Start Date',
            selector: 'startDate',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return moment(row.startDate).format('DD/MM/YYYY')
            }
        },
        {
            name: 'End Date',
            selector: 'endDate',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return moment(row.endDate).format('DD/MM/YYYY')
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <GlobalAlertActionsButton 
                        globalAlert={row} 
                        handleEdit={props.handleEdit} 
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="isActive"
                        defaultSortAsc={false}
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No Global Alerts were found</Alert>
            }
        </>
    );
}

