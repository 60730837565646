import { NotificationManager } from '../helpers/NotificationManager';

// This file contains the reducers for services. There's no link between these and Redux
// so to save any confusion, all reducers for services are bundled together here
// for easy viewing

export const businessSupportNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const convertingNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const customerComplaintsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const despatchNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const foilingEmbossingNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const prepressNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const printingNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export const supplierNcrsReducer = (state, action) => {
    switch (action.type) {
        case "LOADING": {
            return { loading: true, forms: [], paging: null, error: null };
        }
        case "LOADED": {
            return { loading: false, forms: action.forms, paging: action.paging, error: null };
        }
        case "ERROR": {
            NotificationManager.error('Could not obtain results for the non-conformance-report', 'Error', 1000);
            return { loading: false, forms: [], paging: null, error: action.error };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}