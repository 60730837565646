
import React from 'react';
import { BaseForm, Validators } from '../FormBuilder';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';

export class LoginForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "username",
                    label: "Username",
                    validators: [
                        Validators.required(),
                    ],
                    autoComplete: 'username'
                },
                {
                    name: "password",
                    label: "Password",
                    editor: "password",
                    validators: [
                        Validators.required(),
                    ],
                    autoComplete: 'current-password'
                }                
            ]
        };
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }    

    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {       
        return (
            <Row form>
                <Col xs="12" md="12">
                    <FieldEditor field={fields.username} actions={this.fieldActions} />
                    <FieldEditor field={fields.password} actions={this.fieldActions}/>
                    <div className="form-group">
                    <NavLink to="/forgot-password">Forgot password?</NavLink>
                    </div>                           
                </Col>
            </Row>  
        );
    }   
}