import React from 'react';
import { Route, Redirect } from "react-router-dom";
import { Keys } from '../../enums';
import { EncodedStorageService } from '../../services/EncodedStorageService';

export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {
        const storage = new EncodedStorageService();
        const currentUser = storage.get(Keys.AuthUser);
        if (!currentUser) {
            // Not logged in so redirect to login page (with the return url?)
            return <Redirect to={{ pathname: '/' }} />
        }
        
        
        // check if route is restricted by role
        if (roles && currentUser.roles.some(role => roles.includes(role)) === false) {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/error/not-authorised'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
);