import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { Row, Col, Card, CardTitle, CardText } from 'reactstrap';

export const CompanyTypeCardList = props => {
    const [state, setState] = useState({
        selected: ''
    });

    const setFilter = filter => {
        setState({ ...state, selected: filter });
        props.handleFilter(filter);
    };

    const isActive = value => {
        switch(props.isFilterToggled) {
            case true:
            return (value === state.selected) ? 'companyTypeCard active' : 'companyTypeCard';
            default:
            return 'companyTypeCard';
         } 
    };

    return (
        <Row>
            <Col sm="4">
                <Card className={isActive('Customer')} body onClick={() => setFilter('Customer')}>
                    <Icon.User className="feather customer" />
                    <CardTitle>{props.customers}</CardTitle>
                    <CardText>Overdue Forms for <br/>Customers</CardText>
                </Card>
            </Col>
            <Col sm="4">
                <Card className={isActive('Customer and Supplier')} body onClick={() => setFilter('Customer and Supplier')}>
                    <Icon.UserPlus className="feather customerAndSupplier" />
                    <CardTitle>{props.customerAndSuppliers}</CardTitle>
                    <CardText>Overdue Forms for <br/>Customer and Suppliers</CardText>
                </Card>
            </Col>
            <Col sm="4">
                <Card className={isActive('Supplier')} body onClick={() => setFilter('Supplier')}>
                    <Icon.Truck className="feather supplier" />
                    <CardTitle>{props.suppliers}</CardTitle>
                    <CardText>Overdue Forms for <br/>Suppliers</CardText>
                </Card>
            </Col>
        </Row>
    );
}

