import React from 'react';
import { FormGroup, InputGroup, InputGroupAddon, Input, InputGroupText } from 'reactstrap';
import * as Icon from 'react-feather';
import { FormStatusDropdownList } from '../FormComponents/BaseForm/FormStatusDropdownList';

export class StatusSearchForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchText: '',
            typing: false,
            typingTimeout: 0
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }  

    handleSearchChange(e) {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        const value = e.target.value;
        this.setState({ 
            typing: true,
            searchText: value,
            typingTimeout: setTimeout(() => {
                this.handleSearch(); 
            }, 600)
        });
    }

    handleClearSearch() {
        if(this.state.searchText.length > 0) {
            this.setState({ searchText: ''}, () => { this.handleSearch(); }); 
        }        
    }

    handleSearch() {
        if(this.props.handleSearch) {
            const searchText = this.state.searchText;
            this.props.handleSearch(searchText);
        }
    }

    render() {
        return (
            <div className="table-data-search" style={{marginBottom: '14px'}}>
                <div className="row">
                    <div className="col-xs-12 col-md-3">
                    <FormStatusDropdownList handleStatusChange={this.props.handleStatusChange}/>

                    </div>
                    <div className="col-xs-12 col-md-4 offset-md-5">
                        <FormGroup style={{"marginBottom":"10px"}}>                       
                            <InputGroup>
                                <Input type="text" name="searchText" id="searchText" onChange={this.handleSearchChange} value={this.state.searchText} placeholder="Search..." />
                                <InputGroupAddon addonType="append">
                                    <InputGroupText onClick={this.handleClearSearch}>
                                        { this.state.searchText.length > 0 ? <Icon.X />: <Icon.Search /> }
                                    </InputGroupText>                        
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
            </div>
        );
    }
}