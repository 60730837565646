import React from 'react';
import { Alert } from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../Modals/Confirm';

export function LogoutModal(props) {
    return (
        <ConfirmModal 
            isOpen={props.isOpen}
            toggle={props.toggle} 
            handleConfirm={props.handleLogout}
            title="Confirm Logout?"
            confirmText="Yes, Logout"
            confirmColor="success"
            confirmIcon={Icon.LogOut}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to logout?</strong>
            </Alert>
        </ConfirmModal>
    );
}