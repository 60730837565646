
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import moment from 'moment';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { PrepressNcrEditSummaryForm } from './EditSummaryForm';
import { PrepressNcrEditJobDetailsForm } from './EditJobDetailsForm';
import { PrepressNcrService } from '../../services/PrepressNcrService';
import { LookupService } from '../../services/LookupService';
import { CompanyService } from '../../services/CompanyService';
import { EntityService } from '../../services/EntityService';
import { Form } from '../FormComponents/BaseForm/Form';
import { FormTable } from '../FormComponents/BaseForm/FormTable';
import { FormSummary } from '../FormComponents/BaseForm/FormSummary';
import { FormJobDetails } from '../FormComponents/BaseForm/FormJobDetails';
import { FormContacts } from '../FormComponents/BaseForm/FormContacts';
import { PdfDownload } from '../FormComponents/Pdf/PdfDownload';
import { PdfTable } from '../FormComponents/Pdf/PdfTable';
import { useDispatch } from 'react-redux';
import { refreshNavigation } from '../../reducers/navigationReducer';

export const PrepressNcrViewContainer = props => {
    const [form, setForm] = useState([]);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [isStatusesLoaded, setIsStatusesLoaded] = useState(false);
    const [classificationTypes, setClassificationTypes] = useState([]);
    const [isClassificationTypesLoaded, setIsClassificationTypesLoaded] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isCustomersLoaded, setIsCustomersLoaded] = useState(false);
    const [operators, setOperators] = useState([]);
    const [isOperatorsLoaded, setIsOperatorsLoaded] = useState(false);

    // Services
    const dispatchStore = useDispatch();

    // Handles audit trail refresh
    const [isAuditTrailRefreshed, setIsAuditTrailRefreshed] = useState(false);
    const auditTrailRefresh = (tf) => setIsAuditTrailRefreshed(tf);

    // Global Component Services
    const [formService] = useState(new PrepressNcrService());
    const formId = props.match.params.prepressNcrId * 1;
    const formTitle = "Prepress NCR";
    const formNavLink = "/prepress-ncrs";

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            const lookupService = new LookupService();
            const classificationTypeService = new EntityService('ClassificationTypes');
            const companyService = new CompanyService();
            const operatorService = new EntityService('Operators');

            fetchForm();

            // Loads statuses
            lookupService.getByType("FormStatus").then(
                response => {
                    const statuses = response.data.results.map(status => {
                        return {
                            id: status.id,
                            name: status.name
                        };
                    });

                    setStatuses(statuses);
                    setIsStatusesLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain statuses', 'Error', 1000);
                }
            );

            // Loads classification Types
            classificationTypeService.getList(1, 1000, "Name", "desc").then(
                response => {
                    const classificationTypes = response.data.results.map(classificationType => {
                        return {
                            id: classificationType.id,
                            name: classificationType.name
                        };
                    });

                    setClassificationTypes(classificationTypes);
                    setIsClassificationTypesLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain classification types', 'Error', 1000);
                }
            );

            // Custom list.
            companyService.getCustomersList(1, 1000, "Name", "asc").then(
                response => {
                    const customers = response.data.results.map(customer => {
                        return {
                            id: customer.id,
                            name: customer.name
                        };
                    });

                    setCustomers(customers);
                    setIsCustomersLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain customers', 'Error', 1000);
                }
            );

            // Loads operators
            operatorService.getList(1, 1000, "Name", "asc").then(
                response => {
                    const operators = response.data.results.map(operator => {
                        return {
                            id: operator.id,
                            name: operator.firstName + ' ' + operator.surname
                        };
                    });

                    setOperators(operators);
                    setIsOperatorsLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain operators', 'Error', 1000);
                }
            );
        }, [formService, formId]);
    }

    // Fetches the prepress ncr
    const fetchForm = () => {
        formService.getById(formId).then(
            response => {
                const form = {
                    ...response.data,
                    formId: response.data.formId * 1,
                    statusId: response.data.status.id * 1,
                    classificationTypeId: response.data.classificationType.id * 1,
                    // Custom props.
                    customerId: response.data.customer.id * 1,
                    operatorId: response.data.operator === null ? 0 : response.data.operator.id * 1
                }
                setForm(form);
                setIsFormLoaded(true);
                dispatchStore(refreshNavigation());
            },
            error => {
                NotificationManager.error('Could not obtain specified non-conformance report', 'Error', 1000);
            }
        )
    };

    useEffect(() => {
        if (isAuditTrailRefreshed === true) {
            fetchForm();
            setIsAuditTrailRefreshed(false);            
        }     
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuditTrailRefreshed])

    // Updates the form (PATCH request)
    const updateForm = (patchedFields) => {
        var model = [];
        const fields = Object.keys(patchedFields);
        const prevFields = Object.keys(form).filter(f => fields.includes(f)); // Filtered fields

        for (const entry of fields.entries()) {
            const key = entry[1]; // Skip index and take key value
            const prevKey = prevFields.filter(f => f.includes(key));
            const value = patchedFields[key];
            const prevValue = form[prevKey];

            if (value !== prevValue) {
                var json = {
                    "op": "replace",
                    "path": "/" + key,
                    "value": value
                };
                model.push(json);
            }
        }

        formService.patch(form.id, model).then(
            response => {
                fetchForm();
                auditTrailRefresh(true);
                NotificationManager.success(form.ncrLogNo + ' was updated.', 'Updated', 1000); // make CCR Log No
            },
            error => {
                NotificationManager.error('The NCR was not updated.', 'Error', 1000);
            }
        );
    };


    //  ===============================================================================================================

    const isLoaded = isFormLoaded && isStatusesLoaded && isClassificationTypesLoaded && isCustomersLoaded && isOperatorsLoaded;
    const title = isLoaded ? form.ncrLogNo : "Non-Conformance Report";

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <>
            <NavLink to={formNavLink} className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to {formTitle}s List
            </NavLink>

            <FormContacts
                formId={formId}
                formService={formService}
                auditTrailRefresh={auditTrailRefresh}
            />

            <PdfDownload
                formId={formId}
                formService={formService}
                title={formTitle}
                status={form.status}
                logNo={form.ncrLogNo}
                summary={
                    <>
                        <PdfTable
                            headers={["NCR Log No", "Resolved by Contractor Repair", "Date Contractor Arrived", "Operator Responsible"]}
                            cells={[
                                form.ncrLogNo,
                                form.isResolvedByContractorRepair === true ? "Yes" : "No",
                                form.dateContractorArrived != null ? moment(form.dateContractorArrived).format('DD/MM/YYYY') : "N/A",
                                form.operator != null ? form.operator.firstName + ' ' + form.operator.surname : "N/A"
                            ]}
                        />
                        <PdfTable
                            headers={["Description of Issue"]}
                            cells={[form.description]}
                        />
                    </>
                }
                jobDetails={
                    <PdfTable
                        headers={["Customer", "SKU", "Work Ticket / Batch No", "Quantity"]}
                        cells={[form.customer != null ? form.customer.name : "N/A", form.stockCode, form.workTicketNo, form.quantity]}
                    />
                }
            />
        </>
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading " + formTitle + "..."
    };

    return (
        <Screen title={title} menu={screenMenu} loader={screenLoader}>
            <Form
                title={formTitle}
                status={form.status}
                formId={formId}
                formService={formService}
                data={form}
                isAuditTrailRefreshed={isAuditTrailRefreshed}
                auditTrailRefresh={auditTrailRefresh}
            >
                <FormSummary
                    title={formTitle}
                status={form.status}
                    render={
                        <>
                            <FormTable
                                headers={["NCR Log No", "Resolved by Contractor Repair", "Date Contractor Arrived", "Operator Responsible"]}
                                cells={[
                                    form.ncrLogNo,
                                    form.isResolvedByContractorRepair === true ? "Yes" : "No",
                                    form.dateContractorArrived != null ? moment(form.dateContractorArrived).format('DD/MM/YYYY') : "N/A",
                                    form.operator != null ? form.operator.firstName + ' ' + form.operator.surname : "N/A"
                                ]}
                            />
                            <FormTable
                                headers={["Description of Issue"]}
                                cells={[form.description]}
                            />
                        </>
                    }
                    modal={
                        <PrepressNcrEditSummaryForm
                            data={form}
                            handleSave={updateForm}
                            statusOptions={statuses}
                            classificationTypeOptions={classificationTypes}
                            operatorOptions={operators}
                        />
                    }
                />
                <FormJobDetails
                    render={
                        <FormTable
                            headers={["Customer", "SKU", "Work Ticket / Batch No", "Quantity"]}
                            cells={[form.customer != null ? form.customer.name : "N/A", form.stockCode, form.workTicketNo, form.quantity]}
                        />
                    }
                    modal={
                        <PrepressNcrEditJobDetailsForm
                            data={form}
                            handleSave={updateForm}
                            
                            customersOptions={customers}
                        />
                    }
                />
            </Form>
        </Screen>
    );
}



