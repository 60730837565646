import React, { useState } from 'react';
import * as Icon from 'react-feather';
import classnames from 'classnames';
import { Row, Col, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { OverviewBadge } from '../Badges/OverviewBadge';
import { FollowUps } from '../FollowUp/FollowUps';
import { AuditTrailList } from './AuditTrailList';
import { FormTabStatus } from './FormTabStatus';
import { RootCause } from '../RootCause/RootCause';
import { ActionTaken } from '../ActionTaken/ActionTaken';
import { NoteList } from '../Notes/NoteList';

export const Form = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [hasRootCause, setHasRootCause] = useState(false);
    const [hasActionTaken, setHasActionTaken] = useState(false);
    const [hasFollowUps, setHasFollowUps] = useState(false);

    const handleToggle = (tab) => { setActiveTab(tab); }
    const handleRootCauseStatus = (tf) => { setHasRootCause(tf) }
    const handleActionTakenStatus = (tf) => { setHasActionTaken(tf) }
    const handleFollowUpsStatus = (tf) => { setHasFollowUps(tf) }

    return (
        <>
            <Row>
                <Col sm="8" style={{"marginBottom":"15px"}}>
                    <ListGroup>
                        <ListGroupItem active>
                            <ListGroupItemHeading>
                                {props.title}
                                <OverviewBadge
                                    status={props.data.status}
                                    classificationType={props.data.classificationType}
                                    isClosedOut={props.data.isClosedOut}
                                />
                            </ListGroupItemHeading>
                        </ListGroupItem>
                        {props.children}
                        <ListGroupItem>
                            <ListGroupItemHeading> Procedures </ListGroupItemHeading>
                            <ListGroupItemText>Highlights the key procedures that should be carried out when investigating the {props.title}.</ListGroupItemText>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { handleToggle('1'); }}>
                                        <FormTabStatus
                                            title="Root Cause"
                                            status={hasRootCause}
                                        />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { handleToggle('2'); }}>
                                        <FormTabStatus
                                            title="Corrective/Preventive Action"
                                            status={hasActionTaken}
                                        />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { handleToggle('3'); }}>
                                        <FormTabStatus
                                            title="Follow Ups"
                                            status={hasFollowUps}
                                        />
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: activeTab === '4' })} onClick={() => { handleToggle('4'); }}>
                                        <Icon.List className="feather" />&nbsp; Audit Trail
                                     </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <RootCause
                                        formId={props.formId}
                                        formService={props.formService}
                                        hasRootCause={handleRootCauseStatus}
                                        auditTrailRefresh={props.auditTrailRefresh}
                                    />
                                </TabPane>
                                <TabPane tabId="2">
                                    <ActionTaken
                                        formId={props.formId}
                                        formService={props.formService}
                                        hasActionTaken={handleActionTakenStatus}
                                        auditTrailRefresh={props.auditTrailRefresh}
                                    />
                                </TabPane>
                                <TabPane tabId="3">
                                    <FollowUps
                                        formId={props.formId}
                                        formService={props.formService}
                                        hasFollowUps={handleFollowUpsStatus}
                                        auditTrailRefresh={props.auditTrailRefresh}
                                    />
                                </TabPane>
                                <TabPane tabId="4">
                                    <AuditTrailList
                                        formId={props.formId}
                                        formService={props.formService} 
                                        isAuditTrailRefreshed={props.isAuditTrailRefreshed}
                                        auditTrailRefresh={props.auditTrailRefresh}
                                    />
                                </TabPane>
                            </TabContent>
                        </ListGroupItem>
                    </ListGroup>
                </Col>
                <Col sm="4">
                    <NoteList
                        formId={props.formId}
                        formService={props.formService}
                        auditTrailRefresh={props.auditTrailRefresh}
                    />
                </Col>
            </Row>
        </>
    );
}