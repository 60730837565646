import React, { useState, useEffect} from 'react';
import { Badge } from 'reactstrap';

export const StatusBadge = props => {
    const [color, setColor] = useState("primary");
    const type = props.type !== undefined ? props.type.name : "";

    useEffect(() => {
        var color = "";
        switch (type) {
            case "Open":
                color = "danger";
                break;
            case "Under Review":
                color = "warning";
                break;
            case "Further Action Required":
                color = "warning";
                break;
            case "Resolved":
                color = "success";
                break;
            case "Resolved (External Factor)":
                color = "success";
                break;
            default: 
                color="secondary"
                break;
        }

        setColor(color);
        
    }, [type]);


    return (
        <Badge color={color} pill>{type}</Badge>
    );
}