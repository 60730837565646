import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { TenantForm } from './Form';
import { EntityService } from '../../services/EntityService';
import { UserService } from '../../services/UserService';

export class EditTenantContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            users: [],
            isUsersLoaded: false,
            tenant: {},
            isTenantLoaded: false
        };

        this.TenantService = new EntityService("Tenants");
        this.UserService = new UserService();

        this.updateTenant = this.updateTenant.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.tenantId = this.props.match.params.tenantId * 1;

    }

    componentDidMount() {
        this.TenantService.getById(this.tenantId).then(
            response => {
                const tenant = {
                    ...response.data
                }
                this.setState({
                    tenant: tenant,
                    isTenantLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified organisation', 'Error', 1000);
            }
        );

        this.UserService.getOwners(1, 1000, "UserName", "asc").then(
            response => {
                const users = response.data.results.map(user => {
                    return {
                        id: user.id,
                        name: user.firstName + ' ' + user.surname
                    };
                });
                this.setState({
                    users: users,
                    isUsersLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain users', 'Error', 1000);
            }
        );
    }

    updateTenant(tenant) {
        var model = {
            ...tenant
        };

        this.TenantService.update(this.tenantId, model).then(
            response => {
                // Alert the client that the tenant has been updated
                NotificationManager.success('The tenant ' + tenant.name + ' was updated.', 'Organisation Updated', 1000);
            },
            error => {
                // Alert the client that the tenant has NOT been updated
                NotificationManager.error('Organisation was not updated', 'Error', 1000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the UserForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.tenantForm && this.tenantForm.handleSubmit) {
            const form = this.tenantForm.state.form
            if (form.isValid) {
                this.tenantForm.handleSubmit(e);
            }
            else {
                this.tenantForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 1000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isTenantLoaded && this.state.isUsersLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/organisations" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Organisations List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit Organisation Form..."
        };

        return (
            <Screen title="Edit Organisation" menu={screenMenu} loader={screenLoader}>
                <TenantForm
                    ref={(el) => this.tenantForm = el}
                    data={this.state.tenant}
                    handleSave={this.updateTenant}
                    
                    ownerOptions={this.state.users}
                />
            </Screen>
        );
    }
}