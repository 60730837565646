import React, { useState, useEffect} from 'react';
import { Badge } from 'reactstrap';

export const ClassificationTypeBadge = props => {
    const [color, setColor] = useState("primary");
    const type = props.type !== undefined ? props.type.name : "";

    useEffect(() => {
        var color = "";
        switch (type) {
            case "Minor":
                color = "secondary";
                break;
            case "Major":
                color = "warning";
                break;
            case "Critical":
                color = "danger";
                break;
            default:
                color = "primary";
        }

        setColor(color);
        
    }, [type]);


    return (
        <Badge color={color} pill>{type}</Badge>
    );
}