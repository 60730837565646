import React from 'react';
import { LookupsList } from './LookupsList';

export const LookupsListContainer = () => {
    const items = [
        { id: 1, name: 'Companies', path: 'companies', area: 'Management' },
        { id: 2, name: 'Operators',  path: 'operators', area: 'Management' },
        { id: 3, name: 'Classification Types', path: 'classification-types', area: 'Management' },
        ];

    return <LookupsList items={items} />;
};
