import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteDespatchNcrModal } from './ConfirmDeleteModal';
import { Can } from '../RBAC/Can';
import { PdfActionItem } from '../FormComponents/Pdf/PdfActionItem';
import { DespatchNcrService } from '../../services/DespatchNcrService';
import { PdfTable } from '../FormComponents/Pdf/PdfTable';

export const DespatchNcrActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleConfirmDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen
        }));        
    };

    const handleDeleteConfirmed = (entity, e) => {
        props.handleDelete(entity, e);
    };

    const formTitle = "Despatch NCR";
    const [formService] = useState(new DespatchNcrService());
    const form = props.despatchNcr;
    const formId = form.formId * 1;

    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleView(props.despatchNcr, e)}> <Icon.FileText className="feather" />&nbsp; View</DropdownItem>
                        <DropdownItem divider />
                        
                        <PdfActionItem
                            formId={formId}
                            formService={formService}
                            title={formTitle}
                status={form.status}
                            logNo={form.ncrLogNo}
                            summary={
                                <PdfTable
                                headers={["NCR Log No", "Operator Responsible", "Description of Issue"]}
                                cells={[
                                    form.ncrLogNo,
                                    form.operator != null ? form.operator.firstName + ' ' + form.operator.surname : "N/A",
                                    form.description
                                ]}
                            />
                            }
                            jobDetails={
                                <PdfTable
                                    headers={["Customer", "SKU", "Work Ticket / Batch No", "Quantity"]}
                                    cells={[form.customer != null ? form.customer.name : "N/A", form.stockCode, form.workTicketNo, form.quantity]}
                                />
                            }
                        />

                        <Can
                            perform="forms:delete"
                            yes={() => (
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => toggleConfirmDeleteModal()}> <Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                                </>
                            )}
                        />  
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <ConfirmDeleteDespatchNcrModal 
                isOpen={state.isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal} 
                handleConfirm={handleDeleteConfirmed}
                despatchNcr={props.despatchNcr}
            />
        </>
    );
}

