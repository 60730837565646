import React from 'react';
import { Form, Input, Label, FormGroup, Button } from 'reactstrap';

export const AddDiscrepancyForm = ({ inputValues, handleInputChange, handleSubmit, categories }) => {
    return (
        <Form>
            <FormGroup>
                <Label for="activityAudited">Activity Audited <span style={{color: 'red'}}>*</span></Label>
                <Input
                    type="text"
                    id="activityAudited"
                    value={inputValues.activityAudited || ''}
                    required
                    onChange={(e) => handleInputChange('activityAudited', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="findings">Findings <span style={{color: 'red'}}>*</span></Label>
                <Input
                    type="textarea"
                    id="findings"
                    required
                    value={inputValues.findings || ''}
                    onChange={(e) => handleInputChange('findings', e.target.value)}
                />
            </FormGroup>
            <Button color="success" type="submit" onClick={handleSubmit}>Save</Button>
        </Form>
    );
};