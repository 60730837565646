import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { GlobalAlertCreateForm } from './CreateForm';
import { GlobalAlertService } from '../../services/GlobalAlertService';
import { CompanyService } from '../../services/CompanyService';
import { LookupService } from '../../services/LookupService';

export class CreateGlobalAlertContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            companies: [],
            isCompaniesLoaded: false,
        };

        this.GlobalAlertService = new GlobalAlertService();
        this.CompanyService = new CompanyService();
        this.LookupService = new LookupService();

        this.createGlobalAlert = this.createGlobalAlert.bind(this);
    }

    componentDidMount() {
        // Loads companies
        this.CompanyService.getList(1, 1000, "Name", "asc").then(
            response => {
                const companies = response.data.results.map(company => {
                    return {
                        ...company
                    };
                });
                this.setState({
                    companies: companies,
                    isCompaniesLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain companies', 'Error', 1000);
            }
        );
    }

    createGlobalAlert(globalAlert) {
        var model = {
            ...globalAlert
        };

        this.GlobalAlertService.create(model).then(
            response => {
                const globalAlert = response.data;
                this.props.history.push('/global-alerts/edit/' + globalAlert.id);
                NotificationManager.success('The global alert was created successfully', 'Global Alert Created');
            },
            response => {
                console.log(response);
                NotificationManager.error('An active global alert already exists for this company', 'Error', 1000);
            },
        );
    }

    render() {
        const isLoaded = this.state.isCompaniesLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/global-alerts" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Global Alerts List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Create Global Alert Form..."
        };

        return (
            <Screen title="Create Global Alert" menu={screenMenu} loader={screenLoader}>
                <GlobalAlertCreateForm
                    ref={(el) => this.globalAlertForm = el}
                    handleSave={this.createGlobalAlert}
                    companyOptions={this.state.companies}                    
                />
            </Screen>
        );
    }
}