import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Alert, Button, Table, Row, Col } from 'reactstrap';
import DataTable from 'react-data-table-component';
import { SimpleLoader } from '../../Common/SimpleLoader';
import { BasicModal } from '../../Modals/Basic';

export function AuditTrailList(props) {
    const [auditTrails, setAuditTrails] = useState([]);
    const [isAuditTrailsLoaded, setIsAuditTrailsLoaded] = useState(false);

    const [prevEntityJson, setPrevEntityJson] = useState("");
    const [currEntityJson, setCurrEntityJson] = useState("");
    const [entityType, setEntityType] = useState("");
    const [action, setAction] = useState("");
    const [dateCreated, setDateCreated] = useState("");

    const [isChangeLogModalOpen, setIsChangeLogModalOpen] = useState(false);
    const toggleIsChangeLogModalOpen = (row) => {
        setAction(row.action != null ? row.action.name : '');
        setEntityType(row.entityType);
        setDateCreated(row.dateCreated)
        setPrevEntityJson(row.prevEntityJson);
        setCurrEntityJson(row.currEntityJson);

        setIsChangeLogModalOpen(!isChangeLogModalOpen)
    };



    // Paging options
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const formService = props.formService;
    const formId = props.formId * 1;

    // Init.
    useInitMount(formService, formId, pageNumber, perPage, props.isAuditTrailRefreshed, props.auditTrailRefresh);
    function useInitMount(formService, formId, pageNumber, perPage, isAuditTrailRefreshed, auditTrailRefresh) {
        useEffect(() => {
            fetchAuditTrail();
        }, [formService, formId]);        

        // Refetch data based on params
        useEffect(() => {
            fetchAuditTrail();
        }, [pageNumber, perPage])

        // Refreshes audit trail
        useEffect(() => {
            if (isAuditTrailRefreshed === true) {
                fetchAuditTrail();
                auditTrailRefresh(false);
            }
        }, [isAuditTrailRefreshed, auditTrailRefresh]);
    }
    
    const handlePageChange = (page) => setPageNumber(page);
    const handlePerRowsChange = (newPerPage) => setPerPage(newPerPage);     

    // Fetch root cause
    const fetchAuditTrail = () => {
        formService.getAuditTrail(formId, pageNumber, perPage, "DateCreated", "desc").then(
            response => {
                const auditTrails = response.data.results.map(auditTrail => {
                    return {
                        ...auditTrail
                    };
                });
                setAuditTrails(auditTrails);
                setIsAuditTrailsLoaded(true);                
                // Sets paging
                setTotalRows(response.data.paging.totalItemCount);               
            },
            error => {
                setAuditTrails([]);
                setIsAuditTrailsLoaded(true);
            }
        )
    };

    // Column Def.
    const columns = [
        {
            name: 'Action',
            selector: 'action.name',
            sortable: true,
            cell: (row) => {
                return <span>{row.action.name} {row.entityType}</span>
            }
        },
        {
            name: 'User',
            selector: 'userId',
            sortable: true,
            cell: (row) => {
                if (row.user != null) {
                    return <span>{row.user.firstName} {row.user.surname}</span>
                }
            }
        },
        {
            name: 'Change Log',
            selector: 'userId',
            sortable: true,
            cell: (row) => {
                return <Button color="primary" size="sm" onClick={() => toggleIsChangeLogModalOpen(row)} style={{ margin: "0px 5px 0px 5px" }}>View</Button>
            }
        },
        {
            name: 'Date Created',
            selector: 'dateCreated',
            sortable: true,
            cell: (row) => {
                return moment(row.dateCreated).format('DD/MM/YYYY hh:mm A')
            }
        },
    ];

    const hasAny = auditTrails && auditTrails.length > 0;
    const isLoaded = isAuditTrailsLoaded;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
        <>
            {
                hasAny ? (
                    <>
                    <DataTable
                        columns={columns}
                        data={auditTrails}
                        pagination
                        paginationServer
                        highlightOnHover
                        noHeader
                        paginationTotalRows={totalRows}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}                        
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}                    
                    />

                            {/* Modal */}
                            <BasicModal
                                isOpen={isChangeLogModalOpen}
                                toggle={toggleIsChangeLogModalOpen}
                                title="Change Log"
                            >

                                <p><strong>{action} {entityType} ({moment(dateCreated).format('DD/MM/YYYY')})</strong></p>
                                <p>Hover over the appropriate column and scroll horizontally to see the full side-by-side comparison.</p>
                                <Row form>
                                    <Col xs="12">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th className="col-xs-4">Previously</th>
                                                    <th className="col-xs-4">Currently</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><DynamicTable data={prevEntityJson}/></td>
                                                    <td><DynamicTable data={currEntityJson}/></td>
                                                </tr>
                                            </tbody>
                                        </Table>                                    
                                    </Col></Row>                               
                            </BasicModal>
                        </>
                ) : <Alert color="warning">No audit trails were found.</Alert>
            }
        </>
        </SimpleLoader>
    );
}

export function DynamicTable (props){
    if (props.data !== undefined) {
        var obj = JSON.parse(props.data);   
        return (<div><pre style={{"overflowX":"auto", "width":"200px"}}>{ JSON.stringify(obj, null, 2) }</pre></div>);
    }  

    return <span>N/A</span>
}