

import React from 'react';
import { Keys } from '../../enums';
import { EncodedStorageService } from '../../services/EncodedStorageService';

export const RoleCondition = ({roles, children}) => {
    const storage = new EncodedStorageService();
    const currentUser = storage.get(Keys.AuthUser);
    
    return (roles && currentUser.roles.some(role => roles.includes(role))) ? (
        <>
            {children}
        </>
    ) : null;
}