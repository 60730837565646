import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import { BasicModal } from '../../Modals/Basic';
import { NotificationManager } from '../../../helpers/NotificationManager';

export const FormContacts = props => {
    const [isEmailAlertsEnabled, setIsEmailAlertsEnabled] = useState(false);
    const [isFormLoaded, setIsFormLoaded] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [isContactsLoaded, setIsContactsLoaded] = useState(false);
    const [potentialContacts, setPotentialContacts] = useState([]);
    const [isPotentialContactsLoaded, setIsPotentialContactsLoaded] = useState(false);
    const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);

    const formService = props.formService;
    const formId = props.formId * 1;

    // Methods
    const toggleFormContactsModal = () => setIsContactsModalOpen(!isContactsModalOpen);
    const handleSelectedContact = (contact) => setSelectedContact(contact);

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            fetchContacts();
        }, [formService, formId]);
    }

    // Fetch contacts
    const fetchContacts = () => {
        formService.getById(formId).then(
            response => {
                setIsEmailAlertsEnabled(response.data.isEmailAlertsEnabled);
                setIsFormLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain specified non-conformance report', 'Error', 1000);
            }
        )
        
        formService.getContacts(formId).then(
            response => {
                const contacts = response.data.results.map(contact => {
                    return { ...contact };
                });
                setContacts(contacts);
                setIsContactsLoaded(true);

                formService.getPotentialContacts(formId).then(
                    response => {
                        const potentialContacts = response.data.results.map(potentialContact => {
                            return { ...potentialContact };
                        });
                        setPotentialContacts(potentialContacts);
                        setIsPotentialContactsLoaded(true);
                    },
                    error => {
                        NotificationManager.error('Could not obtain potential contacts', 'Error', 1000);
                    }
                );
            },
            error => {
                NotificationManager.error('Could not obtain contacts', 'Error', 1000);
            }
        )
    };

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.name === 'isEmailAlertsEnabled' ? target.checked : target.value;

        setIsEmailAlertsEnabled(value);
    }

    //  ===============================================================================================================

    const addContact = (contact) => {
        if (contact != null) {
            const isInPotentialContacts = potentialContacts.some(item => item.email === contact.email);
            if (isInPotentialContacts) {
                setPotentialContacts(potentialContacts.filter(x => x.email !== contact.email));
                setContacts([...contacts, contact]);
            }
            setSelectedContact(null);
        }
    };

    const removeContact = (contact) => {
        if (contact != null) {
            const isInPotentialContacts = potentialContacts.some(item => item.email === contact.email);
            if (!isInPotentialContacts) {
                setPotentialContacts([...potentialContacts, contact]);
                setContacts(contacts.filter(x => x.email !== contact.email));
            }
            setSelectedContact(null);
        }
    };

    const handleSave = (contacts) => {
        var model = { isEmailAlertsEnabled: isEmailAlertsEnabled, contacts: [...contacts] };
        formService.updateContacts(formId, model).then(
            response => {
                fetchContacts();
                props.auditTrailRefresh(true);
                NotificationManager.success('The contacts were updated.', 'Updated', 1000);

            },
            error => {
                NotificationManager.error('The contacts were not updated.', 'Error', 1000);
            }
        );
    }

    const isLoaded = isContactsLoaded && isPotentialContactsLoaded && isFormLoaded;

    return (
        <>
            <Button className="btn btn-sm btn-success" style={{ marginLeft: 5 }} onClick={toggleFormContactsModal} title="Contact">
                <Icon.Users className="feather" />
            </Button>

            <BasicModal
                isOpen={isContactsModalOpen}
                toggle={toggleFormContactsModal}
                title="Contacts"
                isLoaded={isLoaded}>       
                <Row>
                    <Col sm="12">
                        <FormGroup>
                            <Label>
                                Is email alerts enabled?
                                <input style={{"marginLeft":"5px"}}
                                    name="isEmailAlertsEnabled"
                                    type="checkbox"
                                    checked={isEmailAlertsEnabled}
                                    onChange={handleInputChange} 
                                />
                            </Label>                         
                        </FormGroup>
                    </Col>
                    </Row>             
                <Row>
                    <Col sm="5">
                        <FormGroup>
                            <Label>Potential Contacts*</Label>
                            <Input type="select" multiple>
                                {
                                    potentialContacts.map((item, index) => {
                                        var isUser = item.isUser === true ? '' : '(Customer/Suppler)';
                                        return (
                                            <option key={index} onClick={() => handleSelectedContact(item)}>{item.firstName} {item.surname} {isUser}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm="2">
                        <Button className="addContactBtn" color="secondary" onClick={() => addContact(selectedContact)}><Icon.ChevronRight className="feather" /></Button>
                        <Button className="removeContactBtn" color="secondary" onClick={() => removeContact(selectedContact)}><Icon.ChevronLeft className="feather" /></Button>
                    </Col>
                    <Col sm="5">
                        <FormGroup>
                            <Label>Actual Contacts*</Label>
                            <Input type="select" multiple >
                                {
                                    contacts.map((item, index) => {
                                        var isUser = item.isUser === true ? '' : '(Customer/Suppler)';
                                        return (
                                            <option key={index} onClick={() => handleSelectedContact(item)}>{item.firstName} {item.surname} {isUser}</option>
                                        );
                                    })
                                }
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col sm="12">
                        <div className="float-left">
                            <Button color="success" onClick={(e) => handleSave(contacts)}>
                                Submit
                        </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <br/>
                        <p>* Select ONE contact at a time.</p>
                    </Col>
                </Row>
            </BasicModal>
        </>
    );
}