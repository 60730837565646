const StringHelper = {
    getBoolean: function(value) {
        return (value && value.toLowerCase() === 'true') ? true : false;
    },
    getString: function(value, defaultValue) {
        if(value && typeof value === 'string' && value.length > 0) return value;
        return defaultValue;
    },
    ensureString: function(value) {
        if(typeof value === 'string') return value;
        return (!value) ? '' : value.toString();
    },
    getIntArray: function(value) {
        var valuesArr = [];          
        value.forEach(item => {
            valuesArr.push(item.id);
        });
        return valuesArr;              
    },
    getArray: function(value) {
        var valuesArr = [];          
        value.forEach(item => {
            valuesArr.push(item);
        });
        return valuesArr; 
    }
    
}

export default StringHelper;