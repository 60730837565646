import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { AddSharpForm } from './AddSharpForm';
import { SharpService } from '../../services/SharpService';
import { ZoneService } from "../../services";
import { EntityService } from '../../services/EntityService';

export const AddSharpContainer = () => {
    const history = useHistory();
    const sharpForm = useRef();

    // Services
    const [sharpService] = useState(new SharpService());
    const [zoneService] = useState(new ZoneService());
    const [operatorService] = useState(new EntityService('Operators'));

    const [zones, setZones] = useState([]);
    const [operators, setOperators] = useState([]);
    const [types, setTypes] = useState([]);

    // Load initial data
    useEffect(() => {
        // Zones
        zoneService.getZones(1, 1000, 'Name', 'asc').then(
            response => {
                const zones = response.data.map(zone => ({ ...zone }));
                setZones(zones);
            },
            () => {
                NotificationManager.error('Could not obtain results for Zones', 'Error', 1000);
            }
        );

        // Operators
        operatorService.getList(1, 1000, 'FirstName', 'asc').then(
            response => {
                const operators = response.data.results.map(operator =>  ({
                    id: operator.id,
                    name: operator.firstName + ' ' + operator.surname
                }));
                setOperators(operators);
            },
            () => {
                NotificationManager.error('Could not obtain results for Operators', 'Error', 1000);
            }
        );
        
        // Types
        sharpService.getTypes().then(
            response => {
                const types = response.data.map(type => ({ ...type }));
                setTypes(types);
            },
            () => {
                NotificationManager.error('Could not obtain results for Types', 'Error', 1000);
            });        
        
    }, [zoneService, operatorService, sharpService]);
    
    const addSharp = (sharp) => {
        var model = {
            ...sharp,            
        };

        sharpService.createSharp(model).then(
            response => {
                const sharp = response.data.content;
                history.push('/sharps/edit/' + sharp.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                const errors = error.response.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            },
        );
    }

    // Build the Screen menu.
    const screenMenu = (
        <>
            <NavLink to="/sharps" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Sharps List
            </NavLink>
        </>
    );    
    
    const isLoaded = zones.length > 0 && operators.length > 0;

    // Define the setting for the screen loader
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading..."
    };

    return (
        
        <Screen title="Assign Sharp" menu={screenMenu} loader={screenLoader}>
            <AddSharpForm
                ref={sharpForm}
                handleSave={addSharp}
                zones={zones}
                operators={operators}
                types={types}
                readonly={false}
            />
        </Screen>
    );
}