import React from 'react';
import { Badge } from 'reactstrap';
import { StatusBadge } from './StatusBadge';
import { ClassificationTypeBadge } from './ClassificationTypeBadge';

export function OverviewBadge(props) {
    var isClosedOut = props.isClosedOut === true ? 'inline' : 'none'

    return (
        <div className="OverviewBadge">
            <StatusBadge type={props.status} />
            <ClassificationTypeBadge type={props.classificationType}/>
            <Badge style={{ display: isClosedOut }} color="success" pill>Closed</Badge>
        </div>
    ); 
}