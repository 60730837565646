import axios from 'axios';
import { EntityService } from './EntityService';

export class DashboardService extends EntityService {
    constructor() {
        super('Dashboard');
    }

    getFormsCount() {
        const endpoint = this.baseUrl + "GetAllUnresolvedForms/";
        return axios.get(endpoint);     
    }

    getTenantForms() {
        const endpoint = this.baseUrl + "GetTenantForms/";
        return axios.get(endpoint);
    }

    getChartData() {
        const endpoint = this.baseUrl + "ChartData/";
        return axios.get(endpoint);
    }

    getTotalCCRs() {
        const endpoint = this.baseUrl + "TotalCCRs/";
        return axios.get(endpoint);
    }

    getTotalNCRs() {
        const endpoint = this.baseUrl + "TotalNCRs/";
        return axios.get(endpoint);
    }   
}