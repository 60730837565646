import React from 'react';
import moment from 'moment';
import { NavLink } from "react-router-dom";
import DataTable from 'react-data-table-component';
import { Alert } from 'reactstrap';
import { DashboardActionsButton } from './ActionsButton';
import { StatusBadge } from '../FormComponents/Badges/StatusBadge';

export function DashboardFormsList(props) {
    const columns = [
        {
            name: 'Log No',
            selector: 'logNo',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={row.url}>{row.logNo}</NavLink>
            }
        },       
        {
            name: 'Status',
            selector: 'status.name',
            sortable: true,
            cell: (row) => {
                return (
                    <StatusBadge 
                    type={row.status} />
                );
            }
        },
        {
            name: 'Type',
            selector: 'formType',
            sortable: true,
            cell: (row) => {
                return <span>{row.formType}</span>
            }
        },
        {
            name: 'Date Raised',
            selector: 'dateCreated',
            sortable: true,
            cell: (row) => {
                return moment(row.dateCreated).format('DD/MM/YYYY')
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <DashboardActionsButton
                        form={row}
                        handleView={props.handleView}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}                        
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No forms were found</Alert>
            }
        </>
    );
}

