import React, { useState, useEffect } from 'react';
import { TemplateService } from '../../services/TemplateService';
import { EditTemplateForm } from './EditTemplateForm';
import { NotificationManager } from '../../helpers/NotificationManager';
import  { useHistory } from "react-router-dom";
import { ZoneService } from '../../services/ZoneService';

export const EditTemplateFormContainer = (props) => {
    const [templateService] = useState(new TemplateService());
    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [description, setDescription] = useState('');
    const [categories, setCategories] = useState([]);
    const templateId = props.match.params.templateId * 1;
    const history = useHistory();

    const [state, setState] = useState({
        zones: [],
        isZonesLoaded: false,
    });

    // Zone Service
    const [zoneService] = useState(new ZoneService());

   // Load initial data
useEffect(() => {
    const handleResponse = (response) => {
        const zones = response.data.map(zone => {
            return {
                value: zone.id,
                label: zone.name
            };
        });
        setState(prevState => {
            return {
                ...prevState,
                zones: zones,
                isZonesLoaded: true,
            };
        });
    };

    const handleError = () => {
        NotificationManager.error('Could not obtain results for Zones', 'Error', 1000);
    };

    zoneService.getZones(1, 1000, 'Name', 'asc').then(handleResponse, handleError); // Use getZones method

}, [zoneService]);

    useEffect(() => {
        const loadTemplate = async () => {
            const response = await templateService.getTemplate(templateId);
            const template = response.data;

            setName(template.name);
            setShortName(template.shortName);
            setDescription(template.summary);
    
            const categoriesMap = template.questions.reduce((acc, question) => {
                if (!acc[question.categoryName]) {
                    acc[question.categoryName] = {
                        id: question.id,
                        categoryName: question.categoryName,
                        questions: []
                    };
                }

            
                acc[question.categoryName].questions.push({
                    id: question.id,
                    text: question.text,
                    sortOrder: question.sortOrder,
                    zoneIds: question.zoneIds
                });
                return acc;
            }, {});
    
            const categoriesArray = Object.values(categoriesMap);
            setCategories(categoriesArray);
        };
        loadTemplate();
    }, [templateId, templateService]);
    
    const handleZoneChange = (categoryId, questionId, selectedZones) => {
        setCategories(categories.map(category => {
            if (category.id === categoryId) {
                category.questions = category.questions.map(question => {
                    if (question.id === questionId) {
                        question.zoneIds = selectedZones.map(zone => zone.value);
                    }
                    return question;
                });
            }
            return category;
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const questions = categories.flatMap(category => category.questions.map(question => ({
            category: category.categoryName,
            question: question.text,
            sortOrder: question.sortOrder,
            zoneIds: question.zoneIds
        })));
    
        const templateInput = {
            id: templateId,
            name,
            shortName,
            description,
            questions
        };

        templateService.editTemplate(templateInput).then(
            response => {
                const template = response.data.content;
                history.push('/templates/edit/' + template.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            response => {
                const errors = response.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            },
        );
    };

    const addCategory = () => {
        setCategories([...categories, { id: categories.length + 1, categoryName: '', questions: [] }]);
    };

    const addQuestion = (categoryId) => {
        setCategories(categories.map(category => {
            if (category.id === categoryId) {
                const newQuestion = { id: category.questions.length + 1, text: '', sortOrder: category.questions.length };
                category.questions.push(newQuestion);
            }
            return category;
        }));
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;
    
        if (!destination) {
            return;
        }
    
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
    
        setCategories(prev => {
            const newCategories = [...prev];
            const sourceCategory = newCategories.find(category => `droppable${category.id}` === source.droppableId);
            const destinationCategory = newCategories.find(category => `droppable${category.id}` === destination.droppableId);
            const [removed] = sourceCategory.questions.splice(source.index, 1);
    
            if (sourceCategory === destinationCategory) {
                sourceCategory.questions.splice(destination.index, 0, removed);
                // Update sortOrder for questions in the sourceCategory
                sourceCategory.questions.forEach((question, index) => {
                    question.sortOrder = index;
                });
            } else {
                destinationCategory.questions.splice(destination.index, 0, removed);
                // Update sortOrder for questions in both the sourceCategory and destinationCategory
                sourceCategory.questions.forEach((question, index) => {
                    question.sortOrder = index;
                });
                destinationCategory.questions.forEach((question, index) => {
                    question.sortOrder = index;
                });
            }
    
            return newCategories;
        });
    };

    const deleteCategory = (categoryId) => {
        setCategories(categories.filter(category => category.id !== categoryId));
    };
    
    const deleteQuestion = (categoryId, questionId) => {
        setCategories(categories.map(category => {
            if (category.id === categoryId) {
                category.questions = category.questions.filter(question => question.id !== questionId);
                // Update sortOrder for remaining questions in the category
                category.questions.forEach((question, index) => {
                    question.sortOrder = index;
                });
            }
            return category;
        }));
    };

    const handleQuestionChange = (categoryId, questionId, event) => {
        setCategories(categories.map(category => {
            if (category.id === categoryId) {
                category.questions = category.questions.map(question => {
                    if (question.id === questionId) {
                        question[event.target.name] = event.target.value;
                    }
                    return question;
                });
            }
            return category;
        }));
    };

    const handleCategoryChange = (categoryId, event) => {
        setCategories(categories.map(category => {
            if (category.id === categoryId) {
                category.categoryName = event.target.value;
            }
            return category;
        }));
    };

    return (
        <EditTemplateForm
            name={name}
            setName={setName}
            shortName={shortName}
            setShortName={setShortName}
            description={description}
            setDescription={setDescription}
            categories={categories}
            addCategory={addCategory}
            addQuestion={addQuestion}
            deleteQuestion={deleteQuestion}
            handleQuestionChange={handleQuestionChange}
            handleCategoryChange={handleCategoryChange}
            handleSubmit={handleSubmit}
            setCategories={setCategories}
            onDragEnd={onDragEnd}
            deleteCategory={deleteCategory}
            zones={state.zones}
            handleZoneChange={handleZoneChange}
        />
    );
};