import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';

export const DashboardActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };
    
    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleView(props.form, e)}> <Icon.FileText className="feather" />&nbsp; View</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        </>
    );
}

