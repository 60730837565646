
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import font from '../../fonts/HelveticaNeue.ttf';
import fontSB from '../../fonts/HelveticaNeue-Medium.ttf';
import { Page, View, Text, Font, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import {SharpService} from "../../services/SharpService";
import {  Button }  from 'reactstrap';
import {NotificationManager} from "../../helpers/NotificationManager";
import Logo from '../../graphics/logo-no-text-transparent.png';
export const SharpAuditReportPdf = (props) => {
    const [report, setReport] = useState({
        name: '',
        auditor: '',
        reference: '',
        description: '',
        sharps: [],
        status: '',
        loaded: false
    });

    const [zoneSharps, setZoneSharps] = useState({
        sharps: [],
        isSharpsLoaded: false,
    });

    const [sharpService] = useState(new SharpService());
    const reportId = props.reportId;

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetching the audit report
                const auditResponse = await sharpService.getAuditReport(reportId);
                const { name, zone, reference, description, sharps, status, completedOnUtc, assessorName } = auditResponse.data;

                // Setting report data
                setReport({
                    name,
                    zone,
                    reference,
                    description,
                    sharps,
                    status,
                    completedOnUtc: completedOnUtc,
                    auditor: assessorName,
                    isLoaded: true,
                });

                // Fetching sharps based on zoneId
                const sharpsResponse = await sharpService.getSharps(1, 1000, 'Type', 'asc', '', null, zone.id);
                const sharpsData = sharpsResponse.data.map(sharpItem => ({
                    ...sharpItem
                }));

                // Updating zoneSharps state and marking sharps as loaded
                setZoneSharps(prevState => ({
                    ...prevState,
                    sharps: sharpsData,
                    isSharpsLoaded: true
                }));

            } catch (error) {
                console.error("Failed to load data:", error);
                NotificationManager.error('Could not obtain results for Sharps', 'Error', 1000);
            }
        };

        fetchData();
    }, [reportId, sharpService]);

    const isLoaded = report.isLoaded && zoneSharps.isSharpsLoaded;

    // PDF 
    const fileName = report.reference + "_" + moment(new Date()).format('DD-MM-YYYY_HH-mmA') + ".pdf";
    const generatePdfDocument = async () => {
        const blob = await pdf((
            <SharpPdfDocument report={report} zoneSharps={zoneSharps} />
        )).toBlob();
        saveAs(blob, fileName);
    }

    return (isLoaded ?
            <Button onClick={generatePdfDocument} size="sm" color="success"><Icon.Download className="feather" /></Button> :
            <Button size="sm" color="success"><Icon.Download className="feather" /></Button>
    )
}


// Create Document Component
export const SharpPdfDocument = (props) => {
    const dateCreated = moment(new Date()).format('DD/MM/YYYY @ hh:mm A')
    const isDocument = props.isDocument ?? true;
    
    // Register fonts
    Font.register({
        family: 'Helvetica Neue LT Std',
        fonts: [
            { src: font },
            { src: fontSB, fontStyle: 'semibold' },
        ],
    });
    
    // Updated styles
    const styles = StyleSheet.create({
        logo: {
            width: 50,
            height: 50,
            marginBottom: 20,
            alignSelf: 'center',
            resizeMode: 'contain'
        },
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
            fontFamily: 'Helvetica Neue LT Std',
        },
        title: {
            fontSize: 22,
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'semibold',
            marginBottom: 15,
            color: '#2c3e50',
            textAlign: 'left',
        },
        header: {
            fontSize: 10,
            color: '#7f8c8d',
            marginBottom: 15,
            textAlign: 'center',
        },
        containerTitle: {
            color: '#ffffff',
            fontSize: 16,
            fontFamily: 'Helvetica Neue LT Std',
            textAlign: 'left',
            fontWeight: 'semibold',
        },
        contentBox: {
            padding: 15,
            backgroundColor: '#ffffff',
            marginBottom: 20,
            border: '1px solid black', // Black border around table
        },
        table: {
            display: "table",
            width: "auto",
            borderStyle: 'solid',
            borderColor: '#e0e0e0', // Match category background color
            borderWidth: 1,
        },
        tableRow: {
            flexDirection: "row"
        },
        tableCol: {
            width: "50%",
            borderStyle: 'solid',
            borderColor: '#e0e0e0', // Match category background color
            borderWidth: 1,
        },
        tableCell: {
            padding: 10, // Increase padding
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std'
        },
        subtitle: {
            fontSize: 12,
            fontFamily: 'Helvetica Neue LT Std',
            color: '#000000',
            backgroundColor: '#e0e0e0',
            marginBottom: 5,
            textAlign: 'left',
            padding: 10,
            paddingLeft: 10,
            textTransform: 'none',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            marginTop: 20,
        },
        question: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'bold',
            margin: 5,
        },
        answer: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            margin: 5,
            border: '1px solid #ccc',
            padding: 5,
            borderRadius: 4,
            backgroundColor: '#f9f9f9',
            minHeight: 50,
        },
        comments: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            margin: 5,
            border: '1px solid #ccc',
            padding: 5,
            borderRadius: 4,
            backgroundColor: '#f9f9f9',
            minHeight: 50,
            width: '80%', // Set width to 80%
            marginRight: 0
        },
        complianceContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: 5,
        },
        complianceColumn: {
            width: '10%', // Set width to 10%
            textAlign: 'center',
            backgroundColor: '#f9f9f9', // Grey background
            border: '1px solid #ccc',
            padding: 5,
            borderRadius: 4,
            minHeight: 50,
            marginLeft: 5,
        },
        complianceLabel: {
            fontSize: 11,
            fontFamily: 'Helvetica Neue LT Std',
            fontWeight: 'bold',
            marginRight: 5,
        },
        complianceValue: {
            fontSize: 10,
            fontFamily: 'Helvetica Neue LT Std',
            color: '#000000', // Remove red text
        },
        hr: {
            borderBottom: '1px solid #e0e0e0', // Match category background color
            marginVertical: 10,
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 50,
            right: 0,
            textAlign: 'left',
            color: '#7f8c8d',
        },
        dateCreated: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 0,
            right: 50,
            textAlign: 'right',
            color: '#7f8c8d',
        },
        questionContainer: {
            marginTop: 20, // Increase margin between bottom of table and questions
        },
        explanatoryText: {
            fontSize: 10,
            color: '#7f8c8d',
            marginTop: 5,
        },
    });
    

    const openOnUtcFormatted = moment(props.report.openOnUtc).format('DD/MM/YYYY');
    const completedOnUtcFormatted = props.report.completedOnUtc !== null ? moment(props.report.completedOnUtc).format('DD/MM/YYYY') : 'N/A';
    
    const groupedSharps = (props.zoneSharps && Array.isArray(props.zoneSharps.sharps)) ?
        props.zoneSharps.sharps.reduce((acc, sh) => {
            acc[sh.type.name] = acc[sh.type.name] || [];
            const sharpIds = new Set(acc[sh.type.name].map(item => item.id));
            if (!sharpIds.has(sh.id)) {
                acc[sh.type.name].push(sh);
            }
            return acc;
        }, {}) : {};

    return (
        isDocument ? (
            <Document>
                <Page style={styles.body}>
                    <Image style={styles.logo} src={Logo} alt="QualityWorx" title="QualityWorx"/>

                    <Text style={styles.title}>{props.report.reference}</Text>
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Audit No: {props.report.reference}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Auditor: {props.report.assessorName}</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Activity Audited: Sharps Audit</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}></Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Date of Audit: {openOnUtcFormatted}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>Completed On: {completedOnUtcFormatted}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={[styles.contentBox, styles.questionContainer]}>
                        {Object.keys(groupedSharps).length > 0 ? (
                            Object.entries(groupedSharps).map(([type, sharps], index) => {
                                // Sort the sharps array once
                                const sortedSharps = sharps.sort((a, b) => a.sortOrder - b.sortOrder);

                                const headers = ["Operator", "Assigned", "Audit: Assigned"];
                                const cells = sortedSharps.map(sh => ({
                                    operator: sh.operator.firstName + ' ' + sh.operator.surname,
                                    isAssigned: sh.isAssigned ? 'Yes' : 'No',
                                    auditIsAssigned: props.report.sharps.find(rs => rs.sharpId === sh.id) !== undefined ?
                                        props.report.sharps.find(rs => rs.sharpId === sh.id).isAssigned ? 'Yes' : 'No' : 'No'
                                }));

                                return (
                                    <View key={index}>
                                        <Text style={styles.subtitle}>{type}</Text>
                                        <SharpAuditReportPdfTable
                                            type={type}
                                            headers={headers}
                                            cells={cells}
                                        />
                                    </View>
                                );
                            })
                        ) : (
                            <Text style={styles.tableCell}>No data available yet.</Text>
                        )}
                    </View>

                    <Text
                        style={styles.pageNumber}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                        fixed
                    />
                    <Text style={styles.dateCreated} render={() => `${dateCreated}`} fixed />
                </Page>
            </Document>
        ) : (
                <View style={[styles.contentBox, styles.questionContainer]}>
                <Text style={styles.title}>{props.report.reference}</Text>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Audit No: {props.report.reference}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Auditor: {props.report.assessorName}</Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Activity Audited: Sharps Audit</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}></Text>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Date of Audit: {openOnUtcFormatted}</Text>
                        </View>
                        <View style={styles.tableCol}>
                            <Text style={styles.tableCell}>Completed On: {completedOnUtcFormatted}</Text>
                        </View>
                    </View>
                </View>
                    {Object.keys(groupedSharps).length > 0 ? (
                        Object.entries(groupedSharps).map(([type, sharps], index) => {
                            // Sort the sharps array once
                            const sortedSharps = sharps.sort((a, b) => a.sortOrder - b.sortOrder);

                            const headers = ["Operator", "Assigned", "Audit: Assigned"];
                            const cells = sortedSharps.map(sh => ({
                                operator: sh.operator.firstName + ' ' + sh.operator.surname,
                                isAssigned: sh.isAssigned ? 'Yes' : 'No',
                                auditIsAssigned: props.report.sharps.find(rs => rs.sharpId === sh.id) !== undefined ?
                                    props.report.sharps.find(rs => rs.sharpId === sh.id).isAssigned ? 'Yes' : 'No' : 'No'
                            }));

                            return (
                                <View key={index}>
                                    <Text style={styles.subtitle}>{type}</Text>
                                    <SharpAuditReportPdfTable
                                        type={type}
                                        headers={headers}
                                        cells={cells}
                                    />
                                </View>
                            );
                        })
                    ) : (
                        <Text style={styles.tableCell}>No data available yet.</Text>
                    )}
            </View>
        )
    );
}



export const SharpAuditReportPdfTable = (props) => {

    const COLN_WIDTH = (100) / props.headers.length;

    // Register fonts
    Font.register({
        family: 'Segoe UI', fonts: [
            { src: font },
            { src: fontSB, fontStyle: 'semibold' }
        ]
    });

    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            margin: 10,
            marginTop: -1,
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: COLN_WIDTH + "%",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderBottomColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol: {
            width: COLN_WIDTH + "%",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: 5,
            fontSize: 11,
            fontStyle: 'semibold',
            fontFamily: 'Segoe UI'
        },
        tableCell: {
            margin: 5,
            fontSize: 11,
            fontFamily: 'Segoe UI'
        },
        categoryName: {
            margin: 10,
            fontSize: 14,
            fontStyle: 'semibold',
            fontFamily: 'Segoe UI'
        }
    });

    return (
        <View>
            <View style={styles.table}>
                <View style={styles.tableRow}>
                    {
                        props.headers.map((header, index) => (
                            <View style={styles.tableColHeader} key={index}>
                                <Text style={styles.tableCellHeader}>{header}</Text>
                            </View>
                        ))
                    }
                </View>
                {
                    props.cells.map((cell, index) => (                      
                        <View style={styles.tableRow} key={index}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{cell.operator}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{cell.isAssigned}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={[styles.tableCell, cell.auditIsAssigned !== cell.isAssigned && { color: 'red' }]}>
                                    {cell.auditIsAssigned}
                                </Text>
                            </View>
                        </View>
                    ))
                }
            </View>
        </View>
    );
};