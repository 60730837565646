import React from 'react';
import { Screen } from '../App/Screen';

export function ReleaseNotes() {
    return (
        <Screen title="Release Notes">
            <h3>V1.0.0</h3>
            <p><strong>What's New?</strong></p>
            <ul>
                <li>An intitutive and easy-to-read dashboard has been implemented</li>
                <li>There are currently 8 form types</li>
                <li>Automated e-mails will be sent to all specified form Contacts when a form is "Opened" and "Closed"</li>
                <li>It's now possible to generate downloadable PDF's on-the-fly for each form</li>
                <li>Users can leave "notes" on reports for others to see</li>
                <li>Each form has a detailed Audit Trail, which includes a detailed change log</li>
            </ul>
        </Screen>
    );
}