import React from 'react';
import { withRouter } from "react-router-dom";
import { Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap';
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { ChangePasswordForm } from '../Users/ChangePasswordForm';
import { BasicModal } from '../Modals/Basic';
import { LogoutModal } from '../AuthApp/LogoutModal';
import Logo from '../../graphics/logo-no-text-transparent.png';
import { SwitchTenantModal } from '../AuthApp/SwitchTenantModal';
import { AuthService } from '../../services/AuthService';
import { UserService } from '../../services/UserService';

import { Navigation } from '../Layout';

export class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            isDropdownOpen: false,
            isChangePasswordModalOpen: false,
            isConfirmLogoutModalOpen: false,
            isSwitchTenantModalOpen: false,
            tenants: [],
            isTenantsLoaded: false
        };

        this.AuthService = new AuthService();
        this.UserService = new UserService();

        this.toggle = this.toggle.bind(this);
        this.toggleDropdownMenu = this.toggleDropdownMenu.bind(this);
        this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.toggleConfirmLogoutModal = this.toggleConfirmLogoutModal.bind(this);
        this.toggleSwitchTenantModal = this.toggleSwitchTenantModal.bind(this);
        this.handleSwitchTenant = this.handleSwitchTenant.bind(this);
    }

    retrieveTenants() {
        // Get a list of Tenants (specific to currentUser)
        this.AuthService.getMyTenants().then(
            response => {
                const tenants = response.data.results.map(tenant => {
                    return {
                        id: tenant.id,
                        name: tenant.name
                    };
                });
                this.setState({
                    tenants: tenants,
                    isTenantsLoaded: true
                });
            }
        );
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    toggleDropdownMenu() {
        this.setState({
            isDropdownOpen: !this.state.isDropdownOpen
        });
    }

    toggleChangePasswordModal = () => {
        this.setState({
            isChangePasswordModalOpen: !this.state.isChangePasswordModalOpen
        });
    };

    changePassword(user, e) {   
        const model = {
            password: user.password,
            passwordConfirm: user.passwordConfirm
        };

        this.UserService.changePassword(model).then(
            response => { 
                NotificationManager.success('The password was changed succesfully', 'User Password Changed', 1000);
            },
            error => {
                NotificationManager.error('User password was not changed', 'Error', 1000);
            }
        );      
    }

    toggleConfirmLogoutModal() {
        this.setState({
            isConfirmLogoutModalOpen: !this.state.isConfirmLogoutModalOpen
        });
    }

    toggleSwitchTenantModal() {
        this.setState({
            isSwitchTenantModalOpen: !this.state.isSwitchTenantModalOpen
        }, () => {
            if (this.state.isSwitchTenantModalOpen){
                this.retrieveTenants()
            }            
        });
    }

    handleSwitchTenant(tenant) {
        this.props.handleSwitchTenant(tenant);
        this.setState({
            isSwitchTenantModalOpen: !this.state.isSwitchTenantModalOpen
        });
    }

    render() {
        // Create a routed version of the LogoutModal.
        // The reason for doing this is that we want to be able to change the route to the "/logout" route when the confirm button is clicked.
        // the props.history prperty will not be available unless this is a routed component.
        const RoutedLogoutModal = withRouter(({history}) => (
            <LogoutModal
                isOpen={this.state.isConfirmLogoutModalOpen}
                toggle={this.toggleConfirmLogoutModal} 
                handleLogout={() => {
                    history.push('/logout');
                }}
            />
        ));

        return (
            <header className="main-header">
                <Navbar color="dark" dark fixed="top" expand="sm" className="flex-md-nowrap p-0 shadow">
                    <NavbarBrand href="/" className="col-5 col-sm-3 col-md-2 mr-0">
                        <img src={Logo} alt="" width="28"/>
                        <span style={{ top: '2px', position: 'relative', fontSize: '1.3em'}}>&nbsp;&nbsp;{this.props.title}</span>
                    </NavbarBrand>

                    <NavbarToggler onClick={this.toggle} />

                    <Collapse isOpen={this.state.isOpen} navbar>
                    <span className="activeTenantLabel">{this.props.user.tenant.name}</span>                
                                <Button color="success" size="sm" className="nav-link" onClick={this.toggleSwitchTenantModal}>
                                Change
                                </Button>
                        <Nav className="ml-auto" navbar>
                        <NavItem>
                            <hr/>
                            </NavItem>
                            <Navigation isMobileNavigation={true} />
                            <NavItem>
                                <ButtonDropdown direction="down" isOpen={this.state.isDropdownOpen} toggle={this.toggleDropdownMenu} size="sm">
                                    <DropdownToggle size="xs" className="bg-dark" style={{border: "none"}}>  <span className="nav-link logged-in">Logged in as {this.props.user.firstName} {this.props.user.surname}</span>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    <DropdownItem onClick={this.toggleSwitchTenantModal}><Icon.Repeat style={{ height: "20px" }} /> <small>Switch Organisation</small></DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={this.toggleChangePasswordModal}><Icon.Lock style={{ height: "20px" }} /> <small>Change Password</small></DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem onClick={this.toggleConfirmLogoutModal}><Icon.LogOut style={{ height: "20px" }} /> <small>Log Out</small></DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>                                
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>

                <RoutedLogoutModal />

                <SwitchTenantModal
                    isOpen={this.state.isSwitchTenantModalOpen}
                    toggle={this.toggleSwitchTenantModal}
                    tenants={this.state.tenants}
                    handleSwitchTenant={this.handleSwitchTenant}
                    currentTenant={this.props.user.tenant}
                />     

                {/* Change Password */}
            <BasicModal
                isOpen={this.state.isChangePasswordModalOpen}
                toggle={this.toggleChangePasswordModal}
                title="Change Password"
            >
                <ChangePasswordForm
                    user={this.props.user}
                    handleSave={this.changePassword}
                    
                />
            </BasicModal>
            </header>
        );
    }
}