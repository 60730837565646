import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { AddDiscrepancyForm } from './AddDiscrepancyForm';
import { DiscrepancyService } from '../../services/DiscrepancyService';
import {EntityService} from "../../services/EntityService";

export const AddDiscrepancyContainer = () => {
    const history = useHistory();
    const [inputValues, setInputValues] = useState({});
    const [discrepancyService] = useState(new DiscrepancyService());
    const [categories, setCategories] = useState([]);
    const [classificationTypeService] = useState(new EntityService('ClassificationTypes'));

    useEffect(() => {
        // Fetch all classification types
        classificationTypeService.getList(1, 1000, "Name", "desc").then(
            response => {
                const categories = response.data.results.map(category => {
                    return {
                        id: category.id,
                        name: category.name
                    };
                });

                setCategories(categories);
            },
            error => {
                NotificationManager.error('Could not obtain categories', 'Error', 1000);
            }
        );
    }, [classificationTypeService]);

    const handleInputChange = (field, value) => {
        setInputValues({
            ...inputValues,
            [field]: value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        discrepancyService.addDiscrepancy(inputValues).then(
            response => {
                const discrepancy = response.data.content;
                history.push('/discrepancies/edit/' + discrepancy.id);
                NotificationManager.success(response.data.successMessage, 'Success');
            },
            error => {
                // Access the error message from error.response.data
                const errorMessage = error.response && error.response.data && error.response.data.errors ? error.response.data.errors[0].message : 'Could not add discrepancy';
                NotificationManager.error(errorMessage, 'Error', 1000);
            },
        );
    }

    // Build the Screen menu.
    const screenMenu = (
        <>
            <NavLink to="/discrepancies" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Discrepancies List
            </NavLink>
        </>
    );

    // Define the setting for the screen loader
    const screenLoader = {
        isEnabled: true,
        isLoaded: true,
        text: "Loading..."
    };

    return (
        <Screen title="Add Discrepancy" menu={screenMenu} loader={screenLoader}>
            <AddDiscrepancyForm
                inputValues={inputValues}
                handleInputChange={handleInputChange}
                handleSubmit={handleSubmit}
                categories={categories}
            />
        </Screen>
    );
}