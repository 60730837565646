import React from 'react';
import DataTable from 'react-data-table-component';
import { NavLink } from "react-router-dom";
import { Alert }  from 'reactstrap';
import { TableSearchForm } from '../Common/TableSearchForm';
import { TemplateActionsButton } from './TemplateActionsButton';

export function TemplatesList(props) {
    const columns = [
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <NavLink to={'/templates/edit/' + row.id}>{row.name}</NavLink>
            }
        },
        {
            name: 'Summary',
            selector: 'summary',
            sortable: true,
            ignoreRowClick: true,
            cell: (row) => {
                return <span>{row.summary}</span>
            }
        },
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <TemplateActionsButton 
                        template={row} 
                        handleEdit={props.handleEdit} 
                        handleDelete={props.handleDelete}
                    />
                );
            }
        }
    ];

    const hasAny = props.items && props.items.length > 0;

    return (
        <>
            <TableSearchForm handleSearch={props.handleSearch} />
            {
                hasAny ? (
                    <DataTable
                        columns={columns}
                        data={props.items}
                        defaultSortField="name"
                        pagination
                        highlightOnHover
                        noHeader
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
                ) : <Alert color="warning">No templates were found</Alert>
            }
        </>
    );
}

