import React, { useState, useEffect } from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';
import _ from 'lodash';

export const MultipleTenantCheckboxField = (props) => {
    const [selectedItems, setSelectedItems] = useState(props.field.value || []);
    const [isLoaded, setIsLoaded] = useState(false);
    const currentTenantId = props.defaultValue.id;

    useEffect(() => {
        if (!isLoaded) {
            if (!selectedItems.includes(currentTenantId)) {
                setSelectedItems([currentTenantId, ...selectedItems]);
            }
            setIsLoaded(true);
        }
    }, [isLoaded, currentTenantId, selectedItems]);

    useEffect(() => {
        const multiCheckbox = {
            target: {
                name: props.field.name,
                type: 'multitenantcheckbox',
                value: selectedItems,
            },
        };
        props.actions.handleChange(multiCheckbox);
    }, [selectedItems, props.field.name, props.actions]);

    const handleChange = (e) => {
        const id = parseInt(e.target.id.replace('tenant-', ''), 10);
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedItems([...selectedItems, id]);
        } else {
            setSelectedItems(selectedItems.filter((item) => item !== id));
        }
    };

    return (
        isLoaded ? (
            <FormGroup className={props.actions.getErrorClass(props.field)}>
                <Label for={props.field.name}>
                    {props.field.label}&nbsp;&nbsp;&nbsp;
                    <small>{props.actions.getErrorMessage(props.field)}</small>
                </Label>
                <div>
                    {props.options && props.options.length > 0 ? (
                        props.options.map((opt) => {
                            const isChecked = selectedItems.includes(opt.id);
                            const isDisabled = opt.id === currentTenantId;
                            return (
                                <CustomInput
                                    key={opt.id}
                                    type="checkbox"
                                    id={`tenant-${opt.id}`}
                                    label={opt.name}
                                    checked={isChecked || isDisabled}
                                    onChange={handleChange}
                                    disabled={isDisabled}
                                />
                            );
                        })
                    ) : (
                        <p>No tenant options available</p>
                    )}
                </div>
            </FormGroup>
        ) : (
            <>Loading tenant options...</>
        )
    );
};
