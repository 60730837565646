import React, { useState, useEffect } from 'react';
import { Badge } from 'reactstrap';
export const AuditReportStatusBadge = props => {
    const [color, setColor] = useState("primary");

    useEffect(() => {
        var color = "";
        switch (props.status) {
            case "Closed":
                color = "danger";
                break;
            case "In Progress":
                color = "warning";
                break;
            case "Completed":
                color = "secondary";
                break;
            case "Open":
                color = "success";
                break;
            default: 
                color="secondary"
                break;
        }

        setColor(color);
        
    }, [props.status]);


    return (
        <Badge color={color} pill>{props.status}</Badge>
    );
}