

import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import font from '../../../fonts/segoe-ui.ttf';
import fontSB from '../../../fonts/segoe-ui-semibold.ttf';
import { Page, View, Text, Font, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import { PdfTable } from '../../FormComponents/Pdf/PdfTable';
import { saveAs } from 'file-saver';
import { DropdownItem }  from 'reactstrap';

export const PdfActionItem = (props) => {
    const [rootCause, setRootCause] = useState([]);
    const [isRootCauseLoaded, setIsRootCauseLoaded] = useState(false);
    const [actionTaken, setActionTaken] = useState([]);
    const [isActionTakenLoaded, setIsActionTakenLoaded] = useState(false);
    const [followUps, setFollowUps] = useState([]);
    const [isFollowUpsLoaded, setIsFollowUpsLoaded] = useState(false);

    const formService = props.formService;
    const formId = props.formId;

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            // Root Cause
            formService.getRootCause(formId).then(
                response => {
                    const rootCause = {
                        ...response.data
                    }
                    setRootCause(rootCause);
                    setIsRootCauseLoaded(true);
                },
                error => {
                    setRootCause(null);
                    setIsRootCauseLoaded(true);
                }
            )
            // Action Taken
            formService.getActionTaken(formId).then(
                response => {
                    const actionTaken = {
                        ...response.data,
                    }
                    setActionTaken(actionTaken);
                    setIsActionTakenLoaded(true);
                },
                error => {
                    setActionTaken(null);
                    setIsActionTakenLoaded(true);
                }
            )
            // Follow Ups
            formService.getFollowUps(formId, 1, 10, "ExpectedResponseDate", "desc").then(
                response => {
                    const followUps = response.data.results.map(followUp => {
                        return {
                            ...followUp
                        };
                    });
                    setFollowUps(followUps);
                    setIsFollowUpsLoaded(true);
                },
                error => {
                    setFollowUps([]);
                    setIsFollowUpsLoaded(true);
                }
            );
        }, [formService, formId])
    }

    const isLoaded = isRootCauseLoaded && isActionTakenLoaded && isFollowUpsLoaded;

    // PDF 
    const fileName = props.logNo + "_" + moment(new Date()).format('DD-MM-YYYY_HH-mmA') + ".pdf";
    const generatePdfDocument = async () => {
        const blob = await pdf((
            <PdfDocument
                status={props.status}
                title={props.title}
                logNo={props.logNo}
                summary={props.summary}
                jobDetails={props.jobDetails}
                // Procedures
                rootCause={rootCause}
                actionTaken={actionTaken}
                followUps={followUps}
            />
        )).toBlob();
        saveAs(blob, fileName);
    }

    return (isLoaded ?
        <DropdownItem onClick={generatePdfDocument}> <Icon.Download className="feather" />&nbsp; Download PDF</DropdownItem> :
        <DropdownItem> <Icon.Download className="feather" />&nbsp; Download PDF</DropdownItem> 
    )
}


// Create Document Component
export const PdfDocument = (props) => {
    const dateCreated = moment(new Date()).format('DD/MM/YYYY @ hh:mm A')

    // Register fonts
    Font.register({
        family: 'Segoe UI', fonts: [
            { src: font },
            { src: fontSB, fontStyle: 'semibold' }
        ]
    });

    const styles = StyleSheet.create({
        table: {
            display: "table",
            width: "auto",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 0,
            margin: 10,
            marginTop: -1,
        },
        tableRow: {
            margin: "auto",
            flexDirection: "row"
        },
        tableColHeader: {
            width: 100 + "%",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderBottomColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCol: {
            width: 25 + "%",
            borderStyle: 'solid',
            borderColor: '#dee2e6',
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0
        },
        tableCellHeader: {
            margin: 5,
            fontSize: 11,
            fontStyle: 'semibold',
            fontFamily: 'Segoe UI'
        },
        tableCell: {
            margin: 5,
            fontSize: 11,
            fontFamily: 'Segoe UI'
        },
        body: {
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        title: {
            fontSize: 22,
            textAlign: 'center',
            fontFamily: 'Segoe UI',
            fontStyle: 'semibold'
        },
        author: {
            fontSize: 11,
            textAlign: 'center',
            marginBottom: 30,
        },
        subtitle: {
            fontSize: 14,
            margin: 10,
            fontFamily: 'Segoe UI',
            fontStyle: 'semibold'
        },
        text: {
            margin: 10,
            marginTop: 0,
            fontSize: 11,
            textAlign: 'justify',
            fontFamily: 'Segoe UI'
        },
        header: {
            fontSize: 11,
            marginBottom: 20,
            textAlign: 'center',
            color: 'grey',
        },
        container: {
            backgroundColor: '#343a40',
            border: '#343a40',
        },
        containerTitle: {
            margin: 10,
            color: '#fff',
            fontSize: 14,
        },
        contentBox: {
            borderColor: '#dee2e6',
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 1,
            borderTopWidth: 1,
            marginTop: -1
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 50,
            right: 0,
            textAlign: 'left',
            color: 'grey',
        },
        dateCreated: {
            position: 'absolute',
            fontSize: 11,
            bottom: 30,
            left: 0,
            right: 50,

            textAlign: 'right',
            color: 'grey',
        }
    });

    return (
        <Document>
            <Page style={styles.body}>
                <Text style={styles.header} fixed>
                    # QualityWorx #
                </Text>
                <Text style={styles.title}>{props.logNo}</Text>
                <Text style={styles.author}>{props.title} - {props.status.name}</Text>

                <View style={styles.container}>
                    <Text style={styles.containerTitle}>Non-Conformance Report</Text>
                </View>

                <View style={styles.contentBox}>
                    <Text style={styles.subtitle}>Summary</Text>
                    <Text style={styles.text}>
                        Provides a quick summary for the NCR (NCR), particularly for when the report was received and replied to.
                    </Text>
                    {props.summary}
                </View>

                <View style={styles.contentBox}>
                    <Text style={styles.subtitle}>Job Details</Text>
                    <Text style={styles.text}>
                        Provides a summary of some of the most important details for the job.
                    </Text>
                    {props.jobDetails}
                </View>

                <View style={styles.contentBox}>
                    <Text style={styles.subtitle}>Procedures</Text>
                    <Text style={styles.text}>
                        Highlights the key procedures that should be carried out when investigating the NCR (NCR).
                    </Text>
                    <PdfTable
                        headers={["Root Cause"]}
                        cells={[props.rootCause != null ? props.rootCause.comments : "N/A"]}
                    />
                    <PdfTable
                        headers={["Corrective/Preventive Action"]}
                        cells={[props.actionTaken != null ? props.actionTaken.comments : "N/A"]}
                    />
                    {props.followUps != null ?

                        <View style={styles.table}>
                            <View style={styles.tableRow}>
                                <View style={styles.tableColHeader}>
                                    <Text style={styles.tableCellHeader}>Follow Ups (10 Most Recent)</Text>                                   
                                </View>
                            </View>
                            <View styles={styles.tableColHeader}>
                                <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Assignee</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Exp. Response Data</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Actual Response Data</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Is Late Response?</Text>
                                        </View>
                                    </View>
                                </View>
                            {
                                props.followUps.map((cell, index) => {
                                    return (

                                        <View style={styles.tableRow}>
                                        <View style={styles.tableCol} key={index}>
                                            <Text style={styles.tableCell}>{cell.assignee != null ? cell.assignee.firstName + ' ' + cell.assignee.surname : "N/A"}</Text>
                                        </View>

                                        <View style={styles.tableCol} key={index}>
                                            <Text style={styles.tableCell}>{moment(cell.expectedResponseDate).format('DD/MM/YYYY')}</Text>
                                        </View>

                                        <View style={styles.tableCol} key={index}>
                                            <Text style={styles.tableCell}>{cell.actualResponseDate != null ? moment(cell.actualResponseDate).format('DD/MM/YYYY') : "-"}</Text>
                                        </View>

                                        <View style={styles.tableCol} key={index}>
                                            <Text style={styles.tableCell}>{cell.isLateResponse === true ? "Yes" : "No"}</Text>
                                        </View>
                                    </View>

                                    );
                                })
                            }

                        </View>


                        : <PdfTable
                            headers={["Follow Ups (10 Most Recent)"]}
                            cells={["N/A"]}
                        />
                    }

                </View>
                <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
                <Text style={styles.dateCreated} render={() => (
                    `${dateCreated}`
                )} fixed />
            </Page>
        </Document>
    );
}