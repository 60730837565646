import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import DataTable from 'react-data-table-component';
import { NotificationManager } from '../../../helpers/NotificationManager';
import { Alert, Button, Badge } from 'reactstrap';
import { FollowUpActionsButton } from './ActionsButton';
import { BasicModal } from '../../Modals/Basic';
import { CreateFollowUpForm } from './CreateFollowUpForm';
import { Can } from '../../RBAC/Can';
import { UserService } from '../../../services/UserService';
import { SimpleLoader } from '../../Common/SimpleLoader';

export function FollowUps(props) {
    const [followUps, setFollowUps] = useState([]);
    const [isFollowUpsLoaded, setIsFollowUpsLoaded] = useState(false);
    const [assignees, setAssignees] = useState([]);
    const [isAssigneesLoaded, setIsAssigneesLoaded] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    // Paging options
    const [pageNumber, setPageNumber] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const formService = props.formService;
    const formId = props.formId * 1;

    // Services
    const [userService] = useState(new UserService());    

    // Init.
    useInitMount(formService, formId, pageNumber, perPage);
    function useInitMount(formService, formId, pageNumber, perPage) {
        useEffect(() => {
            fetchFollowUps();
        }, [formService, formId]);        

        // Refetch data based on params
        useEffect(() => {
            fetchFollowUps();
        }, [pageNumber, perPage])
    }    

    // Fetch root cause
    const fetchFollowUps = () => {
        formService.getFollowUps(formId, pageNumber, perPage, "DateCreated", "desc").then(
            response => {
                const followUps = response.data.results.map(followUp => {
                    return {
                        ...followUp
                    };
                });
                setFollowUps(followUps);
                setIsFollowUpsLoaded(true);
                // Paging
                setTotalRows(response.data.paging.totalItemCount);

                if (followUps.length >= 1) { props.hasFollowUps(true)}
                else {props.hasFollowUps(false)};
            },
            error => {
                setFollowUps([]);
                setIsFollowUpsLoaded(true);
                props.hasFollowUps(false);
            }
        );

        userService.getList(1, 1000, 'UserName', 'asc').then(
            response => {
                const assignees = response.data.results.map(assignee => {
                    return {
                        id: assignee.id,
                        name: assignee.firstName + ' ' + assignee.surname
                    };
                });
                setAssignees(assignees);
                setIsAssigneesLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain assignees', 'Error', 1000);
            }
        )};

    const handlePageChange = (page) => setPageNumber(page);
    const handlePerRowsChange = (newPerPage) => setPerPage(newPerPage);    
    const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen); 

    //  ===============================================================================================================

    // TODO :: ACTIONS
    const viewFollowUp = () => { };

    // Actions
    const createFollowUp = (followUp) => {
        var model = { ...followUp };
        formService.createFollowUp(formId, model).then(
            response => {
                fetchFollowUps();
                props.auditTrailRefresh(true);
                NotificationManager.success('The follow up was created successfully', 'Created', 1000);
            },
            error => {
                NotificationManager.error('The follow up was not created', 'Error', 1000);
            }
        );
    }

    const editFollowUp = (followUp) => {
        var model = { ...followUp };
        const followUpId = followUp.id * 1;
        formService.editFollowUp(formId, followUpId, model).then(
            response => {
                fetchFollowUps();
                props.auditTrailRefresh(true);
                NotificationManager.success('The follow up was updated.', 'Updated', 1000);
            },
            error => {
                NotificationManager.error('The follow up was not updated', 'Error', 1000);
            }
        );
    };

    const deleteFollowUp = (followUp, e) => {
        e.preventDefault();
        const followUpId = followUp.id * 1;
        formService.deleteFollowUp(formId, followUpId).then(
            response => {
                fetchFollowUps();
                props.auditTrailRefresh(true);
                NotificationManager.success('The follow up was deleted.', 'Follow Up Deleted', 1000);
            },
            error => {
                NotificationManager.error('The follow up was not deleted', 'Error', 1000);
            }
        );
    };

    const createFollowUpResponse = (followUpResponse) => {
        var model = { ...followUpResponse };
        const followUpId = model.followUpId * 1;
        formService.createFollowUpResponse(formId, followUpId, model).then(
            response => {
                fetchFollowUps();
                props.auditTrailRefresh(true);
                NotificationManager.success('The follow up response was created successfully', 'Created', 1000);
            },
            error => {
                NotificationManager.error('The follow up response was not created', 'Error', 1000);
            }
        );
    }

    const editFollowUpResponse = (followUpResponse) => {
        var model = { ...followUpResponse };
        const followUpId = model.followUpId * 1;
        const followUpResponseId = model.id * 1;     
        formService.editFollowUpResponse(formId, followUpId, followUpResponseId, model).then(
            response => {
                fetchFollowUps();
                props.auditTrailRefresh(true);
                NotificationManager.success('The follow up response was updated.', 'Updated', 1000);
            },
            error => {
                NotificationManager.error('The follow up response was not updated', 'Error', 1000);
            }
        );
    };

    //  ===============================================================================================================

    const columns = [
        {
            name: 'Follow Up',
            selector: 'comments',
            sortable: true
        },
        {
            name: 'Assignee',
            selector: 'assigneeId',
            sortable: true,
            cell: (row) => {
                if (row.assignee != null) {
                    return <span>{row.assignee.firstName} {row.assignee.surname}</span>
                }
                else {
                    return <span>N/A</span>
                }
            }
        },
        {
            name: 'Exp. Resp. Date',
            selector: 'expectedResponseDate',
            sortable: true,
            cell: (row) => {
                return moment(row.expectedResponseDate).format('DD/MM/YYYY')
            }
        },
        {
            name: 'Resp. Date',
            selector: 'actualResponseDate',
            sortable: true,
            cell: (row) => {
                if (row.actualResponseDate != null) {
                    return moment(row.actualResponseDate).format('DD/MM/YYYY')
                }
                else {
                    return <span>-</span>
                }
            }
        },
        {
            name: 'Late Response?',
            selector: 'isLateResponse',
            sortable: true,
            cell: (row) => {
                return row.isLateResponse === true ?
                    <Badge color='danger' pill>Yes</Badge>
                    : <Badge color='success' pill>No</Badge>;
            }
        },
        {
            name: 'Response',
            selector: 'response.comments',
            sortable: true,
            cell: (row) => {
                if (row.response == null) {
                    return <span>-</span>
                }
                else {
                    return row.response.comments;
                }                
            }
        },
        {
            name: 'Further Act. Req.',
            selector: 'response.isFurtherActionRequired',
            sortable: true,
            cell: (row) => {
                if (row.response == null) {
                    return <span>-</span>
                }
                else {
                    return row.response.isFurtherActionRequired === true ? <span>Yes</span> : <span>No</span>;
                }                
            }
        },       
        {
            name: '',
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            cell: (row) => {
                return (
                    <FollowUpActionsButton
                        followUp={row}
                        assigneeOptions={assignees}
                        handleView={viewFollowUp}
                        handleEdit={editFollowUp}
                        handleDelete={deleteFollowUp}
                        handleResponseCreate={createFollowUpResponse}
                        handleResponseEdit={editFollowUpResponse}
                    />
                );
            }
        }
    ];

    const hasAny = followUps && followUps.length > 0;
    const isLoaded = isFollowUpsLoaded & isAssigneesLoaded;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
            <>
                <Can
                    perform="followups:create"
                    yes={() => (
                        <>
                            <Button className="float-left" color="success" size="sm" onClick={() => toggleCreateModal()}>
                                <Icon.PlusCircle className="feather" /> Create Follow Up
                            </Button>
                            <br/><br/>
                        </>
                    )}
                />

                {
                    hasAny ? (
                        <DataTable
                            columns={columns}
                            data={followUps}
                            pagination
                            paginationServer
                            highlightOnHover
                            noHeader
                            paginationTotalRows={totalRows}
                            paginationRowsPerPageOptions={[10, 25, 50, 100]}                        
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}   
                        />
                    ) : <Alert color="warning">No follow ups were found</Alert>
                }

                {/* Create */}
                <BasicModal
                    isOpen={isCreateModalOpen}
                    toggle={toggleCreateModal}
                    title="Create Follow Up"
                >
                    <CreateFollowUpForm
                        assigneeOptions={assignees}
                        handleSave={createFollowUp}>
                    </CreateFollowUpForm>
                </BasicModal>
            </>
        </SimpleLoader>
    );
}
