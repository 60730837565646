import React from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody } from 'reactstrap';

export const ScheduleAuditReportForm = ({ report, handleReportChange, handleSubmit, templates }) => {
    return (
        <Form onSubmit={handleSubmit}>
            <Card key="def0">
                <CardBody>
                <FormGroup>
                        <Label for="templateId">Template</Label>
                        <Input type="select" id="templateId" value={report.template} onChange={handleReportChange('templateId')} required>
                            <option value="">- Template - </option>
                            {templates.map((template) => (
                                <option key={template.id} value={template.id}>{template.name}</option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="startDate">Start Date</Label>
                        <Input type="date" id="startDate" value={report.startDate} onChange={handleReportChange('startDate')} required />
                    </FormGroup>
                    <FormGroup>
                        <Label for="endDate">End Date</Label>
                        <Input type="date" id="endDate" value={report.endDate} onChange={handleReportChange('endDate')} required />
                    </FormGroup>                    
                    <FormGroup>
                        <Label for="frequency">Frequency</Label>
                        <Input type="select" id="frequency" value={report.frequency} onChange={handleReportChange('frequency')} required>
                            <option value="">-  Frequency -</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="frequencyInterval">Frequency Interval</Label>
                        <Input type="number" id="frequencyInterval" value={report.frequencyInterval} onChange={handleReportChange('frequencyInterval')} min="1" max="10"/>
                    </FormGroup>                    
                </CardBody>
            </Card>        
            <Button color="success" type="submit">Schedule</Button>
        </Form>
    );
};