
export class StorageService {
    constructor(isBase64EncodingEnabled) {
        this.isBase64EncodingEnabled = isBase64EncodingEnabled;
    }

    exists(key) {
        return (key in localStorage);
    }

    get(key) {
        let value = localStorage.getItem(key);

        if(this.isBase64EncodingEnabled) {
            if(value && value.length > 0) {
                const decodedValue = atob(value);
                return JSON.parse(decodedValue);
            }
        }
        
        return JSON.parse(value);      
    }

    save(key, value) {
        const jsonValue = JSON.stringify(value);

        if(this.isBase64EncodingEnabled) {
            localStorage.setItem(key, btoa(jsonValue));
        }
        else {
            localStorage.setItem(key, jsonValue);
        }
    }

    delete(key) {
        localStorage.removeItem(key);
    }

    
}