import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { CompaniesList } from './List';
import { CompanyService } from '../../services/CompanyService';
import { CompanyTypeCardList } from '../Companies/CompanyTypeCard/CompanyTypeCardList';

export const CompaniesListContainer = props => {
    const [companies, setCompanies] = useState([]);
    const [isCompaniesLoaded, setIsCompaniesLoaded] = useState(false);
    const [customers, setCustomers] = useState(0);
    const [suppliers, setSuppliers] = useState(0);
    const [customersAndSuppliers, setCustomerAndSuppliers] = useState(0);

    const [state, setState] = useState({
        searchType: null,
        isFilterToggled: false
    });

    // Services
    const [companyService] = useState(new CompanyService());

    //Load initial data
    useEffect(() => {
        const handleResponse = (response) => {
            const companies = response.data.results.map(company => {
                return {
                    ...company,
                };
            });

            setCompanies(companies);
            setIsCompaniesLoaded(true);
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Companies', 'Error', 1000);
        };
        
        companyService.getList(1, 1000, 'Name', 'asc').then(handleResponse, handleError);

        // Loads outstanding forms by types
        companyService.getOutstandingFormsByCompany(0, 1, true).then(response => {
            setCustomers(response.data * 1);
        });
        companyService.getOutstandingFormsByCompany(0, 2, true).then(response => {
            setSuppliers(response.data * 1);
        });
        companyService.getOutstandingFormsByCompany(0, 3, true).then(response => {
            setCustomerAndSuppliers(response.data * 1);
        }); 

    }, [companyService]);

    const handleSearch = (searchText) => {

        const handleResponse = (response) => {
            const companies = response.data.results.map(company => {
                return {
                    ...company
                };
            });

            setCompanies(companies);
            setIsCompaniesLoaded(true);
        };

        const handleError = (error) => {
            NotificationManager.error('Could not obtain results for Companies', 'Error', 1000);
        };

        if (searchText && searchText.length > 0) {
            companyService.search(searchText, 1, 1000, 'Name', 'asc').then(handleResponse, handleError);
        }
        else {
            companyService.getList(1, 1000, 'Name', 'asc').then(handleResponse, handleError);
        }
    };

    const handleFilter = (searchType) => {
        setState((prevState) => ({
            ...prevState,
            isFilterToggled: prevState.searchType === searchType ? !prevState.isFilterToggled : true,
            searchType: prevState.searchType === searchType ? null : searchType
        }));
    };

    const handleView = (company, e) => {
        e.preventDefault();
        props.history.push('/companies/view/' + company.id);
    };

    const isLoaded = isCompaniesLoaded;
    const searchType = state.searchType;
    var filteredCompanies = companies;

    if (searchType != null) {
        filteredCompanies = _.filter(companies, x => x.type.name === searchType);
    }

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Companies..."
    };

    return (
        <Screen title="Companies" loader={screenLoader}>
            <CompanyTypeCardList
                handleFilter={handleFilter}
                isFilterToggled={state.isFilterToggled}
                customers={customers}
                customerAndSuppliers={customersAndSuppliers}
                suppliers={suppliers}
            />
            <br />
            <CompaniesList
                companies={filteredCompanies}
                handleView={handleView}
                handleSearch={handleSearch}
            />
        </Screen>
    );
}



