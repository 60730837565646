import React, { useState } from 'react';
import { Form, Input, Label, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem  } from 'reactstrap';

export const EditDiscrepancyForm = ({ inputValues, handleInputChange, handleSubmit, handleFinish, categories }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggle = () => setDropdownOpen(prevState => !prevState);
    
    return (
        <Form>
            <FormGroup>
                <Label for="activityAudited">Activity Audited</Label>
                <Input
                    type="text"
                    id="activityAudited"
                    value={inputValues.activityAudited || ''}
                    onChange={(e) => handleInputChange('activityAudited', e.target.value)}
                />
            </FormGroup>
            {/* Add more fields as needed */}
            <FormGroup>
                <Label for="previouslyAuditedOnUtc">Previously Audited On</Label>
                <Input
                    type="date"
                    id="previouslyAuditedOnUtc"
                    value={inputValues.previouslyAuditedOnUtc || ''}
                    onChange={(e) => handleInputChange('previouslyAuditedOnUtc', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="personnelContacted">Personnel Contacted</Label>
                <Input
                    type="text"
                    id="personnelContacted"
                    value={inputValues.personnelContacted || ''}
                    onChange={(e) => handleInputChange('personnelContacted', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="discrepancyCategoryId">Discrepancy Category</Label>
                <Input
                    type="select"
                    id="discrepancyCategoryId"
                    value={inputValues.discrepancyCategoryId || ''}
                    onChange={(e) => handleInputChange('discrepancyCategoryId', e.target.value)}
                >
                    <option value="">Select a category</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>
                            {category.name}
                        </option>
                    ))}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="findings">Findings</Label>
                <Input
                    type="textarea"
                    id="findings"
                    value={inputValues.findings || ''}
                    onChange={(e) => handleInputChange('findings', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="correctiveAction">Corrective Action</Label>
                <Input
                    type="textarea"
                    id="correctiveAction"
                    value={inputValues.correctiveAction || ''}
                    onChange={(e) => handleInputChange('correctiveAction', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="correctiveActionScheduledCompletionDateUtc">Corrective Action Scheduled Completion Date</Label>
                <Input
                    type="date"
                    id="correctiveActionScheduledCompletionDateUtc"
                    value={inputValues.correctiveActionScheduledCompletionDateUtc || ''}
                    onChange={(e) => handleInputChange('correctiveActionScheduledCompletionDateUtc', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="correctiveActionDate">Corrective Action Date</Label>
                <Input
                    type="date"
                    id="correctiveActionDate"
                    value={inputValues.correctiveActionDate || ''}
                    onChange={(e) => handleInputChange('correctiveActionDate', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="departmentRep">Department Representative</Label>
                <Input
                    type="text"
                    id="departmentRep"
                    value={inputValues.departmentRep || ''}
                    onChange={(e) => handleInputChange('departmentRep', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="implementedActionComments">Implemented Action Comments</Label>
                <Input
                    type="textarea"
                    id="implementedActionComments"
                    value={inputValues.implementedActionComments || ''}
                    onChange={(e) => handleInputChange('implementedActionComments', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="implementedActionAuditor">Implemented Action Auditor</Label>
                <Input
                    type="text"
                    id="implementedActionAuditor"
                    value={inputValues.implementedActionAuditor || ''}
                    onChange={(e) => handleInputChange('implementedActionAuditor', e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label for="implementedActionOnUtc">Implemented Action Date</Label>
                <Input
                    type="date"
                    id="implementedActionOnUtc"
                    value={inputValues.implementedActionOnUtc || ''}
                    onChange={(e) => handleInputChange('implementedActionOnUtc', e.target.value)}
                />
            </FormGroup>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                                    <DropdownToggle color="success" caret>
                                        Save
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={handleSubmit}>Save</DropdownItem>
                                        <DropdownItem onClick={handleFinish}>Save and Finish</DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </Form>
        
    );
};