import axios from 'axios';
import config from '../config';

export class LookupService {
    constructor() {
        this.basePath = "Lookups";
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }

    getByType(type) {
        var endpoint = this.baseUrl + type;
        return axios.get(endpoint);
    }

    getCompanyTypes() {
        return this.getByType("CompanyType");
    }
}