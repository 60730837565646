import { GET_NAVIGATION, UPDATE_NAVIGATION } from "../actions/types";
import { DashboardService } from "../services/DashboardService";
import { updateNavigation } from "../actions/navigationActions";

  const initialState = {
    customerComplaints: { unresolved: 0  },
    businessSupportNcrs: { unresolved: 0 },
    convertingNcrs: {unresolved: 0 },
    foilingEmbossingNcrs: { unresolved: 0 },
    printingNcrs: { unresolved: 0 },
    prepressNcrs: { unresolved: 0 },
    despatchNcrs: { unresolved: 0 },
    supplierNcrs: { unresolved: 0 }
  };

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_NAVIGATION:
            return {
                ...state
            };
        case UPDATE_NAVIGATION:
            return {
                ...action.payload   
            };
        default:
            return state;
    }
}


// TODO: Never gets dispatched
export function refreshNavigation() {   
    const dashboardService = new DashboardService();
    return (dispatch, getState) => {
        dashboardService.getFormsCount().then(
            response => dispatch(updateNavigation(response.data)),
            error => dispatch(updateNavigation(initialState))
        );
    }
}




