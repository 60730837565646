import React from 'react';
import { Spinner } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

export function Loader({ active, text, children }) {
    return (
        <LoadingOverlay
            active={active}
            text={text}
            spinner={<GrowingCircles />}
            styles={{
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(255, 255, 255, 0.8)'
                }),
                content: (base) => ({
                    ...base,
                    color: '#343a40',
                    fontWeight: 'bold',
                    fontSize: '1.0em'
                })
            }}
        >
            {children}
        </LoadingOverlay>
    )
}

function GrowingCircles(props) {
    return (
        <div className="growing-circles">
            <Spinner type="grow" color="primary" />&nbsp;&nbsp;
            <Spinner type="grow" color="secondary" />&nbsp;&nbsp;
            <Spinner type="grow" color="success" />&nbsp;&nbsp;
            <Spinner type="grow" color="danger" />&nbsp;&nbsp;
            <Spinner type="grow" color="warning" />&nbsp;&nbsp;
            <Spinner type="grow" color="info" />&nbsp;&nbsp;
            <Spinner type="grow" color="dark" />&nbsp;&nbsp;
        </div>
    );
}