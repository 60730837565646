
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as Icon from 'react-feather';
import { saveAs } from 'file-saver';
import { DiscrepancyService } from '../../services/DiscrepancyService';
import { AuthService } from '../../services/AuthService';
import { Button } from 'reactstrap';
import { PDFDocument } from 'pdf-lib'
import gwDiscrepancyPdf from '../../pdfs/GW-Discrepancy.pdf';
import tplDiscrepancyPdf from '../../pdfs/TPL-Discrepancy.pdf';

export const DiscrepancyPdf = (props) => {
    const [discrepancyService] = useState(new DiscrepancyService());
    const [authService] = useState(new AuthService());

    const [discrepancy, setDiscrepancy] = useState({});

    const [tenant, setTenant] = useState({});

    const discrepancyId = props.discrepancyId;

    // TODO: Get the current tenant, as this will determine which pdf gets loaded in.

    useEffect(() => {
        const loadUser = async () => {
            const response = await authService.getAuthUser();
            const user = response.data;

           setTenant(user.tenant);
        }

        const loadDiscrepancy = async () => {
            const response = await discrepancyService.getDiscrepancy(discrepancyId);
            const discrepancy = response.data;

            setDiscrepancy(discrepancy);
        };
        loadUser();
        loadDiscrepancy();
    }, [discrepancyId, discrepancyService, authService]);

    const isLoaded = true;

    // PDF   
    async function fillForm() {
        var isTpl = tenant.id * 1 === 2;
        const discrepancyPdf = isTpl ? tplDiscrepancyPdf : gwDiscrepancyPdf

        const formUrl = discrepancyPdf; // load locally
        const formPdfBytes = await fetch(formUrl).then(res => res.arrayBuffer())     
        const pdfDoc = await PDFDocument.load(formPdfBytes)
      
        const form = pdfDoc.getForm()
      
        const idField = form.getTextField('Audit Discrepancy AD No')
        const auditorField = form.getTextField('Auditor')
        const activityAuditedField = form.getTextField('Activity Audited');
        const dateOfAuditField = form.getTextField('Date of Audit');
        const dateOfPreviousAuditField = form.getTextField("Date of Previous Audit")
        const personellContactedField = form.getTextField('Personnel Contacted');
        const findingsField = form.getTextField('Findings');
        const correctiveActionsField = form.getTextField('Corrective Actions');
        const correctiveActionScheduledCompletionDate = form.getTextField('Corrective Actions Scheduled Completion Date');
        const departmentRepField = form.getTextField('Department Representative');
        const dateField = form.getTextField('Date');
        const implementedActionsField = form.getTextField('Implemented Action');
        const dateField2 = form.getTextField('Date_2');
        const auditorField2 = form.getTextField('Auditor2')
        const minorField = form.getCheckBox('Check Box3')
        const majorField = form.getCheckBox('Check Box4');

        idField.setText(discrepancy.reference);
        auditorField.setText(discrepancy.auditor);
      
        dateOfAuditField.setText(moment(discrepancy.startedOnUtc).format('YYYY-MM-DD'));

        if (discrepancy.previouslyAuditedOnUtc != null) {
            dateOfPreviousAuditField.setText(moment(discrepancy.previouslyAuditedOnUtc).format('YYYY-MM-DD'));
        }
        activityAuditedField.setText(discrepancy.activityAudited);
        personellContactedField.setText(discrepancy.personnelContacted);
        findingsField.setText(discrepancy.findings);
        correctiveActionsField.setText(discrepancy.correctiveAction);
        if (discrepancy.correctiveActionScheduledCompletionDateUtc != null) {
            correctiveActionScheduledCompletionDate.setText(moment(discrepancy.correctiveActionScheduledCompletionDateUtc).format('YYYY-MM-DD'));
        }departmentRepField.setText(discrepancy.departmentRep);
        dateField.setText(moment(discrepancy.correctiveActionDate).format('YYYY-MM-DD'));
        implementedActionsField.setText(discrepancy.implementedActionComments);
        dateField2.setText(moment(discrepancy.implementedActionOnUtc).format('YYYY-MM-DD'));
        auditorField2.setText(discrepancy.implementedActionAuditor);

        // Group categories into cat 1 or cat 2
        if (discrepancy.discrepancyCategory != null) {
            if (discrepancy.discrepancyCategory.name === 'Minor' || discrepancy.discrepancyCategory.name === 'Unassigned') {
                    minorField.check();
            }
            else if (discrepancy.discrepancyCategory.name === 'Major' || discrepancy.discrepancyCategory.name === 'Critical') {
                majorField.check();
            }
        }
      
        const fileName = discrepancy.id + ":discrepancy_" + moment(new Date()).format('DD-MM-YYYY_HH-mmA') + ".pdf";
        const pdfBytes = await pdfDoc.save()      
        var blob = new Blob([pdfBytes], {type: "application/pdf"});

        saveAs(blob, fileName);
      }


    return (isLoaded ?
        <Button onClick={fillForm} size="sm" color="success"><Icon.Download className="feather" /></Button> :
        <Button size="sm" color="success"><Icon.Download className="feather" /></Button>
    )
}

