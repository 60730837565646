import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { TemplatesList } from './TemplatesList';
import { Can } from '../RBAC/Can';
import { TemplateService } from '../../services/TemplateService';

export const TemplatesListContainer = props =>  {
    const [state, setState] = useState({
        templates: [],
        isTemplatesLoaded: false,
        isSearchingActive: false
    });

    // Template Service
    const [templateService] = useState(new TemplateService());

    // Load initial data
    useEffect(() => {
        const handleResponse = (response) => {
            const templates = response.data.map(templates => {
                return {
                    ...templates
                };
            });
            setState(prevState => {
                return {
                    ...prevState,
                    templates: templates,
                    isTemplatesLoaded: true,
                    isSearchingActive: false
                };
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Templates', 'Error', 1000);
        };

        templateService.getTemplates(1, 1000, 'Name', 'asc').then(handleResponse, handleError); 
    }, [templateService]);

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });
        const handleResponse = (response) => {
            const templates = response.data.map(templates => {
                return {
                    ...templates
                };
            });
            setState({ 
                ...state,
                templates: templates,
                isTemplatesLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Templates', 'Error', 1000);
        };

        if (searchText && searchText.length > 0) {
            templateService.getTemplates(1, 1000, 'Name', 'asc', searchText).then(handleResponse, handleError);
        }
        else {
            templateService.getTemplates(1, 1000, 'Name', 'asc').then(handleResponse, handleError); 
        }
    };

    const handleEdit = (template, e) => {
        e.preventDefault();
        props.history.push('/templates/edit/' + template.id);
    };

    const handleDelete = (template, e) => {
        e.preventDefault();

        templateService.deleteTemplate(template.id).then(
            response => {  
                const templates = _.filter(state.templates, x => x.id !== template.id);
                setState({ ...state, templates: templates });
                NotificationManager.success(response.data.successMessage, 'Deleted', 1000);
            },
            error => {
                const errors = error.data.errors;
                NotificationManager.error(errors[0].message, 'Error', 1000);
            }
        );
    };

    const isLoaded = state.isTemplatesLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="templates:add"
            yes={() => (
                <>
                    <NavLink to="/templates/add" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Add Template
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading..."
    };

    return (
        <Screen title="Templates" menu={screenMenu} loader={screenLoader}>
            <TemplatesList
                items={state.templates}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </Screen>
    );
}