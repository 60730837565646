import React from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import * as Icon from 'react-feather';
import { Screen } from '../App/Screen';
import { NavLink } from "react-router-dom";

export const AddTemplateForm = ({ name, setName,shortName, setShortName, description, setDescription, categories, addCategory, deleteCategory, addQuestion, deleteQuestion, onDragEnd, handleQuestionChange, handleCategoryChange, handleSubmit, zones, handleZoneChange }) => {
    const screenMenu = (
        <>
            <NavLink to="/templates" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Templates
            </NavLink>
        </>
    );

    const screenLoader = {
        isEnabled: true,
        isLoaded: true,
        text: "Loading Add Template..."
    };

    return (
        <Screen title="New Template" menu={screenMenu} loader={screenLoader}>
            <Row>
                <Col xs="12">
                    <Form onSubmit={handleSubmit}>
                        <Card key="def0">
                            <CardBody>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="shortName">Short Name</Label>
                                    <Input type="text" id="shortName" value={shortName} onChange={(e) => setShortName(e.target.value)} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Description</Label>
                                    <Input type="text" id="description" value={description} onChange={(e) => setDescription(e.target.value)} />
                                </FormGroup>
                            </CardBody>
                        </Card>
                        {categories.map((category, index) => (
                            <Card key={category.id}>
                                <CardBody>
                                    <CardTitle tag="h5">
                                        <FormGroup style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Input type="text" id={`categoryName${index}`} value={category.categoryName} onChange={(e) => handleCategoryChange(category.id, e)} required placeholder="Enter a category name..." style={{ marginRight: '10px'}}/>
                                            <Button color="danger" type="button" onClick={() => deleteCategory(category.id)}>Delete</Button>
                                        </FormGroup>
                                    </CardTitle>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId={`droppable${category.id}`}>
                                            {(provided) => (
                                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                                    {category.questions.map((question, questionIndex) => (
                                                        <Draggable key={question.id} draggableId={`draggable${question.id}`} index={questionIndex}>
                                                            {(provided) => (
                                                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                    <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Icon.Move className="feather" style={{ marginRight: '10px' }} />
                                                                        <Input type="text" id={`text${questionIndex}`} name="text" value={question.text} onChange={(e) => handleQuestionChange(category.id, question.id, e)} required style={{ marginRight: '10px'}} placeholder="Enter a question..."/>
                                                                        <Select
                                                                        styles={{
                                                                            control: (provided) => ({
                                                                                ...provided,
                                                                                width: 250,
                                                                                marginRight: 10,
                                                                            }),
                                                                        }}
                                                                        isMulti
                                                                        value={zones.filter(zone => (question.zoneIds || []).includes(zone.value))}                                                                        options={zones}
                                                                        onChange={(selectedZones) => handleZoneChange(category.id, question.id, selectedZones)}
                                                                    />
                                                                        <Input type="text" value={`${question.sortOrder}`} disabled style={{ marginRight: '10px', width: '50px', textAlign: 'center' }} />
                                                                        <Button color="danger" type="button" onClick={() => deleteQuestion(category.id, question.id)}>Delete</Button>
                                                                       
                                                                    </FormGroup>
                                                                    

                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}

                                                    <Button color="secondary" onClick={() => addQuestion(category.id)} style={{ height: '50px', width: '100%', marginTop: '15px', marginBottom: '15px' }}>
                                                        <Icon.Plus className="feather" />&nbsp;Add question
                                                    </Button>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </CardBody>
                            </Card>
                        ))}
                        <Button color="secondary" onClick={addCategory} style={{ height: '50px', width: '100%', marginTop: '15px', marginBottom: '15px' }}>
                            <Icon.Plus className="feather" />&nbsp;Add category
                        </Button>
                        <Button color="success" type="submit">Submit</Button>
                    </Form>
                </Col>
            </Row>
        </Screen>
    );
};