import { BaseForm, Validators } from '../FormBuilder';

export class OperatorForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "firstName",
                    label: "First Name",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "surname",
                    label: "Surname",
                    validators: [
                        Validators.required()
                    ]
                }
            ]
        };
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }
}