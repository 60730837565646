import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { BasicModal } from '../../Modals/Basic';
import { Button } from 'reactstrap';
import { NotificationManager } from '../../../helpers/NotificationManager';
import { SimpleLoader } from '../../Common/SimpleLoader';
import { CreateRootCauseForm } from './CreateRootCauseForm';
import { EditRootCauseForm } from './EditRootCauseForm';
import { Can } from '../../RBAC/Can';

export const RootCause = props => {
    const [rootCause, setRootCause] = useState(null);
    const [isRootCauseLoaded, setIsRootCauseLoaded] = useState(false);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    // Methods
    const toggleCreateModal = () => setIsCreateModalOpen(!isCreateModalOpen);
    const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen);

    const formService = props.formService;
    const formId = props.formId * 1;

    // Init.
    useInitMount(formService, formId);
    function useInitMount(formService, formId) {
        useEffect(() => {
            fetchRootCause();
        }, [formService, formId]);
    }

    // Fetch root cause
    const fetchRootCause = () => {
        formService.getRootCause(formId).then(
            response => {
                const rootCause = {
                    ...response.data,
                }

                if (rootCause.id != null)  { 
                    setRootCause(rootCause);
                    setIsRootCauseLoaded(true);
                    props.hasRootCause(true);
                }
                else { 
                    setRootCause(null);
                    setIsRootCauseLoaded(true);
                }                
            },
            error => {
                NotificationManager.error('Could not retrieve root cause', 'Error', 1000);
                setRootCause(null);
                setIsRootCauseLoaded(false);
            }
        )
    };

    // Creates a root cause
    const createRootCause = (rootCause) => {
        var model = { ...rootCause };
        formService.createRootCause(formId, model).then(
            response => {
                fetchRootCause();
                props.auditTrailRefresh(true);
                NotificationManager.success('The root cause was created.', 'Created', 1000);
            },
            error => {
                NotificationManager.error('The root cause was not created.', 'Error', 1000);
            }
        );
    };

    // Updates a root cause
    const editRootCause = (rootCause) => {
        var model = { ...rootCause }; 
        formService.editRootCause(formId, model).then(
            response => {
                fetchRootCause();
                props.auditTrailRefresh(true);
                NotificationManager.success('The root cause was updated.', 'Updated', 1000);
            },
            error => {
                NotificationManager.error('The root cause was not updated.', 'Error', 1000);
            }
        );
    };

    const isLoaded = isRootCauseLoaded;
    const hasAny = rootCause === null ? false : true;

    return (
        <SimpleLoader active={!isLoaded} spinner text="Loading...">
            { !hasAny ?
                <>
                    <Can
                        perform="rootcause:create"
                        yes={() => (
                            <>
                                <Button color="success" size="sm" style={{ margin: "0px 5px 0px 5px" }} onClick={() => toggleCreateModal()}>
                                    <Icon.PlusCircle className="feather" /> Create Root Cause
                                </Button>
                                <hr/>

                                <BasicModal
                                    isOpen={isCreateModalOpen}
                                    toggle={toggleCreateModal}
                                    title="Create Root Cause"
                                >
                                    <CreateRootCauseForm
                                        handleSave={createRootCause}
                                    />
                                </BasicModal>
                            </>
                        )}
                    />
                </> :
                <>
                    <Can
                        perform="rootcause:edit"
                        yes={() => (
                            <>
                                <Button color="success" size="sm" style={{ margin: "0px 5px 0px 5px" }} onClick={() => toggleEditModal()}>
                                    <Icon.Edit2 className="feather" /> Edit Root Cause
                                </Button>
                                <hr/>

                                <BasicModal
                                    isOpen={isEditModalOpen}
                                    toggle={toggleEditModal}
                                    title="Edit Root Cause"
                                >
                                    <EditRootCauseForm
                                        data={rootCause}
                                        handleSave={editRootCause}
                                    />
                                </BasicModal>
                            </>
                        )}
                    />
                    <p>{rootCause.comments}</p>
                </>
            }
        </SimpleLoader>
    );
}