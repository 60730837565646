import React from 'react';
import { Spinner } from 'reactstrap';

export function SimpleLoader({ active, text, children }) {
    return (
        <GrowingCircles text={text} active={active} children={children} />
    )
}

function GrowingCircles(props) {
    return (
        props.active === true ? 
        <>
        <div className="growing-circles" style={{display: 'flex', justifyContent:'center', alignItems:'center', marginTop: '25px'}}>
            <Spinner type="grow" color="primary" />&nbsp;&nbsp;
            <Spinner type="grow" color="secondary" />&nbsp;&nbsp;
            <Spinner type="grow" color="success" />&nbsp;&nbsp;
            <Spinner type="grow" color="danger" />&nbsp;&nbsp;
            <Spinner type="grow" color="warning" />&nbsp;&nbsp;
            <Spinner type="grow" color="info" />&nbsp;&nbsp;
            <Spinner type="grow" color="dark" />&nbsp;&nbsp;
        </div> 
        <h6 style={{textAlign: 'center'}}><strong>{props.text}</strong></h6>
        </> : props.children
    );
}