import React from 'react';
import { Alert, Table }  from 'reactstrap';
import * as Icon from 'react-feather';
import { ConfirmModal } from '../Modals/Confirm';

export function ConfirmDeleteTemplateModal(props) {
    return (
        <ConfirmModal 
            isOpen={props.isOpen}
            toggle={props.toggle} 
            handleConfirm={(e) => props.handleConfirm(props.template, e)}
            title="Delete Template?"
            confirmText="Yes, Delete Template"
            confirmColor="danger"
            confirmIcon={Icon.Trash2}
        >
            <Alert color="warning">
                <strong>Are you sure you wish to delete the following template?</strong>
            </Alert>

            <Table bordered>                
                <thead>
                    <tr>
                        <th className="col-xs-6">Name</th>    
                        <th className="col-xs-6">Summary</th>                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="col-xs-6">{props.template.name}</td>
                        <td className="col-xs-6">{props.template.summary}</td>
                    </tr>
                </tbody>
            </Table>
        </ConfirmModal>
    );
}