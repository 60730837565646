import { BaseForm, Validators } from '../../FormBuilder';

export class CreateCompanyContactForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "firstName",
                    label: "First Name",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "surname",
                    label: "Surname",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "email",
                    label: "Email",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "telephoneNumber",
                    label: "Telephone No."                    
                },
                {
                    name: "jobRole",
                    label: "Job Role"                    
                },
                {
                    name: "officeLocation",
                    label: "Office Location"                    
                }
            ]
        };
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }

    getModel(fields) {
        // This function will override the logic to generate the model that is returned after submitting the form.
        // The generated model will be passed to the relevant handleSave() event, where it can be used to process the data accordingly.

        // Get the model that is generated by the BaseForm.
        // This gets the value for each field defined within the form and returns it as an object.
        // We can use this as the base model to work from and modify it accordingly before we return it.    
        const model = {
            ...this._getModel(fields)
        };

        return model;
    }
}