import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteSupplierNcrModal } from './ConfirmDeleteModal';
import { Can } from './../RBAC/Can';
import { SupplierNcrService } from '../../services/SupplierNcrService';
import moment from 'moment';
import { PdfTable } from '../FormComponents/Pdf/PdfTable';
import { PdfActionItem } from '../FormComponents/Pdf/PdfActionItem';

export const SupplierNcrActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleConfirmDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen
        }));        
    };

    const handleDeleteConfirmed = (entity, e) => {
        props.handleDelete(entity, e);
    };

    const formTitle = "Supplier NCR";
    const [formService] = useState(new SupplierNcrService());
    const form = props.supplierNcr;
    const formId = form.formId * 1;

    return (
        <>
            <div className="float-right">
                <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                    <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => props.handleView(props.supplierNcr, e)}> <Icon.FileText className="feather" />&nbsp; View</DropdownItem>
                        <DropdownItem divider />

                        <PdfActionItem
                            formId={formId}
                            formService={formService}
                            title={formTitle}
                            status={form.status}
                            logNo={form.ncrLogNo}
                            summary={
                                <>
                                    <PdfTable
                                        headers={["NCR Log No", "Defect Type", "Quantity Affected", "Were Materials Rejected?"]}
                                        cells={[form.ncrLogNo, form.defectType !== undefined ? form.defectType.name : "N/A", form.quantityAffected, form.isMaterialsRejected === true ? "Yes" : "No"]}
                                    />
                                    <PdfTable
                                        headers={["Description of Issue"]}
                                        cells={[form.description]}
                                    />
                                </>
                            }
                            jobDetails={
                                <PdfTable
                                    headers={["Supplier", "Purchase Order No", "Material", "Date Delivered"]}
                                    cells={[form.supplier !== undefined ? form.supplier.name : "N/A", form.purchaseOrderNo, form.material, form.dateDelivered != null ? moment(form.dateDelivered).format('DD/MM/YYYY') : "N/A"]} />
                            }
                        />

                        <Can
                            perform="forms:delete"
                            yes={() => (
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => toggleConfirmDeleteModal()}> <Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                                </>
                            )}
                        />  
                    </DropdownMenu>
                </ButtonDropdown>
            </div>

            <ConfirmDeleteSupplierNcrModal 
                isOpen={state.isConfirmDeleteModalOpen}
                toggle={toggleConfirmDeleteModal} 
                handleConfirm={handleDeleteConfirmed}
                supplierNcr={props.supplierNcr}
            />
        </>
    );
}

