import React, { useState, useEffect } from 'react';
import { FormGroup, Label, CustomInput } from 'reactstrap';

export const MultipleRolesCheckboxField = (props) => {
    // Initialize selectedRoles with the IDs of the roles from props.field.value
    const initialRoleIds = props.field.value ? props.field.value.map(role => role.id) : [];
    const [selectedRoles, setSelectedRoles] = useState(initialRoleIds);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!isLoaded) {
            setSelectedRoles(initialRoleIds);
            setIsLoaded(true);
        }
    }, [isLoaded, initialRoleIds]);

    useEffect(() => {
        const multiCheckbox = {
            target: {
                name: props.field.name,
                type: 'multirolecheckbox',
                value: selectedRoles.map(roleId => props.options.find(option => option.id === roleId)),
            },
        };
        props.actions.handleChange(multiCheckbox);
    }, [selectedRoles, props.field.name, props.actions, props.options]);

    const handleChange = (e) => {
        const id = e.target.id.replace('role-', '');
        const isChecked = e.target.checked;

        if (isChecked) {
            setSelectedRoles(prevRoles => [...prevRoles, id]);
        } else {
            setSelectedRoles(prevRoles => prevRoles.filter(roleId => roleId !== id));
        }
    };

    return (
        isLoaded ? (
            <FormGroup className={props.actions.getErrorClass(props.field)}>
                <Label for={props.field.name}>
                    {props.field.label}&nbsp;&nbsp;&nbsp;
                    <small>{props.actions.getErrorMessage(props.field)}</small>
                </Label>
                <div>
                    {props.options && props.options.length > 0 ? (
                        props.options.map((role) => {
                            const isChecked = selectedRoles.includes(role.id);
                            return (
                                <CustomInput
                                    key={role.id}
                                    type="checkbox"
                                    id={`role-${role.id}`}
                                    label={role.name}
                                    checked={isChecked}
                                    onChange={handleChange}
                                />
                            );
                        })
                    ) : (
                        <p>No roles available</p>
                    )}
                </div>
            </FormGroup>
        ) : (
            <>Loading roles...</>
        )
    );
};
