import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import _ from 'lodash';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { GlobalAlertsList } from './List';
import { GlobalAlertService } from '../../services/GlobalAlertService';
import { Can } from '../RBAC/Can';

export const GlobalAlertsListContainer = props =>  {
    const [state, setState] = useState({
        globalAlerts: [],
        isGlobalAlertsLoaded: false,
        isSearchingActive: false
    });  

    // Global Alert Service
    const [globalAlertService] = useState(new GlobalAlertService());

    // Load initial data
    useEffect(() => {            
        const handleResponse = (response) => {
            const globalAlerts = response.data.results.map(globalAlert => {
                return {
                    ...globalAlert
                };
            });
            setState(prevState => { 
                return {
                    ...prevState,
                    globalAlerts: globalAlerts,
                    isGlobalAlertsLoaded: true,
                    isSearchingActive: false
                };
            });      
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Global Alerts', 'Error', 1000);
        };
        
        globalAlertService.getList(1, 1000, 'Id', 'asc').then(handleResponse, handleError);

    }, [globalAlertService]);

    const handleSearch = (searchText) => {
        setState({ ...state, isSearchingActive: true });

        const handleResponse = (response) => {
            const globalAlerts = response.data.results.map(globalAlert => {
                return {
                    ...globalAlert
                };
            });
            setState({ 
                ...state,
                globalAlerts: globalAlerts,
                isGlobalAlertsLoaded: true,
                isSearchingActive: false
            });
        };

        const handleError = () => {
            NotificationManager.error('Could not obtain results for Global Alerts', 'Error', 1000);
        };

        if (searchText && searchText.length > 0) {
            globalAlertService.search(searchText, 1, 1000, 'Id', 'asc').then(handleResponse, handleError);
        }
        else {
            globalAlertService.getList(1, 1000, 'Id', 'asc').then(handleResponse, handleError);
        }
    };

    const handleEdit = (globalAlert, e) => {
        e.preventDefault();
        props.history.push('/global-alerts/edit/' + globalAlert.id);
    };

    const handleDelete = (globalAlert, e) => {
        e.preventDefault();

        globalAlertService.delete(globalAlert.id).then(
            response => { 
                const globalAlerts = _.filter(state.globalAlerts, x => x.id !== globalAlert.id);
                setState({ ...state, globalAlerts: globalAlerts });
                NotificationManager.success('The global alert was deleted.', 'Global Alert Deleted', 1000);
            },
            error => {
                NotificationManager.error('Global Alert was not deleted', 'Error', 1000);
            }
        );
    };

    const isLoaded = state.isGlobalAlertsLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <Can
            perform="globalAlerts:create"
            yes={() => (
                <>
                    <NavLink to="/global-alerts/create" className="btn btn-sm btn-success">
                        <Icon.PlusCircle className="feather" />&nbsp;&nbsp;Create Global Alert
                     </NavLink>
                </>
            )}
        />
    );
    
    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Global Alerts..."
    };

    return (
        <Screen title="Global Alerts" menu={screenMenu} loader={screenLoader}>
            <GlobalAlertsList
                items={state.globalAlerts}
                handleSearch={handleSearch}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
            />
        </Screen>
    );
}
