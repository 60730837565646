import React from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { GlobalAlertEditForm } from './EditForm';
import { GlobalAlertService } from '../../services/GlobalAlertService';
import { CompanyService } from '../../services/CompanyService';
import { LookupService } from '../../services/LookupService';

export class EditGlobalAlertContainer extends React.Component {
    constructor(props) {
        super(props);

        // Setup the default state
        this.state = {
            globalAlert: {},
            isGlobalAlertLoaded: false,
            companies: [],
            isCompaniesLoaded: false
        };

        this.GlobalAlertService = new GlobalAlertService();
        this.CompanyService = new CompanyService();
        this.LookupService = new LookupService();

        this.updateGlobalAlert = this.updateGlobalAlert.bind(this);
        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.globalAlertId = this.props.match.params.globalAlertId * 1;
    }

    componentDidMount() {
        this.GlobalAlertService.getById(this.globalAlertId).then(
            response => {
                const globalAlert = {
                    ...response.data,
                    companyId: response.data.company != null ? response.data.company.id : 0,
                }
                this.setState({
                    globalAlert: globalAlert,
                    isGlobalAlertLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain specified global alert', 'Error', 1000);
            }
        );

        // Loads companies
        this.CompanyService.getList(1, 1000, "Name", "asc").then(
            response => {
                const companies = response.data.results.map(company => {
                    return {
                        ...company
                    };
                });
                this.setState({
                    companies: companies,
                    isCompaniesLoaded: true
                });
            },
            error => {
                NotificationManager.error('Could not obtain companies', 'Error', 1000);
            }
        );
    }

    updateGlobalAlert(globalAlert) {
        var model = {
            ...globalAlert
        };

        this.GlobalAlertService.update(this.globalAlertId, model).then(
            response => {
                // Alert the client that the global alert has been updated
                NotificationManager.success('The global alert was updated.', 'Global Alert Updated', 1000);
            },
            error => {
                // Alert the client that the global alert has NOT been updated
                NotificationManager.error('An active global alert already exists for this company', 'Error', 1000);
            }
        );
    }

    handleSaveClick(e) {
        // We will use the reference to the UserForm component
        // To call the relevant function/method that will submit/process the form data.
        if (this.globalAlertForm && this.globalAlertForm.handleSubmit) {
            const form = this.globalAlertForm.state.form
            if (form.isValid) {
                this.globalAlertForm.handleSubmit(e);
            }
            else {
                this.globalAlertForm.setState({
                    form: {
                        ...form,
                        isSubmitAttempted: true
                    }
                });
                NotificationManager.error('Please fix the form errors', 'Error', 1000);
            }
        }
    }

    render() {
        const isLoaded = this.state.isGlobalAlertLoaded && this.state.isCompaniesLoaded;

        // Build the Screen menu.
        // This will be passed to the Screen component so that it can be rendered in the relevant place.
        const screenMenu = (
            <>
                <NavLink to="/global-alerts" className="btn btn-sm btn-outline-secondary">
                    <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Global Alerts List
               </NavLink>
            </>
        );

        // Define the setting for the screen loader
        // This object will be passed to the Screen component,
        // Which will determine how and if the loader should be displayed
        const screenLoader = {
            isEnabled: true,
            isLoaded: isLoaded,
            text: "Loading Edit Global Alert Form..."
        };

        return (
            <Screen title="Edit Global Alert" menu={screenMenu} loader={screenLoader}>
                <GlobalAlertEditForm
                    ref={(el) => this.globalAlertForm = el}
                    data={this.state.globalAlert}
                    handleSave={this.updateGlobalAlert}
                    companyOptions={this.state.companies}                    
                />
            </Screen>
        );
    }
}