import React from 'react';
import { BaseForm, Validators } from '../FormBuilder';
import { Row, Col } from 'reactstrap';
import { FieldEditor } from '../FormBuilder/FieldEditor';

export class EditUserForm extends BaseForm {
    constructor(props) {
        // Create an object that will represent the structure of the form.
        // This should include a definition for each field within the form (i.e. name, value, label etc.).
        // This will be passed to the constructor of the base class "BaseForm".
        const formDefinition = {
            fields: [
                {
                    name: "firstName",
                    label: "First Name",
                    validators: [
                        Validators.required()
                    ],
                },
                {
                    name: "surname",
                    label: "Surname",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "username",
                    label: "Username",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "email",
                    label: "Email",
                    validators: [
                        Validators.required()
                    ]
                },
                {
                    name: "roles",
                    label: "Roles",
                    editor: {
                        name: "multirolecheckbox",
                        options: props.roleOptions,
                    },
                    validators: [
                        Validators.required()
                    ],
                    value: props.roles
                },
                {
                    name: "isDefaultContact",
                    label: "Is a default form contact?",
                    editor: "checkbox",
                    value: false
                },
                {
                    name: "tenants",
                    label: "Has access to the following Tenants:",
                    editor: {
                        name: "multitentantcheckbox",
                        options: props.tenantOptions,
                        defaultValue: props.currentTenant
                    },
                    value: props.tenants                                 
                }
            ]
        };
        
        // Call the constructor of the base class.
        // As we are passing in the formDefintion and the form data (props.data),
        // This should setup the form accordingly.
        super(props, formDefinition, props.data);
    }


    // Override the renderFormFields() method to output an alternative form layout.
    // To do this, we will need to know what fields exist, so that we can provide the relevant field definition to the editor.
    // Doing this makes the form builder a bit less dynamic, but does give the flexibility to layout the form differently.  
    renderFormFields(fields, form) {       

   
        return (
            <Row form>
                <Col xs="12" md="12">
                    <FieldEditor field={fields.firstName} actions={this.fieldActions} />
                    <FieldEditor field={fields.surname} actions={this.fieldActions} />
                    <FieldEditor field={fields.username} actions={this.fieldActions} />
                    <FieldEditor field={fields.email} actions={this.fieldActions} />
                    <FieldEditor field={fields.roles} actions={this.fieldActions} />
                    <FieldEditor field={fields.isDefaultContact} actions={this.fieldActions} />

                    <FieldEditor field={fields.tenants} actions={this.fieldActions} />
                </Col>
            </Row>
        );
    }
}