import React, { useState, useEffect} from 'react';
import { Badge } from 'reactstrap';

export const DiscrepancyCategoryBadge = props => {
    const [color, setColor] = useState("primary");
    const category = props.category !== undefined ? props.category : "";

    useEffect(() => {
        var color = "";
        switch (category) {
            case "Minor":
                color = "secondary";
                break;
            case "Major":
                color = "warning";
                break;
            case "Critical":
                color = "danger";
                break;
            default:
                color = "primary";
        }

        setColor(color);
        
    }, [category]);


    return (
        <Badge color={color} pill>{category}</Badge>
    );
}