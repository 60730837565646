import React from 'react';
import config from '../../../config';

const abbreviateDisplayName = (displayName, maxLength) => {
  if (displayName.length > maxLength) {
    const extension = displayName.split('.').pop(); // Get the file extension
    const abbreviatedName = displayName.slice(0, maxLength - extension.length - 3) + '...' + extension;
    return abbreviatedName;
  }
  return displayName;
};

const AttachmentViewer = ({ attachments }) => {
  // Filter the attachments to keep only those with a 'mediaItem' property
  const attachmentsWithMediaItem = attachments.filter(attachment => attachment.mediaItem);

  return (
    <div>
      {attachmentsWithMediaItem.length > 0 ? (
        <div>
          <br/>
          <span><label>Attachments</label></span>
          {attachmentsWithMediaItem.map((attachment, index) => (
            <div key={index}>
              {
                attachment.mediaItem.mimeType.startsWith('image/') ? (
                  // Display a white block with an abbreviated display name for images
                  <div>
                    <a href={config.baseApiUrl + 'MediaLibrary/item/' + attachment.mediaItem.id} target="_blank" rel="noopener noreferrer">
                      <div style={{ background: 'white', padding: '10px',  marginBottom: '3px', border: '1px solid #ccc' }}>
                        <small>{abbreviateDisplayName(attachment.mediaItem.displayName, 50)}</small>
                      </div>
                    </a>
                  </div>
                ) : (
                  // Display a white block with an abbreviated display name for other file types (e.g., PDF)
                  <div>
                    <a href={config.baseApiUrl + 'MediaLibrary/item/' + attachment.mediaItem.id} target="_blank" rel="noopener noreferrer">
                      <div style={{ background: 'white', padding: '10px', marginBottom: '3px', border: '1px solid #ccc' }}>
                        <small>{abbreviateDisplayName(attachment.mediaItem.displayName, 50)}</small>
                      </div>
                    </a>
                  </div>
                )
              }
            </div>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default AttachmentViewer;
