import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { CustomerComplaintCreateForm } from './CreateForm';
import { CustomerComplaintService } from '../../services/CustomerComplaintService';
import { EntityService } from '../../services/EntityService';
import { CompanyService } from '../../services/CompanyService';

export const CreateCustomerComplaintContainer = props => {
    const [classificationTypes, setClassificationTypes] = useState([]);
    const [isClassificationTypesLoaded, setIsClassificationTypesLoaded] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isCustomersLoaded, setIsCustomersLoaded] = useState(false);
    const [customerId, setCustomerId] = useState(0);
    const [contacts, setContacts] = useState([]); 
    const [isContactsLoaded, setIsContactsLoaded] = useState(false);

    // Services   
    const [customerComplaintService] = useState(new CustomerComplaintService());  

    // Init.
    useEffect(() => {
        const customerComplaintService = new CustomerComplaintService();  
        const classificationTypeService = new EntityService('ClassificationTypes');
        const companyService = new CompanyService();

        // Fetch all classification types
        classificationTypeService.getList(1, 1000, "Name", "desc").then(
            response => {
                const classificationTypes = response.data.results.map(classificationType => {
                    return {
                        id: classificationType.id,
                        name: classificationType.name
                    };
                });

                setClassificationTypes(classificationTypes);
                setIsClassificationTypesLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain classification types', 'Error', 1000);
            }
        );

        // Fetch all customers
        companyService.getCustomersList(1, 1000, "Name", "asc").then(
            response => {
                const customers = response.data.results.map(customer => {
                    return {
                        id: customer.id,
                        name: customer.name
                    };
                });

                setCustomers(customers);
                setIsCustomersLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain customers', 'Error', 1000);
            }
        );

         // Fetch all default contacts
         customerComplaintService.getDefaultContacts(0).then(
            response => {
                const contacts = response.data.results.map((contact, index) => {
                    return {
                        id: index,
                        ...contact
                    };
                });

                setContacts(contacts);
                setIsContactsLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain contacts', 'Error', 1000);
            }
        );       
    }, []);

    // Create a customer complaint
    const createCustomerComplaint = (customerComplaint) => {
        var model = {
            ...customerComplaint
        };

        customerComplaintService.create(model).then(
            response => {
                const customerComplaint = response.data;
                console.log(response.data);
                props.history.push('/customer-complaints/view/' + customerComplaint.logNo);
                NotificationManager.success('The customer complaint ' + customerComplaint.ccrLogNo + ' was created.', 'Customer Complaint Created');
            },
            error => {
                NotificationManager.error('The customer complaint was not created.', 'Error', 1000);
            },
        );
    };

    // Handles customer change
    const handleCustomerChange = (id) => {
        if (customerId !== id) {
            setCustomerId(id);
            // Fetch all default contacts (based on Company)
            customerComplaintService.getDefaultContacts(id).then(
                response => {
                    const contacts = response.data.results.map((contact, index) => {
                        return {
                            id: index,
                            ...contact
                        };
                    });

                    setContacts(contacts);
                    setIsContactsLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain contacts', 'Error', 1000);
                }
            );
        };       
    }

    const isLoaded = isClassificationTypesLoaded && isCustomersLoaded & isContactsLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <>
            <NavLink to="/customer-complaints" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Customer Complaints List
            </NavLink>
        </>
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Create Customer Complaint Form..."
    };

    return (
        <Screen title="Create Customer Complaint" menu={screenMenu} loader={screenLoader}>
            <CustomerComplaintCreateForm
                handleSave={createCustomerComplaint}
                
                classificationTypeOptions={classificationTypes}
                customersOptions={customers}
                contactsOptions={contacts}
                handleCustomerChange={handleCustomerChange}
            />
        </Screen>
    );
}