import axios from 'axios';
import config from '../config';

export class TemplateService {
    constructor() {
        this.basePath = 'AuditTemplates';
        this.baseUrl = config.baseApiUrl + this.basePath + '/';
    }

    getTemplates(page, size, sort, direction, searchTerm = '') {
        const endpoint = this.baseUrl;
        return axios.get(endpoint, {
            params: {
                page: page,
                size: size,
                sort: sort,
                direction: direction,
                searchTerm: searchTerm
            }
        });
    }

    getTemplate(id) {
        const endpoint = this.baseUrl + id;
        return axios.get(endpoint);
    }

    addTemplate(templateInput) {
        const endpoint = this.baseUrl;
        return axios.post(endpoint, templateInput);
    }

    editTemplate(templateInput) {
        const endpoint = this.baseUrl + templateInput.id;
        return axios.put(endpoint, templateInput);
    }

    deleteTemplate(id) {
        const endpoint = this.baseUrl + id;
        return axios.delete(endpoint);
    }
}