import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }  from 'reactstrap';
import { ConfirmDeleteSharpModal } from './ConfirmDeleteSharpModal';
import { Can } from '../RBAC/Can';

export const SharpActionsButton = props => {
    const [state, setState] = useState({
        dropdownOpen: false,
        isConfirmDeleteModalOpen: false
    });

    const toggle = () => {
        setState((prevState) => ({
            ...prevState,
            dropdownOpen: !prevState.dropdownOpen
        }));
    };

    const toggleConfirmDeleteModal = () => {
        setState((prevState) => ({
            ...prevState,
            isConfirmDeleteModalOpen: !prevState.isConfirmDeleteModalOpen
        }));        
    };

    const handleDeleteConfirmed = (entity, e) => {
        props.handleDelete(entity, e);
    };

    return (
        <>
            <Can
                perform="sharps:edit"
                yes={() => (
                    <>
                        <div className="float-right">
                            <ButtonDropdown direction="left" isOpen={state.dropdownOpen} toggle={toggle} size="sm">
                                <DropdownToggle caret color="primary" size="sm">Actions</DropdownToggle>
                                <DropdownMenu>
                                    <Can
                                        perform="sharps:edit"
                                        yes={() => (
                                            <>
                                                <DropdownItem onClick={(e) => props.handleEdit(props.sharp, e)}><Icon.Edit2 className="feather" />&nbsp; Edit</DropdownItem>
                                            </>
                                        )}
                                    />
                                    <Can
                                        perform="sharps:delete"
                                        yes={() => (
                                            <>
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => toggleConfirmDeleteModal()}><Icon.Trash2 className="feather" />&nbsp; Delete</DropdownItem>
                                            </>
                                        )}
                                    />
                                </DropdownMenu>
                            </ButtonDropdown>
                        </div>

                        <ConfirmDeleteSharpModal
                            isOpen={state.isConfirmDeleteModalOpen}
                            toggle={toggleConfirmDeleteModal}
                            handleConfirm={handleDeleteConfirmed}
                            sharp={props.sharp}
                        />
                    </>
                )}
            />           
        </>
    );
}