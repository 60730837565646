
import { GET_NAVIGATION, UPDATE_NAVIGATION } from './types';

export const getNavigation = (forms) => {
    return (dispatch) => {
        dispatch({
            type: GET_NAVIGATION,
            payload: forms
        });
    }
}

export const updateNavigation = (forms) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_NAVIGATION,
            payload: forms
        });
    }
}

