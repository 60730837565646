import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import * as Icon from 'react-feather';
import { NotificationManager } from '../../helpers/NotificationManager';
import { Screen } from '../App/Screen';
import { DespatchNcrCreateForm } from './CreateForm';
import { DespatchNcrService } from '../../services/DespatchNcrService';
import { EntityService } from '../../services/EntityService';
import { CompanyService } from '../../services/CompanyService';

export const CreateDespatchNcrContainer = props => {
    const [classificationTypes, setClassificationTypes] = useState([]);
    const [isClassificationTypesLoaded, setIsClassificationTypesLoaded] = useState(false);
    const [operators, setOperators] = useState([]);
    const [isOperatorsLoaded, setIsOperatorsLoaded] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [isCustomersLoaded, setIsCustomersLoaded] = useState(false);
    const [customerId, setCustomerId] = useState(0);
    const [contacts, setContacts] = useState([]);
    const [isContactsLoaded, setIsContactsLoaded] = useState(false);

    // Services
    const [despatchNcrService] = useState(new DespatchNcrService());

    // Init.
    useEffect(() => {
        const despatchNcrService = new DespatchNcrService();
        const classificationTypeService = new EntityService('ClassificationTypes');
        const companyService = new CompanyService();
        const operatorService = new EntityService('Operators');

        // Fetch all classification types
        classificationTypeService.getList(1, 1000, "Name", "desc").then(
            response => {
                const classificationTypes = response.data.results.map(classificationType => {
                    return {
                        id: classificationType.id,
                        name: classificationType.name
                    };
                });

                setClassificationTypes(classificationTypes);
                setIsClassificationTypesLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain classification types', 'Error', 1000);
            }
        );

        // Fetch all customers
        companyService.getCustomersList(1, 1000, "Name", "asc").then(
            response => {
                const customers = response.data.results.map(customer => {
                    return {
                        id: customer.id,
                        name: customer.name
                    };
                });
                setCustomers(customers);
                setIsCustomersLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain customers', 'Error', 1000);
            }
        );

         // Loads operators
         operatorService.getList(1, 1000, "Name", "asc").then(
            response => {
                const operators = response.data.results.map(operator => {
                    return {
                        id: operator.id,
                        name: operator.firstName + ' ' + operator.surname
                    };
                });

                setOperators(operators);
                setIsOperatorsLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain operators', 'Error', 1000);
            }
        );

        // Fetch all default contacts
        despatchNcrService.getDefaultContacts(0).then(
            response => {
                const contacts = response.data.results.map((contact, index) => {
                    return {
                        id: index,
                        ...contact
                    };
                });

                setContacts(contacts);
                setIsContactsLoaded(true);
            },
            error => {
                NotificationManager.error('Could not obtain contacts', 'Error', 1000);
            }
        );
    }, []);

    // Create a despatch ncr
    const createDespatchNcr = (despatchNcr) => {
        var model = {
            ...despatchNcr,
            customerId: despatchNcr.customerId !== 0 ? despatchNcr.customerId : null,
            operatorId: despatchNcr.operatorId !== 0 ? despatchNcr.operatorId : null
        };

        console.log(model);
        despatchNcrService.create(model).then(
            response => {
                const despatchNcr = response.data;
                props.history.push('/despatch-ncrs/view/' + despatchNcr.logNo);
                NotificationManager.success(despatchNcr.ncrLogNo + ' was created.', 'Created');
            },
            response => {
                NotificationManager.error('The NCR was not created.', 'Error', 1000);
            },
        );
    };

    // Handles customer change
    const handleCustomerChange = (id) => {
        if (customerId !== id) {
            setCustomerId(id);
            // Fetch all default contacts (based on Company)
            despatchNcrService.getDefaultContacts(id).then(
                response => {
                    const contacts = response.data.results.map((contact, index) => {
                        return {
                            id: index,
                            ...contact
                        };
                    });

                    setContacts(contacts);
                    setIsContactsLoaded(true);
                },
                error => {
                    NotificationManager.error('Could not obtain contacts', 'Error', 1000);
                }
            );
        };
    }

    const isLoaded = isClassificationTypesLoaded && isOperatorsLoaded && isCustomersLoaded && isContactsLoaded;

    // Build the Screen menu.
    // This will be passed to the Screen component so that it can be rendered in the relevant place.
    const screenMenu = (
        <>
            <NavLink to="/despatch-ncrs" className="btn btn-sm btn-outline-secondary">
                <Icon.ArrowLeftCircle className="feather" />&nbsp;Back to Despatch NCRs List
            </NavLink>
        </>
    );

    // Define the setting for the screen loader
    // This object will be passed to the Screen component,
    // Which will determine how and if the loader should be displayed
    const screenLoader = {
        isEnabled: true,
        isLoaded: isLoaded,
        text: "Loading Create Despatch NCR Form..."
    };

    return (
        <Screen title="Create Despatch NCR" menu={screenMenu} loader={screenLoader}>
            <DespatchNcrCreateForm
                handleSave={createDespatchNcr}                
                classificationTypeOptions={classificationTypes}
                customersOptions={customers}
                operatorOptions={operators}
                contactsOptions={contacts}
                handleCustomerChange={handleCustomerChange}
            />
        </Screen>
    );
}